import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { blockchains } from 'api/admin';
import { API } from 'api/types';
import { LoadingStatus } from 'helpers/constants';

interface CounterState {
  blockchains: API.Admin.Blockchains.Response[];
  status: LoadingStatus;
}

const initialState: CounterState = {
  blockchains: [],
  status: LoadingStatus.NONE,
};

export const loadBlockchains = createAsyncThunk('blockchains/list', async () => {
  const res = await blockchains.getAll();
  return res.data;
});

export const blockchainsSlice = createSlice({
  name: 'blockchains',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadBlockchains.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(loadBlockchains.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.blockchains = action.payload;
      })
      .addCase(loadBlockchains.rejected, state => {
        state.status = LoadingStatus.REJECTED;
        state.blockchains = [];
      });
  },
});

export default blockchainsSlice.reducer;
