import axios from 'axios';
import { FilePondErrorDescription, FilePondFile } from 'filepond';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import { SetExternalPhotos } from 'components/common/CustomPhotoUploader';
import { DEFAULT_DATA_SOURCE } from 'helpers/constants';

import { Attachment, Picture, OfferUploadPhoto, ShortAttachment } from 'types';

export type UploadPhotos =
  | OfferUploadPhoto[]
  | Picture[]
  | OfferUploadPhoto
  | Attachment
  | Attachment[]
  | ShortAttachment
  | ShortAttachment[];

const usePhotoUpload = (setExternalPhotos?: SetExternalPhotos) => {
  const [photos, setPhotos] = useState<OfferUploadPhoto[]>([]);
  const [files, setFiles] = useState([]);
  const authToken = Cookies.get('authToken');

  const getSortedPhotos = () => files.map(file => photos.find(photo => photo.path === JSON.parse(file.serverId)?.path));

  const deletePhoto = (err: FilePondErrorDescription, deleteData: FilePondFile) => {
    setPhotos(prevPhotos => prevPhotos.filter(photo => photo.path !== JSON.parse(deleteData.serverId)?.path));
    setFiles(prevFiles => prevFiles.filter(file => file.id !== deleteData.id));
  };

  const preloadPhoto = async (source: string, name: string) => {
    try {
      const response = await axios.get(source, { responseType: 'blob' });
      return new File([response.data], name, { type: response.headers['content-type'] });
    } catch (error) {
      console.error(error);
    }
  };

  const preloadPhotos = (photos: UploadPhotos) => {
    if (Array.isArray(photos)) {
      Promise.all(photos.map(photo => preloadPhoto(photo.url, photo.name))).then(result =>
        setFiles(result.filter(Boolean))
      );
    } else {
      preloadPhoto(photos.url, photos.name).then(result => setFiles(result ? [result] : []));
    }
  };

  const serverUploadPhotoConfig = {
    process: (
      fieldName: string,
      file: string | Blob,
      metadata: any,
      load: (arg: string) => void,
      error: (arg: string) => void,
      progress: (arg0: boolean, arg1: number, arg2: number) => void,
      abort: () => void
    ) => {
      const formData = new FormData();
      formData.append('content', file);

      const request = new XMLHttpRequest();
      const baseURL = process.env.REACT_APP_API_BASE || 'https://api.squarefi.app';

      request.open('POST', `${baseURL}/api:admin/upload/google`);
      request.setRequestHeader('Access-Control-Allow-Origin', '*');
      request.setRequestHeader('X-Data-Source', process.env.REACT_APP_API_DATA_SOURCE || DEFAULT_DATA_SOURCE);
      request.setRequestHeader('Authorization', `Bearer ${authToken}`);
      request.upload.onprogress = e => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      request.onload = () => {
        if (request.status >= 200 && request.status < 300) {
          setPhotos(photos => [...photos, JSON.parse(request.response)]);
          load(request.responseText);
        } else {
          error('oh no');
        }
      };

      request.send(formData);

      return {
        abort: () => {
          request.abort();
          abort();
        },
      };
    },
    revert: (uniqueFieldId: any, load: () => void, error: (errorText: string) => void) => {
      setFiles(files => files.filter(file => JSON.parse(file?.serverId)?.path !== JSON.parse(uniqueFieldId)?.path));
    },
  };

  useEffect(() => {
    setExternalPhotos && setTimeout(() => setExternalPhotos(getSortedPhotos()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos]);

  return { getSortedPhotos, deletePhoto, serverUploadPhotoConfig, preloadPhotos, setFiles, files };
};

export default usePhotoUpload;
