import Card from 'components/card/Card';
import DevelopersForm from 'views/admin/developers/components/forms/DeveloperForm';

const Step1 = () => {
  return (
    <Card p='30px'>
      <DevelopersForm />
    </Card>
  );
};

export default Step1;
