import { createAsyncThunk, createSlice, Slice } from '@reduxjs/toolkit';

import { tenant } from 'api/admin';
import { API } from 'api/types';
import { LoadingStatus } from 'helpers/constants';

interface TenantState {
  items: API.Admin.Tenants.Response[];
  status: LoadingStatus;
}

const initialState: TenantState = {
  items: [],
  status: LoadingStatus.NONE,
};

export const loadTenants = createAsyncThunk('tenants/list', async () => {
  const res = await tenant.getAll();
  return res.data;
});

export const tenantsSlice: Slice<TenantState> = createSlice({
  name: 'tenants',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadTenants.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(loadTenants.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.items = action.payload;
      })
      .addCase(loadTenants.rejected, state => {
        state.status = LoadingStatus.REJECTED;
        state.items = [];
      });
  },
});

export default tenantsSlice.reducer;
