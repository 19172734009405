import { createAsyncThunk, createSlice, Slice } from '@reduxjs/toolkit';

import { omit } from 'lodash';

import { project_goals } from 'api/admin';
import { API } from 'api/types';
import { defaultPaginationParams, LoadingStatus } from 'helpers/constants';

interface OrderState {
  items: API.Admin.ProjectGoals.Item[];
  meta: API.Pagination.Response;
  status: LoadingStatus;
}

const initialState: OrderState = {
  items: [],
  meta: {} as API.Pagination.Response,
  status: LoadingStatus.NONE,
};

export const _loadProjectGoals = createAsyncThunk('projectGoals/list', async (props: API.Pagination.Request) => {
  const { data } = await project_goals.getAll(props);
  const goals = { items: data.items, meta: omit(data, 'items') };

  return goals;
});

export const loadProjectGoals = (props: API.Pagination.Request = defaultPaginationParams) => _loadProjectGoals(props);

export const loadAllProjectGoalsHandler = async (page: number) => {
  const paginationParams = { page, per_page: 500 };
  const { data } = await project_goals.getAll(paginationParams);

  const goals = { items: data.items, meta: omit(data, 'items') };

  if (data.nextPage) {
    const nextGoals = await loadAllProjectGoalsHandler(data.nextPage);
    goals.items.push(...nextGoals.items);
    goals.meta = nextGoals.meta;
  }

  return goals;
};

export const _loadAllProjectGoals = createAsyncThunk('projectGoals/all', loadAllProjectGoalsHandler);

export const loadAllProjectGoals = (page = 1) => _loadAllProjectGoals(page);

export const progectGoalsSlice: Slice<OrderState> = createSlice({
  name: 'progectGoals',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(_loadProjectGoals.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(_loadProjectGoals.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.items = action.payload.items;
        state.meta = action.payload.meta;
      })
      .addCase(_loadProjectGoals.rejected, state => {
        state.status = LoadingStatus.REJECTED;
        state.items = [];
        state.meta = {} as API.Pagination.Response;
      })
      .addCase(_loadAllProjectGoals.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(_loadAllProjectGoals.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.items = action.payload.items;
        state.meta = action.payload.meta;
      })
      .addCase(_loadAllProjectGoals.rejected, state => {
        state.status = LoadingStatus.REJECTED;
        state.items = [];
        state.meta = {} as API.Pagination.Response;
      });
  },
});

export default progectGoalsSlice.reducer;
