import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Grid,
  GridItem,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Flex,
} from '@chakra-ui/react';

import { omit } from 'lodash';
import { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';

import InputField from 'components/fields/InputField';
import { LoadingStatus } from 'helpers/constants';

import { geocodeAddress, GeocodeResult, printAddress } from 'utils/geocoder';

export const emptyAddress = {} as GeocodeResult;

type ChangeOfferAddressProps = {
  isOpen: boolean;
  changeOfferAddressHandler: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  onClose: () => void;
  loadingStatus: LoadingStatus;
  currentAddress: GeocodeResult | null;
  changeTargetAddress: (address: GeocodeResult) => void;
};

const ChangeOfferAddressModal: FC<ChangeOfferAddressProps> = props => {
  const { isOpen, changeOfferAddressHandler, loadingStatus, onClose, currentAddress, changeTargetAddress } = props;

  const initialAddressStatus = { isLoad: false, status: !!currentAddress ? 'valid' : 'none' };
  const [addressStatus, setAddressStatus] = useState(initialAddressStatus);
  const [address, setAddress] = useState('');
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const initialAddress = currentAddress ? printAddress(omit(currentAddress, ['geo'])) : '';
    setAddress(initialAddress);
  }, [currentAddress]);

  const checkAddress = () => {
    if (address) {
      setAddressStatus({ status: 'none', isLoad: true });
      geocodeAddress(address)
        .then(result => {
          if (result.country) {
            changeTargetAddress(result);
            return setAddressStatus({ status: 'valid', isLoad: false });
          }
          setAddressStatus({ status: 'invalid', isLoad: false });
        })
        .catch(error => {
          console.error(error);
          setAddressStatus({ status: 'invalid', isLoad: false });
        });
    } else {
      setAddressStatus({ status: 'none', isLoad: false });
      changeTargetAddress(emptyAddress);
    }
  };

  return (
    <Modal initialFocusRef={buttonRef} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg='none' backdropFilter='auto' backdropBlur='10px' />
      <ModalContent>
        <ModalHeader>Change offer address</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateRows='1fr' templateColumns='repeat(5, 1fr)' gap={4}>
            <GridItem colSpan={4}>
              <FormControl isInvalid={addressStatus.status === 'invalid'} mb='0'>
                <InputField
                  mb='0'
                  id='address'
                  name='address'
                  placeholder='If object doesn’t have an address, leave it empty.'
                  label='Offer address'
                  onChange={(e: SyntheticEvent) => setAddress((e.target as HTMLInputElement).value)}
                  value={address}
                  onBlur={checkAddress}
                />
                {addressStatus.status === 'none' && <FormHelperText mb='27px'></FormHelperText>}
                {addressStatus.status === 'valid' && (
                  <FormHelperText color='green'>The address is valid</FormHelperText>
                )}
                {addressStatus.status === 'invalid' && <FormErrorMessage>The address is not valid</FormErrorMessage>}
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <Flex align='start' h='100%'>
                <Button
                  mt='30px'
                  width='100%'
                  variant='brand'
                  size='md'
                  onClick={checkAddress}
                  isLoading={addressStatus.isLoad}
                >
                  Check
                </Button>
              </Flex>
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button ref={buttonRef} variant='outline' mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme={'green'}
            onClick={changeOfferAddressHandler}
            isLoading={loadingStatus === LoadingStatus.PENDING}
          >
            Change address
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangeOfferAddressModal;
