import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { PermissionEndpoints, PermissionLevels, RouteLayouts } from 'helpers/constants';
import routes from 'routes';

import { selectPrettyUserPermissions } from 'store/selectors';
import { useAppSelector } from 'store/store';
import { PrettyUserPermissions } from 'store/types';
import AccessDenied from 'views/static/AccessDenied';

type CustomReactRoute = {
  path: string;
  component: () => JSX.Element;
  layout: RouteLayouts;
  key: number;
};

type CustomPathElement = {
  path: string;
  layout: RouteLayouts;
  permissions?: {
    endpoint: PermissionEndpoints;
    levels: PermissionLevels[];
  };
};

const usePermissions = () => {
  const userPermissions = useAppSelector(selectPrettyUserPermissions);
  const { pathname } = useLocation();

  const checkUserPermission = (endpoint: PermissionEndpoints, level: PermissionLevels | PermissionLevels[]) => {
    return Array.isArray(level)
      ? level.some(lvl => userPermissions[endpoint]?.includes(lvl))
      : userPermissions[endpoint]?.includes(level);
  };

  const getAllowedRoutes = (routesList: RoutesType[]) => {
    const routesWithAccess: Array<RoutesType> = routesList.map((route: RoutesType) => {
      const { items, permissions } = route;

      if (permissions?.levels) {
        const { endpoint, levels } = permissions;
        const endpointUserLevels: PermissionLevels[] = userPermissions[endpoint];
        const isUserHasAccess = levels.some(level => endpointUserLevels?.includes(level));

        if (!isUserHasAccess || !endpointUserLevels) {
          return null;
        }
      }

      return items ? { ...route, items: getAllowedRoutes(items) } : route;
    });

    const allowedRoutes = routesWithAccess.filter((route: RoutesType | null) => {
      if (!route || (route.items && route.items.length === 0)) {
        return false;
      }

      return true;
    });

    return allowedRoutes;
  };

  const getAvailibleRoutes = (routesList: RoutesType[]) => {
    const availibleRoutesList = routesList.map((route: RoutesType): CustomPathElement[] | CustomPathElement => {
      const { path, layout, permissions, items } = route;

      if (items) {
        return getAvailibleRoutes(items);
      }

      return { path, layout, permissions };
    });
    return availibleRoutesList.flat();
  };

  const allowedRoutes = getAllowedRoutes(routes);
  const availibleRoutesList = getAvailibleRoutes(routes);

  const currentRoutePermissions = useMemo(() => {
    const currentRoute = availibleRoutesList.find(({ path, layout }) => layout + path === pathname);
    if (!currentRoute) {
      return null;
    }

    return currentRoute.permissions;
  }, [availibleRoutesList, pathname]);

  const currentRouteUserPermissions = useMemo(
    () => ({
      canView: checkUserPermission(currentRoutePermissions?.endpoint, [
        PermissionLevels.VIEW,
        PermissionLevels.VIEW_OWN,
      ]),
      canCreate: checkUserPermission(currentRoutePermissions?.endpoint, PermissionLevels.CREATE),
      canUpdate: checkUserPermission(currentRoutePermissions?.endpoint, [
        PermissionLevels.UPDATE,
        PermissionLevels.UPDATE_OWN,
      ]),
      canDelete: checkUserPermission(currentRoutePermissions?.endpoint, [
        PermissionLevels.DELETE,
        PermissionLevels.DELETE_OWN,
      ]),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRoutePermissions, userPermissions]
  );

  const getReactRoutes = (routesList: RoutesType[], targetLayout: RouteLayouts): CustomReactRoute[] => {
    const reactRoutes = routesList.map((route: RoutesType, key: number) => {
      const { path, component, layout, items, permissions } = route;

      if (items) {
        return getReactRoutes(items, targetLayout);
      }

      if (layout === targetLayout) {
        if (!permissions?.levels) {
          return { path, component, layout, key };
        }

        const { endpoint, levels } = permissions;
        const endpointUserLevels: PermissionLevels[] = userPermissions[endpoint as keyof PrettyUserPermissions];
        const isUserHasAccess = levels.some(level => endpointUserLevels?.includes(level));
        const allowedComponent = isUserHasAccess ? component : AccessDenied;

        return { path, component: allowedComponent, layout, key };
      }

      return null;
    });

    return reactRoutes.flat().filter((route: CustomReactRoute | null) => !!route);
  };

  return {
    getReactRoutes,
    allowedRoutes,
    availibleRoutesList,
    currentRoutePermissions,
    currentRouteUserPermissions,
    userPermissions,
    checkUserPermission,
  };
};

export default usePermissions;
