import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import agentOffersReducer from './agentOffers';
import assetsReducer from './assets';
import blockchainsReducer from './blockhains';
import commonReducer from './common';
import cryptoAssetsReducer from './cryptoAssets';
import developersReducer from './developers';
import fundraisersReducer from './fundraisers';
import giftcardsReducer from './giftcards';
import offersReducer from './offers';
import ordersReducer from './orders';
import paymentTypesReducer from './paymentTypes';
import projectGoalsReducer from './projectGoals';
import tenantsReducer from './tenants';
import uiReducer from './ui';
import userReducer from './user';
import usersReducer from './users';

import type { TypedUseSelectorHook } from 'react-redux';

export const store = configureStore({
  reducer: {
    assets: assetsReducer,
    agent_offers: agentOffersReducer,
    cryptoAssets: cryptoAssetsReducer,
    blockchains: blockchainsReducer,
    common: commonReducer,
    developers: developersReducer,
    fundraisers: fundraisersReducer,
    giftcards: giftcardsReducer,
    offers: offersReducer,
    orders: ordersReducer,
    paymentTypes: paymentTypesReducer,
    projectGoals: projectGoalsReducer,
    tenants: tenantsReducer,
    ui: uiReducer,
    user: userReducer,
    users: usersReducer,
  },
});

type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
