import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { format } from 'date-fns';
import { FC } from 'react';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { MdEditDocument, MdOutlineContentCopy, MdTouchApp } from 'react-icons/md';

import { useHistory } from 'react-router-dom';

import { API } from 'api/types';
import Card from 'components/card/Card';
import OverlayPreloader from 'components/preloader/OverlayPreloader';
import { LoadingStatus } from 'helpers/constants';
import useCustomClipboard from 'hooks/useCustomClipboard';
import { selectAssets, selectLoadAssetsStatus } from 'store/selectors';
import { useAppSelector } from 'store/store';
import { prettyId } from 'utils/converters';

interface AssetsTableProps {
  clearFilters: () => void;
  itemUuid?: string;
}

const columnHelper = createColumnHelper<API.Admin.Assets.Asset>();

const AssetsTable: FC<AssetsTableProps> = ({ itemUuid, clearFilters }) => {
  const items = useAppSelector(selectAssets);
  const loadStatus = useAppSelector(selectLoadAssetsStatus);
  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const copyToClipboard = useCustomClipboard();
  const history = useHistory();

  const isRequestPending = loadStatus === LoadingStatus.PENDING || loadStatus === LoadingStatus.NONE;

  const editAssetDocuments = (asset_id: number) => {
    history.push({
      pathname: '/admin/assets/documents',
      search: `?assetId=${asset_id}`,
    });
  };

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Asset id
        </Text>
      ),
      cell: info => (
        <Flex alignItems='center'>
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('offering.name', {
      id: 'offering.name',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Offering name
        </Text>
      ),
      cell: info => (
        <Flex flexDirection='column'>
          <Text color={textColor} fontSize='sm' fontWeight='600' minW={'150px'}>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('user.email', {
      id: 'user.email',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          User email
        </Text>
      ),
      cell: info => (
        <Flex flexDirection='column'>
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.getValue() || 'N/A'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('amount', {
      id: 'amount',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Amount
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Status
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Created
        </Text>
      ),
      cell: info => (
        <Text color={textColor} whiteSpace='nowrap' fontSize='sm' fontWeight='600'>
          {format(new Date(info.getValue()), 'yyyy-MM-dd')}
        </Text>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Actions
        </Text>
      ),
      cell: info => {
        const asset = info.row.original;
        return (
          <Menu>
            <MenuButton
              as={Button}
              leftIcon={<MdTouchApp />}
              fontSize='14px'
              textTransform='uppercase'
              colorScheme='blue'
              bgColor='blue.400'
              h='28px'
              borderRadius='6px'
            >
              Actions
            </MenuButton>
            <MenuList minWidth='0px' fontSize='large' padding='10px'>
              <MenuItem icon={<MdOutlineContentCopy />} onClick={() => copyToClipboard(asset.item_id.toString())}>
                Copy item ID
              </MenuItem>
              <MenuItem icon={<MdOutlineContentCopy />} onClick={() => copyToClipboard(asset.id.toString())}>
                Copy asset ID
              </MenuItem>
              <MenuItem icon={<MdEditDocument />} onClick={() => editAssetDocuments(asset.id)}>
                Edit asset documents
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card mt='20px'>
      <Flex direction='column' w='100%' overflowX={{ sm: 'auto', lg: 'auto' }}>
        <Box overflowX='auto'>
          {isRequestPending && <OverlayPreloader />}
          {itemUuid && (
            <Flex
              justifyContent='space-between'
              align='center'
              mb='20px'
              pl='12px'
              py='4px'
              borderRadius='30px'
              border='1px'
              width='fit-content'
            >
              Item UUID: {prettyId(itemUuid)}
              <IconButton
                isRound
                onClick={clearFilters}
                type='button'
                icon={<IoCloseCircleOutline />}
                aria-label='Clear filters'
                size={'xs'}
                fontSize='20px'
                mx='8px'
              />
            </Flex>
          )}
          {!!items.length ? (
            <Table variant='simple' color='gray.500' mt='12px' minWidth='800'>
              <Thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers?.map(header => {
                      return (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          pe='10px'
                          borderColor={borderColor}
                          cursor='pointer'
                          onClick={header.column?.getToggleSortingHandler()}
                        >
                          <Flex
                            justifyContent='space-between'
                            align='center'
                            fontSize={{ sm: '10px', lg: '12px' }}
                            color='gray.400'
                          >
                            {flexRender(header.column?.columnDef?.header, header.getContext())}
                            {{
                              asc: <FaSortDown />,
                              desc: <FaSortUp />,
                            }[header.column?.getIsSorted() as string] ?? null}
                          </Flex>
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map(row => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderColor='transparent'
                          >
                            {flexRender(cell.column?.columnDef?.cell, cell.getContext())}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          ) : (
            <Text fontSize='24px' textAlign='center' textTransform='uppercase' fontWeight='bold'>
              {isRequestPending ? 'Loading...' : 'No assets found'}
            </Text>
          )}
        </Box>
      </Flex>
    </Card>
  );
};

export default AssetsTable;
