import { Flex, Spinner } from '@chakra-ui/react';

const BasePreloader = () => {
  return (
    <Flex w='100%' h='500px' justifyContent='center' alignItems='center'>
      <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brand.500' size='xl' />
    </Flex>
  );
};

export default BasePreloader;
