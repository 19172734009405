import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { omit } from 'lodash';

import { user } from 'api/admin';
import { API } from 'api/types';
import { LoadingStatus } from 'helpers/constants';

export interface UsersStore {
  items: API.Admin.User.Item[];
  meta: API.Pagination.Response;
  status: LoadingStatus;
}

const initialState: UsersStore = {
  items: [],
  meta: {} as API.Pagination.Response,
  status: LoadingStatus.NONE,
};

export const getUsers = createAsyncThunk('users/list', async (params: API.Pagination.Request) => {
  const { data } = await user.getAll(params);

  const users = { items: data.items, meta: omit(data, 'items') };

  return users;
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUsers.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.items = action.payload.items;
        state.meta = action.payload.meta;
      })
      .addCase(getUsers.rejected, state => {
        state.status = LoadingStatus.REJECTED;
      });
  },
});

export default usersSlice.reducer;
