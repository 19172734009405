/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Flex, Spinner, Stack, Switch, Text, useToast } from '@chakra-ui/react';

import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { user } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';
import { LoadingStatus } from 'helpers/constants';

import useDownloadData from 'hooks/useDownloadData';
import { selectInvestorCategories } from 'store/selectors';
import { useAppSelector } from 'store/store';
import { THistory, TLocation } from 'types';

type InvestorCategoriesFormType = {
  [key: number]: boolean;
};

export default function InvestorCategoriesForm() {
  const location = useLocation<TLocation>();
  const history = useHistory<THistory>();
  const params = new URLSearchParams(location.search);
  const userId = params.get('userId');
  const toast = useToast();

  const [userInfo, getUserStatus, getUser] = useDownloadData<API.Admin.User.Item>(user.getById);
  const [updateUserStatus, setUpdateUserStatus] = useState<LoadingStatus>(LoadingStatus.NONE);
  const investorCategories = useAppSelector(selectInvestorCategories);

  const isUserLoading = getUserStatus === LoadingStatus.PENDING;
  const isUserUpdating = updateUserStatus === LoadingStatus.PENDING;

  useEffect(() => {
    userId && getUser(userId);
  }, [userId]);

  const submitForm = async (formValues: InvestorCategoriesFormType) => {
    try {
      const investor_categories_id = Object.entries(formValues).reduce((acc, [category, value]) => {
        value && acc.push(category);
        return acc;
      }, []);
      setUpdateUserStatus(LoadingStatus.PENDING);
      await user.update(userId, { ...userInfo, investor_categories_id });
      toast({
        title: `Categories has been successfully changed`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      history.push('/admin/users/list');
      setUpdateUserStatus(LoadingStatus.FULFILLED);
    } catch (e) {
      setUpdateUserStatus(LoadingStatus.REJECTED);
      toast({
        title: `Something went wrong. Try again`,
        position: 'top',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const userInvestorCategories: InvestorCategoriesFormType = investorCategories.reduce((acc, { id }) => {
    return Object.assign(acc, { [id]: userInfo?.investor_categories_id.includes(id) });
  }, {});

  if (isUserLoading) {
    return (
      <Flex w='100%' h='500px' justifyContent='center' alignItems='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brand.500' size='xl' />
      </Flex>
    );
  }

  return (
    <Card p='30px'>
      <Formik initialValues={userInvestorCategories} onSubmit={submitForm}>
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Flex direction='column' w='100%'>
              <Stack
                direction={{ sm: 'column', md: 'row' }}
                justifyContent='space-between'
                spacing='20px'
                marginBottom='50'
              >
                {investorCategories &&
                  investorCategories.map(({ category_name, id }) => (
                    <Flex justifyContent={'space-between'} key={id}>
                      <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                        {category_name.toUpperCase()}
                      </Text>
                      <Switch
                        colorScheme='brand'
                        name={`${id}`}
                        onChange={handleChange}
                        isChecked={values[id]}
                        ml='1'
                      />
                    </Flex>
                  ))}
              </Stack>
              <Flex justify='space-between' mt='24px'>
                <Button
                  isLoading={isUserUpdating}
                  variant='darkBrand'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: 'full', md: '148px' }}
                  h='46px'
                  ms='auto'
                  type='submit'
                >
                  Save changes
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </Card>
  );
}
