/* eslint-disable react-hooks/exhaustive-deps */
import { EditIcon } from '@chakra-ui/icons';
import { Button, createStandaloneToast, Flex, Grid, GridItem, Icon, Spinner, Text } from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';

import ItemUpdateModal from '../modals/ItemUpdateModal';

import { offering_items } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';

import DeleteConfirmation from 'components/dialogs/DeleteConfirmation';
import { LoadingStatus } from 'helpers/constants';
import useDownloadData from 'hooks/useDownloadData';
import { THistory, TLocation } from 'types';
import { convertTimeStampToDayDate } from 'utils';

export default function ItemUpdatesForm() {
  const [updateRequestStatus, setUpdateRequestStatus] = useState<LoadingStatus>(LoadingStatus.NONE);
  const [activeUpdate, setActiveUpdate] = useState<API.Admin.OfferingItems.Updates.Response>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const [updates, getUpdatesStatus, getUpdates] = useDownloadData(offering_items.updates.getAll);

  // const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation<TLocation>();
  const params = new URLSearchParams(location.search);
  const toast = createStandaloneToast();
  const itemUuid = params.get('itemUuid');
  const history = useHistory<THistory>();

  const isDocumentsLoading = getUpdatesStatus === LoadingStatus.PENDING;
  const isAccountsPending = updateRequestStatus === LoadingStatus.PENDING;

  const deleteUpdate = () => {
    if (!isAccountsPending) {
      setUpdateRequestStatus(LoadingStatus.PENDING);
      offering_items.updates
        .delete(activeUpdate.id)
        .then(() => {
          setUpdateRequestStatus(LoadingStatus.FULFILLED);
          toast({
            title: `Document successfully deleted`,
            position: 'top',
            status: 'success',
            isClosable: true,
          });
          getUpdates(itemUuid);
          closeDeleteModal();
        })
        .catch(e => {
          setUpdateRequestStatus(LoadingStatus.REJECTED);
          throw e;
        });
    }
  };

  const openDeleteModal = (update: API.Admin.OfferingItems.Updates.Response) => {
    setActiveUpdate(update);
    setIsDeleteModalOpen(true);
  };

  const openUpdateModal = (update?: API.Admin.OfferingItems.Updates.Response) => {
    setActiveUpdate(update);
    setIsUpdateModalOpen(true);
  };

  const closeDeleteModal = () => {
    setActiveUpdate(null);
    setIsDeleteModalOpen(false);
  };

  const closeUpdateModal = () => {
    setActiveUpdate(null);
    setIsUpdateModalOpen(false);
  };

  const handleUpdateModalSubmit = async (values: API.Admin.OfferingItems.Updates.Request) => {
    try {
      setUpdateRequestStatus(LoadingStatus.PENDING);

      activeUpdate
        ? await offering_items.updates.update(activeUpdate.id, values)
        : await offering_items.updates.create(itemUuid, values);

      toast({
        title: `Item update successfully ${activeUpdate ? 'updated' : 'created'}`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      getUpdates(itemUuid);
      closeUpdateModal();
    } catch (error) {
      setUpdateRequestStatus(LoadingStatus.REJECTED);
      throw error;
    }
  };

  const goToOffersList = () => {
    history.push({
      pathname: '/admin/offers/list',
    });
  };

  useEffect(() => {
    getUpdates(itemUuid);
  }, [itemUuid]);

  useEffect(() => {}, [getUpdatesStatus]);

  if (isDocumentsLoading) {
    return (
      <Flex w='100%' h='500px' justifyContent='center' alignItems='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brand.500' size='xl' />
      </Flex>
    );
  }

  return (
    <Card p='30px'>
      {updates?.length > 0 ? (
        <Grid templateColumns={'repeat(24, 1fr)'} gap='20px'>
          {updates.map(update => (
            <>
              <GridItem colSpan={5}>
                <Text noOfLines={1}>{update.title}</Text>
              </GridItem>
              <GridItem colSpan={12}>
                <Text noOfLines={2}>{update.details}</Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text whiteSpace='nowrap'>{convertTimeStampToDayDate(update.date)}</Text>
              </GridItem>
              <GridItem colSpan={3}>
                <Flex alignItems='center'>
                  <Button variant='ghost' fontSize='sm' onClick={() => openUpdateModal(update)}>
                    <Icon as={EditIcon} w='20px' h='20px' />
                  </Button>
                  <Button variant='ghost' color='red' fontSize='sm' onClick={() => openDeleteModal(update)}>
                    <Icon as={MdDelete} w='20px' h='20px' />
                  </Button>
                </Flex>
              </GridItem>
            </>
          ))}
        </Grid>
      ) : (
        <Text marginBottom='50'>No updates</Text>
      )}
      <Button variant='brand' fontSize='sm' borderRadius='4px' mt='4' onClick={() => openUpdateModal()}>
        Add update
      </Button>

      <Flex justify='space-between' mt='24px'>
        <Button
          variant='outline'
          disabled={isAccountsPending}
          fontSize='sm'
          borderRadius='16px'
          w={{ base: 'full', md: '148px' }}
          h='46px'
          ms='auto'
          onClick={goToOffersList}
        >
          Go to offers list
        </Button>
      </Flex>
      <ItemUpdateModal
        isOpen={isUpdateModalOpen}
        onClose={closeUpdateModal}
        clickHandler={handleUpdateModalSubmit}
        itemUpdate={activeUpdate}
        loadingStatus={updateRequestStatus}
      />
      <DeleteConfirmation
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        confirmationHandler={deleteUpdate}
        text={`Are you sure you want to delete update "${activeUpdate?.title}" ? It’s an irreversible process`}
        loadingStatus={updateRequestStatus}
      />
    </Card>
  );
}
