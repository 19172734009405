/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Grid,
  GridItem,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';

import { Field, FieldArray, FieldAttributes, Formik } from 'formik';
import { debounce } from 'lodash';
import { SyntheticEvent, useEffect, useState } from 'react';

import ReactQuill from 'react-quill';
import { useHistory, useLocation } from 'react-router-dom';

// import * as Yup from 'yup';

import { tenant } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';
import CustomPhotoUploader from 'components/common/CustomPhotoUploader';
import InputField from 'components/fields/InputField';
import { LoadingStatus } from 'helpers/constants';
import useDownloadData from 'hooks/useDownloadData';
import { useLeaveConfirmation } from 'hooks/useLeaveConfirmation';
import { THistory, TLocation } from 'types';
import { geocodeAddress, GeocodeResult } from 'utils/geocoder';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { acceptedImgFileTypes } from 'views/admin/offers/components/config';

type FormProps = {
  isEditForm?: boolean;
};

const initialValues: API.Admin.Tenants.Request = {
  name: '',
  manager: '',
  phone: '',
  email: '',
  description: '',
  title: '',
  url: '',
  full_url: '',
  support_email: '',
  legal_info: { VAT: '', ID: '', EIN: '' },
  social: {
    youtube: '',
    vk: '',
    linkedin: '',
    instagram: '',
    telegram: '',
  },
  footer: {
    description_text: '',
    icons: [] as API.Admin.Tenants.FooterIcon[],
  },
  support_agent: {
    name: '',
    url: '',
    photo: {
      url: '',
      name: '',
    },
  },
};

const emptyFooterIcon: API.Admin.Tenants.FooterIcon = {
  url: '',
  name: '',
  link: '',
};

const quillModules = {
  toolbar: [['bold'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
};

const quillFormats = ['bold', 'list', 'bullet', 'link'];

export default function TenantForm({ isEditForm = false }: FormProps) {
  const [tenantInfo, getTenantStatus, getTenant] = useDownloadData<API.Admin.Tenants.Response>(tenant.getById);

  const [createTenantStatus, setCreateTenantStatus] = useState<LoadingStatus>(LoadingStatus.NONE);
  const [address, setAddress] = useState('');
  const [geocoderData, setGeocoderData] = useState<GeocodeResult | null>(null);
  const [addressStatus, setAddressStatus] = useState({ isLoad: false, status: 'none' });

  const { disableLeaveConfirmation } = useLeaveConfirmation();

  const history = useHistory<THistory>();
  const location = useLocation<TLocation>();
  const params = new URLSearchParams(location.search);
  const tenantUuid = params.get('tenantUuid');

  const toast = useToast();

  const checkAddress = (e: SyntheticEvent) => {
    setAddressStatus({ status: 'none', isLoad: true });

    geocodeAddress(address)
      .then(result => {
        if (result.country) {
          setGeocoderData(result);
          setAddressStatus({ status: 'valid', isLoad: false });
        } else {
          setAddressStatus({ status: 'invalid', isLoad: false });
        }
      })
      .catch(error => {
        console.error(error);
        setAddressStatus({ status: 'invalid', isLoad: false });
      });
  };

  const submitData = async (formData: API.Admin.Tenants.Request) => {
    let tenantData = { ...formData };

    if (addressStatus.status === 'valid') {
      tenantData.address = {
        country: geocoderData.country,
        country_code: geocoderData.countryCode,
        state: geocoderData.state,
        city: geocoderData.city || '',
        zip: geocoderData.code || '',
        building: geocoderData.house || '',
      };
    }

    const submitFunction = isEditForm ? () => tenant.update(tenantUuid, tenantData) : () => tenant.create(tenantData);
    const toastMessage = `Tenant has been successfully ${isEditForm ? 'updated' : 'created'}`;

    try {
      setCreateTenantStatus(LoadingStatus.PENDING);
      await submitFunction();
      disableLeaveConfirmation();

      setCreateTenantStatus(LoadingStatus.FULFILLED);
      toast({
        title: toastMessage,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      history.push({
        pathname: `/admin/tenants/list`,
      });
    } catch (e) {
      setCreateTenantStatus(LoadingStatus.REJECTED);
      throw e;
    }
  };

  const initTenant = async () => {
    if (isEditForm) {
      await getTenant(tenantUuid);
    }
  };

  useEffect(() => {
    initTenant();
  }, []);

  useEffect(() => {
    if (isEditForm && getTenantStatus === LoadingStatus.FULFILLED) {
      if (tenantInfo?.address) {
        const { address } = tenantInfo;
        setGeocoderData({ ...tenantInfo.address });
        setAddress(`${address.country ?? ''} ${address.state ?? ''} ${address.city ?? ''}`);
      }
    }
  }, [getTenantStatus]);

  const isLoaded = getTenantStatus === LoadingStatus.FULFILLED;

  if (!isLoaded && isEditForm) {
    return (
      <Flex w='100%' h='500px' justifyContent='center' alignItems='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brand.500' size='xl' />
      </Flex>
    );
  }

  return (
    <Card p='30px'>
      <Formik
        validateOnChange
        validateOnBlur
        initialValues={isEditForm ? tenantInfo : initialValues}
        // validationSchema={Yup.object({
        //   name: Yup.string().max(30, 'maximum 30 symbols'),
        //   description: Yup.string().max(2000, 'maximum 2000 symbols'),
        //   legal_info: Yup.object().shape({
        //     ID: Yup.string().max(20, 'maximum 20 symbols'),
        //     VAT: Yup.string().max(10, 'maximum 10 symbols'),
        //     EIN: Yup.string().max(10, 'maximum 10 symbols'),
        //   }),
        // })}
        onSubmit={submitData}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Flex direction='column' w='100%'>
              <Stack direction='column' spacing='20px'>
                <FormControl isInvalid={!!errors.name} mb='0'>
                  <InputField
                    mb='0px'
                    id='name'
                    name='name'
                    placeholder='Input tenant name'
                    label='Tenant name'
                    onChange={handleChange}
                    value={values.name}
                  />
                  {!errors?.name ? (
                    <FormHelperText mb='25px'></FormHelperText>
                  ) : (
                    <FormErrorMessage>{errors?.name}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.name} mb='0'>
                  <InputField
                    mb='0px'
                    id='manager'
                    name='manager'
                    placeholder='Input manager'
                    label='Manager'
                    onChange={handleChange}
                    value={values.manager}
                  />
                  {!errors?.manager ? (
                    <FormHelperText mb='25px'></FormHelperText>
                  ) : (
                    <FormErrorMessage>{errors?.manager}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.phone} mb='0'>
                  <InputField
                    mb='0px'
                    id='phone'
                    name='phone'
                    placeholder='Input phone'
                    label='Phone number'
                    onChange={handleChange}
                    value={values.phone}
                  />
                  {!errors?.phone ? (
                    <FormHelperText mb='25px'></FormHelperText>
                  ) : (
                    <FormErrorMessage>{errors?.phone}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.email} mb='0'>
                  <InputField
                    mb='0px'
                    id='email'
                    name='email'
                    placeholder='Input email'
                    label='Email'
                    onChange={handleChange}
                    value={values.email}
                  />
                  {!errors?.email ? (
                    <FormHelperText mb='25px'></FormHelperText>
                  ) : (
                    <FormErrorMessage>{errors?.email}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.title} mb='0'>
                  <InputField
                    mb='0px'
                    id='title'
                    name='title'
                    placeholder='Input title'
                    label='Title'
                    onChange={handleChange}
                    value={values.title}
                  />
                  {!errors?.title ? (
                    <FormHelperText mb='25px'></FormHelperText>
                  ) : (
                    <FormErrorMessage>{errors?.title}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.description} mb='0'>
                  <InputField
                    mb='0px'
                    id='description'
                    name='description'
                    placeholder='Description'
                    label='Description'
                    onChange={handleChange}
                    value={values.description}
                  />
                  {!errors?.description ? (
                    <FormHelperText mb='25px'></FormHelperText>
                  ) : (
                    <FormErrorMessage>{errors?.description}</FormErrorMessage>
                  )}
                </FormControl>
                <Grid templateRows='1fr' templateColumns='repeat(2, 1fr)' gap={4}>
                  <GridItem colSpan={1}>
                    <FormControl isInvalid={!!errors.url} mb='0'>
                      <InputField
                        mb='0'
                        id='url'
                        name='url'
                        placeholder='Input URL'
                        label='Tenant url'
                        onChange={handleChange}
                        value={values.url}
                      />
                      {!errors?.url ? (
                        <FormHelperText mb='25px'></FormHelperText>
                      ) : (
                        <FormErrorMessage>{errors?.url}</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl isInvalid={!!errors.full_url} mb='0'>
                      <InputField
                        mb='0'
                        id='full_url'
                        name='full_url'
                        placeholder='Input full URL'
                        label='Tenant full URL'
                        onChange={handleChange}
                        value={values.full_url}
                      />
                      {!errors?.full_url ? (
                        <FormHelperText mb='25px'></FormHelperText>
                      ) : (
                        <FormErrorMessage>{errors?.full_url}</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                </Grid>
                <Grid templateRows='1fr' templateColumns='repeat(2, 1fr)' gap={4}>
                  <GridItem colSpan={1}>
                    <FormControl isInvalid={!!errors.social?.telegram} mb='0'>
                      <InputField
                        mb='0'
                        id='social.telegram'
                        name='social.telegram'
                        placeholder='Input telegram'
                        label='Telegram'
                        onChange={handleChange}
                        value={values.social?.telegram}
                      />
                      {!errors?.social?.telegram ? (
                        <FormHelperText mb='25px'></FormHelperText>
                      ) : (
                        <FormErrorMessage>{errors?.social?.telegram}</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl isInvalid={!!errors.social?.vk} mb='0'>
                      <InputField
                        mb='0'
                        id='social.vk'
                        name='social.vk'
                        placeholder='Input VK'
                        label='VK'
                        onChange={handleChange}
                        value={values.social?.vk}
                      />
                      {!errors?.social?.vk ? (
                        <FormHelperText mb='25px'></FormHelperText>
                      ) : (
                        <FormErrorMessage>{errors?.social?.linkedin}</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                </Grid>
                <Grid templateRows='1fr' templateColumns='repeat(2, 1fr)' gap={4}>
                  <GridItem colSpan={1}>
                    <FormControl isInvalid={!!errors.social?.instagram} mb='0'>
                      <InputField
                        mb='0'
                        id='social.instagram'
                        name='social.instagram'
                        placeholder='Input Instagram'
                        label='Instagram'
                        onChange={handleChange}
                        value={values.social?.instagram}
                      />
                      {!errors?.social?.instagram ? (
                        <FormHelperText mb='25px'></FormHelperText>
                      ) : (
                        <FormErrorMessage>{errors?.social?.instagram}</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl isInvalid={!!errors.social?.linkedin} mb='0'>
                      <InputField
                        mb='0'
                        id='social.linkedin'
                        name='social.linkedin'
                        placeholder='Input LinkedIn'
                        label='LinkedIn'
                        onChange={handleChange}
                        value={values.social?.linkedin}
                      />
                      {!errors?.social?.linkedin ? (
                        <FormHelperText mb='25px'></FormHelperText>
                      ) : (
                        <FormErrorMessage>{errors?.social?.linkedin}</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                </Grid>
                <Grid templateRows='1fr' templateColumns='repeat(2, 1fr)' gap={4}>
                  <GridItem colSpan={1}>
                    <FormControl isInvalid={!!errors.social?.youtube} mb='0'>
                      <InputField
                        mb='0'
                        id='social.youtube'
                        name='social.youtube'
                        placeholder='Input youtube'
                        label='YouTube'
                        onChange={handleChange}
                        value={values.social?.youtube}
                      />
                      {!errors?.social?.youtube ? (
                        <FormHelperText mb='25px'></FormHelperText>
                      ) : (
                        <FormErrorMessage>{errors?.social?.youtube}</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                </Grid>
                <Grid templateRows='1fr' templateColumns='repeat(3, 1fr)' gap={4}>
                  <GridItem colSpan={2}>
                    <FormControl isInvalid={addressStatus.status === 'invalid'} mb='0'>
                      <InputField
                        mb='0'
                        id='address'
                        name='address'
                        placeholder='Address'
                        label='Tenant address'
                        onChange={(e: SyntheticEvent) => setAddress((e.target as HTMLInputElement).value)}
                        value={address}
                        onBlur={checkAddress}
                      />
                      {addressStatus.status === 'none' && <FormHelperText mb='27px'></FormHelperText>}
                      {addressStatus.status === 'valid' && (
                        <FormHelperText color='green'>The address is valid</FormHelperText>
                      )}
                      {addressStatus.status === 'invalid' && (
                        <FormErrorMessage>The address is not valid</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Flex align='start' h='100%'>
                      <Button
                        mt='30px'
                        width='100%'
                        variant='brand'
                        size='md'
                        onClick={checkAddress}
                        isLoading={addressStatus.isLoad}
                      >
                        Check address
                      </Button>
                    </Flex>
                  </GridItem>
                </Grid>
                <Text fontSize='2xl' fontWeight='700'>
                  Customer support
                </Text>
                <FormControl isInvalid={!!errors.support_agent?.name} mb='0'>
                  <InputField
                    mb='0px'
                    id='support_agent.name'
                    name='support_agent.name'
                    placeholder='Input support agent name'
                    label='Support agent name'
                    onChange={handleChange}
                    value={values.support_agent.name}
                  />
                  {!errors?.support_agent?.name ? (
                    <FormHelperText mb='25px'></FormHelperText>
                  ) : (
                    <FormErrorMessage>{errors?.support_agent?.name}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.support_agent?.url} mb='0'>
                  <InputField
                    mb='0px'
                    id='support_agent.url'
                    name='support_agent.url'
                    placeholder='Input support agent URL'
                    label='Support agent URL'
                    onChange={handleChange}
                    value={values.support_agent.url}
                  />
                  {!errors?.support_agent?.url ? (
                    <FormHelperText mb='25px'></FormHelperText>
                  ) : (
                    <FormErrorMessage>{errors?.support_agent?.url}</FormErrorMessage>
                  )}
                </FormControl>
                <Flex direction='column' gap='8px'>
                  <Text fontSize='sm' fontWeight='bold' pl='2.5'>
                    Support agent photo
                  </Text>
                  <CustomPhotoUploader
                    photoFromServer={values.support_agent.photo}
                    acceptedFileTypes={['image/*']}
                    setExternalPhotos={photos => values.support_agent && (values.support_agent.photo = photos[0])}
                  />
                </Flex>

                <Text fontSize='2xl' fontWeight='700'>
                  Legal details
                </Text>
                <Flex gap='4'>
                  <FormControl mb='20px' isInvalid={!!errors.legal_info?.VAT}>
                    <InputField
                      mb='0px'
                      id='legal_info.VAT'
                      name='legal_info.VAT'
                      placeholder='Input VAT'
                      label='VAT'
                      onChange={handleChange}
                      value={values.legal_info?.VAT}
                    />
                    {!errors?.legal_info?.VAT ? (
                      <FormHelperText mb='25px'></FormHelperText>
                    ) : (
                      <FormErrorMessage>{errors?.legal_info?.VAT}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={!!errors.legal_info?.ID}>
                    <InputField
                      mb='0px'
                      id='legal_info.ID'
                      name='legal_info.ID'
                      placeholder='Input legal name'
                      label='Legal name'
                      onChange={handleChange}
                      value={values.legal_info?.ID}
                    />
                    {!errors?.legal_info?.ID ? (
                      <FormHelperText mb='25px'></FormHelperText>
                    ) : (
                      <FormErrorMessage>{errors?.legal_info?.ID}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={!!errors.legal_info?.EIN}>
                    <InputField
                      mb='0px'
                      id='legal_info.EIN'
                      name='legal_info.EIN'
                      placeholder='Input EIN'
                      label='EIN'
                      onChange={handleChange}
                      value={values.legal_info?.EIN}
                    />
                    {!errors?.legal_info?.EIN ? (
                      <FormHelperText mb='25px'></FormHelperText>
                    ) : (
                      <FormErrorMessage>{errors?.legal_info?.EIN}</FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
                <Text fontSize='2xl' fontWeight='700'>
                  Logo
                </Text>
                <CustomPhotoUploader
                  photoFromServer={values.logo}
                  acceptedFileTypes={['image/svg+xml']}
                  setExternalPhotos={logos => (values.logo = logos[0])}
                />
                <Text fontSize='2xl' fontWeight='700'>
                  Favicon
                </Text>
                <CustomPhotoUploader
                  photoFromServer={values.favicon}
                  acceptedFileTypes={['image/*']}
                  setExternalPhotos={favicons => (values.favicon = favicons[0])}
                />
                <Text fontSize='2xl' fontWeight='700'>
                  Icon
                </Text>
                <CustomPhotoUploader
                  photoFromServer={values.icon}
                  acceptedFileTypes={['image/svg+xml']}
                  setExternalPhotos={icons => (values.icon = icons[0])}
                />
                <Text fontSize='2xl' fontWeight='700'>
                  Mobile logo
                </Text>
                <CustomPhotoUploader
                  photoFromServer={values.mobile_logo}
                  acceptedFileTypes={['image/svg+xml']}
                  setExternalPhotos={mobileLogos => (values.mobile_logo = mobileLogos[0])}
                />
                <Text fontSize='2xl' fontWeight='700'>
                  Tenant logo
                </Text>
                <CustomPhotoUploader
                  photoFromServer={values.tenant_logo}
                  acceptedFileTypes={['image/svg+xml']}
                  setExternalPhotos={logosTenant => (values.tenant_logo = logosTenant[0])}
                />
                <Text fontSize='2xl' fontWeight='700'>
                  Header logo
                </Text>
                <CustomPhotoUploader
                  photoFromServer={values.logo_header}
                  acceptedFileTypes={['image/svg+xml']}
                  setExternalPhotos={logosHeader => (values.logo_header = logosHeader[0])}
                />
                <Text fontSize='2xl' fontWeight='700'>
                  SEO Image
                </Text>
                <CustomPhotoUploader
                  photoFromServer={values.seo_image}
                  acceptedFileTypes={acceptedImgFileTypes}
                  setExternalPhotos={photos => (values.seo_image = photos[0])}
                />
              </Stack>
              <Flex direction='column' justify='space-between' gap='12px' mt='24px'>
                <Text fontSize='2xl' fontWeight='700'>
                  Footer
                </Text>
                <Flex direction='column' gap='6px'>
                  <Text>Footer logo</Text>
                  <CustomPhotoUploader
                    photoFromServer={values.logo_footer}
                    acceptedFileTypes={['image/svg+xml']}
                    setExternalPhotos={logosFooter => (values.logo_footer = logosFooter[0])}
                  />
                </Flex>
                <Flex direction='column' gap='6px'>
                  <Text fontWeight='bold'>Footer icons</Text>
                  <FieldArray name='footer.icons'>
                    {({ remove, push }) => (
                      <Flex direction='column' gap='6px'>
                        {values.footer.icons.map((icon, index) => (
                          <Flex key={index} direction='column' gap='6px' ml={1}>
                            <Flex alignItems='center'>
                              <Text fontWeight='medium'>Icon {index + 1}</Text>
                              <Button
                                p='0'
                                borderRadius='10px'
                                variant='ghost'
                                colorScheme='red'
                                fontSize='20px'
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon />
                              </Button>
                            </Flex>

                            <Grid templateRows='1fr' templateColumns='repeat(2, 1fr)' gap={4}>
                              <GridItem colSpan={2}>
                                <InputField
                                  mb='0'
                                  id={`footer.icons[${index}].link`}
                                  name={`footer.icons[${index}].link`}
                                  placeholder='Input icon link'
                                  label='Link'
                                  onChange={handleChange}
                                  fontWeight='normal'
                                  value={icon.link}
                                />
                              </GridItem>
                              <GridItem colSpan={2}>
                                <Text fontSize='sm' ml='2.5' mb='2'>
                                  Icon
                                </Text>
                                <CustomPhotoUploader
                                  photoFromServer={icon}
                                  acceptedFileTypes={['image/svg+xml']}
                                  setExternalPhotos={photos => {
                                    values.footer.icons[index] = { ...photos[0], link: icon.link };
                                  }}
                                />
                              </GridItem>
                            </Grid>
                          </Flex>
                        ))}
                        <Button variant='brand' onClick={() => push(emptyFooterIcon)} borderRadius='base'>
                          Add footer icon
                        </Button>
                      </Flex>
                    )}
                  </FieldArray>
                </Flex>
                <Text fontWeight='bold'>Footer text</Text>
                <Field name='footer.description_text'>
                  {({ field }: FieldAttributes<any>) => {
                    const onFieldChange = debounce(field.onChange(field.name), 250);
                    return (
                      <ReactQuill
                        modules={quillModules}
                        formats={quillFormats}
                        theme='snow'
                        defaultValue={field.value}
                        onChange={onFieldChange}
                      />
                    );
                  }}
                </Field>
              </Flex>

              <Flex justify='space-between' mt='24px'>
                <Button
                  isLoading={createTenantStatus === LoadingStatus.PENDING || addressStatus.isLoad}
                  variant='darkBrand'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: '128px', md: '148px' }}
                  h='46px'
                  ms='auto'
                  type='submit'
                  disabled={!!Object.values(errors).length}
                >
                  {isEditForm ? 'Save changes' : 'Create tenant'}
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </Card>
  );
}
