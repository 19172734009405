// Chakra imports
import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import AgentOfferForm from '../components/forms/AgentOfferForm';

import { offerings } from 'api/admin';
import { API } from 'api/types';
import LeaveConfirmation from 'components/common/LeaveConfirmation';

import BasePreloader from 'components/preloader/BasePreloader';

import { LoadingStatus } from 'helpers/constants';
import { THistory } from 'types';

export default function CreateAgentOffer() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const itemId = Number(params.get('itemId'));
  const history = useHistory<THistory>();
  const offerUuid = params.get('offerUuid');
  const [getOfferStatus, setGetOfferStatus] = useState<LoadingStatus>(LoadingStatus.NONE);
  const [offer, setOffer] = useState<API.Admin.Offerings.Response | null>(null);

  const isOfferLoading = getOfferStatus === LoadingStatus.PENDING;
  const isOfferLoaded = getOfferStatus === LoadingStatus.FULFILLED;

  const loadOffering = async () => {
    try {
      setGetOfferStatus(LoadingStatus.PENDING);
      const { data } = await offerings.getById(offerUuid);
      setOffer(data);
      setGetOfferStatus(LoadingStatus.FULFILLED);
    } catch (e) {
      setGetOfferStatus(LoadingStatus.REJECTED);
    }
  };

  useEffect(() => {
    if (!offerUuid || !itemId) {
      history.push('/admin/offers/list');
    }
    loadOffering();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex direction='column' minH='100vh' align='center' pt={{ sm: '125px', lg: '75px' }} position='relative'>
      <LeaveConfirmation />
      <Box
        h='45vh'
        bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
        position='absolute'
        w='100%'
        borderRadius='30px'
      />
      <Tabs
        variant='unstyled'
        zIndex='0'
        mt={{ base: '60px', md: '165px' }}
        display='flex'
        flexDirection='column'
        isManual={true}
      >
        <TabList display='flex' alignItems='center' alignSelf='center' justifySelf='center'>
          <Tab w={{ sm: '120px', md: '250px', lg: '300px' }} _focus={{ boxShadow: 'none' }}>
            <Flex direction='column' justify='center' align='center' position='relative'>
              <Box
                zIndex='1'
                border='2px solid'
                borderColor={'white'}
                bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
                w='16px'
                h='16px'
                mb='8px'
                borderRadius='50%'
              />
              <Text color={'white'} fontWeight={'bold'} display={{ sm: 'none', md: 'block' }}>
                Create agent offer
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels mt='24px' maxW={{ md: '90%', lg: '100%' }} mx='auto'>
          <TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
            <Flex direction='column' minH='100vh' align='center' position='relative'>
              {isOfferLoading && <BasePreloader />}
              {isOfferLoaded && <AgentOfferForm itemId={itemId} offerId={offer.id} offerName={offer.name} />}
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
