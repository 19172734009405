/* eslint-disable react-hooks/exhaustive-deps */
import { AddIcon } from '@chakra-ui/icons';
import { Flex, Heading, Link } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Link as ReachLink } from 'react-router-dom';

import ProjectGoalsTable from '../../components/tables/ProjectGoalsTable';

import { API } from 'api/types';
import Card from 'components/card/Card';
import BasePreloader from 'components/preloader/BasePreloader';
import { defaultPaginationParams } from 'helpers/constants';
import usePermissions from 'hooks/usePermissions';
import { loadProjectGoals } from 'store/projectGoals';
import { selectLoadProjectGoalsStatus } from 'store/selectors';
import { useAppDispatch, useAppSelector } from 'store/store';

const ProjectGoalsList = () => {
  const dispatch = useAppDispatch();
  const { currentRouteUserPermissions } = usePermissions();
  const { canCreate } = currentRouteUserPermissions;

  const [paginationParams, setPaginationParams] = useState<API.Pagination.Request>(defaultPaginationParams);

  const loadItems = () => dispatch(loadProjectGoals(paginationParams));
  const loadGoalsStatus = useAppSelector(selectLoadProjectGoalsStatus);
  const isLoaded = loadGoalsStatus === 'fulfilled';

  useEffect(() => {
    loadItems();
  }, [paginationParams]);

  return (
    <Flex
      direction='column'
      minH='100vh'
      align='start'
      justifyContent='start'
      pt={{ sm: '125px', lg: '75px' }}
      position='relative'
    >
      {canCreate && (
        <Link as={ReachLink} to='/admin/offers/project-goals/create' maxWidth='400' width='100%'>
          <Card cursor='pointer'>
            <Flex me='-16px' justifyContent='space-between' alignItems='center'>
              <Heading size='xs'>Create new goal</Heading>
              <AddIcon mr='5' />
            </Flex>
          </Card>
        </Link>
      )}
      {isLoaded ? (
        <ProjectGoalsTable setPaginationParams={setPaginationParams} loadItems={loadItems} />
      ) : (
        <BasePreloader />
      )}
    </Flex>
  );
};

export default ProjectGoalsList;
