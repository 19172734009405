import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { giftcards } from 'api/admin';
import { API } from 'api/types';
import { LoadingStatus } from 'helpers/constants';

interface GiftcardsState {
  items: API.Admin.Giftcards.GiftCardItem[];
  status: LoadingStatus;
}

const initialState: GiftcardsState = {
  items: [],
  status: LoadingStatus.NONE,
};

export const loadGiftcards = createAsyncThunk('giftcards/list', async () => {
  const { data } = await giftcards.getAll();

  return data;
});

export const giftcardsSlice = createSlice({
  name: 'giftcards',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadGiftcards.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(loadGiftcards.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.items = action.payload;
      })
      .addCase(loadGiftcards.rejected, state => {
        state.status = LoadingStatus.REJECTED;
      });
  },
});

export default giftcardsSlice.reducer;
