import { createStandaloneToast } from '@chakra-ui/react';
import axios, { AxiosResponse } from 'axios';

import Cookies from 'js-cookie';

import { DEFAULT_DATA_SOURCE } from 'helpers/constants';

const toast = createStandaloneToast();
const baseURL = process.env.REACT_APP_API_BASE || 'https://api.squarefi.app';

export const instance = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'X-Data-Source': process.env.REACT_APP_API_DATA_SOURCE || DEFAULT_DATA_SOURCE,
  },
});

instance.interceptors.request.use(
  (config: any) => {
    const authToken = Cookies.get('authToken');
    if (authToken) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${authToken}`,
        },
      };
    }
    return config;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      Cookies.remove('authToken');
    } else {
      toast({
        title: error?.response?.data?.message || 'Error',
        description: '',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    return Promise.reject(error);
  }
);

interface RequestParams {
  params?: object;
  headers?: object;
}

interface ApiRequestParams extends RequestParams {
  data: object;
}

export const getRequest = async <T>(url: string, reqParams?: RequestParams): Promise<AxiosResponse<T>> => {
  const { params, headers } = reqParams ?? {};

  const config = {
    url,
    params,
    headers,
  };

  const res = await instance.get(url, config);

  return res;
};
export const postRequest = async <T>(url: string, reqParams?: ApiRequestParams): Promise<AxiosResponse<T>> => {
  const { data = {}, headers = {}, params = {} } = reqParams ?? {};

  const config = {
    headers,
    params,
  };

  const res = await instance.post(url, data, config);

  return res;
};
export const patchRequest = async <T>(url: string, reqParams?: ApiRequestParams): Promise<AxiosResponse<T>> => {
  const { data = {}, headers = {}, params = {} } = reqParams ?? {};

  const config = {
    headers,
    params,
  };

  const res = await instance.patch(url, data, config);

  return res;
};

export const deleteRequest = async (url: string, reqParams?: ApiRequestParams) => {
  const { headers = {}, params = {} } = reqParams ?? {};

  const config = {
    headers,
    params,
  };

  await instance.delete(url, config);
};
