import InfoForm from '../../../components/forms/InfoForm';

import Card from 'components/card/Card';

function Step1({ nextStep }: { nextStep: () => void }) {
  return (
    <Card p='30px'>
      <InfoForm nextStep={nextStep} />
    </Card>
  );
}

export default Step1;
