import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import UsersTable from '../../components/UsersTable';

import { user } from 'api/admin';
import { API } from 'api/types';

import BasePreloader from 'components/preloader/BasePreloader';
import { defaultPaginationParams, KYCStatus, LoadingStatus } from 'helpers/constants';
import { loadInvestorCategories, loadUserRoles } from 'store/common';
import { selectLoadInvestorCategoriesStatus, selectLoadUserRolesStatus, selectUsers } from 'store/selectors';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getUsers } from 'store/users';

export default function Users() {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);

  const loadInvestorCategoriesStatus = useAppSelector(selectLoadInvestorCategoriesStatus);
  const loadUserRolesStatus = useAppSelector(selectLoadUserRolesStatus);
  const isInvestorCategoriesFullfilled = loadInvestorCategoriesStatus === LoadingStatus.FULFILLED;
  const isUsersRolesFullfilled = loadUserRolesStatus === LoadingStatus.FULFILLED;

  const [isDataPrepared, setIsDataPrepared] = useState(false);

  const [requestParams, setRequestParams] = useState<API.Pagination.Request>(defaultPaginationParams);

  const changeRefferalStatus = async (selectedUser: API.Admin.User.Item, targetRefferalStatus: number) => {
    await user.update(selectedUser.id, { ...selectedUser, ref_tier_id: targetRefferalStatus });
    await dispatch(getUsers(requestParams));
  };
  const changeKYCStatus = async (selectedUser: API.Admin.User.Item, kyc_status: KYCStatus) => {
    await user.update(selectedUser.id, { ...selectedUser, kyc_status });
    await dispatch(getUsers(requestParams));
  };

  const prepareData = async () => {
    const prepareDataFunctions = [];

    !isInvestorCategoriesFullfilled && prepareDataFunctions.push(dispatch(loadInvestorCategories()));
    !isUsersRolesFullfilled && prepareDataFunctions.push(dispatch(loadUserRoles()));
    prepareDataFunctions.push(dispatch(getUsers(requestParams)));

    await Promise.all(prepareDataFunctions);
    setIsDataPrepared(true);
  };

  useEffect(() => {
    isDataPrepared && dispatch(getUsers(requestParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams]);

  useEffect(() => {
    prepareData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      direction='column'
      minH='100vh'
      align='start'
      justifyContent='start'
      pt={{ sm: '125px', lg: '75px' }}
      position='relative'
    >
      {isDataPrepared ? (
        <UsersTable
          tableData={users}
          setRequestParams={setRequestParams}
          requestParams={requestParams}
          changeRefferalStatus={changeRefferalStatus}
          changeKYCStatus={changeKYCStatus}
        />
      ) : (
        <BasePreloader />
      )}
    </Flex>
  );
}
