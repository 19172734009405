/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  Checkbox,
  createStandaloneToast,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Grid,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { Field, FieldArray, FieldAttributes, Formik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { acceptedImgFileTypes } from '../config';

import { offering_items } from 'api/admin';
import { API } from 'api/types';
import CustomPhotoUploader from 'components/common/CustomPhotoUploader';
import InputField from 'components/fields/InputField';
import { LoadingStatus } from 'helpers/constants';
import useDownloadData from 'hooks/useDownloadData';
import { useLeaveConfirmation } from 'hooks/useLeaveConfirmation';
import { loadCompliance, loadInvestorCategories } from 'store/common';
import { loadAllProjectGoals } from 'store/projectGoals';
import {
  selectCompliance,
  selectInvestorCategories,
  selectLoadComplianceStatus,
  selectLoadInvestorCategoriesStatus,
  selectPaymentTypes,
  selectProjectGoals,
} from 'store/selectors';
import { useAppDispatch, useAppSelector } from 'store/store';
import { THistory, TLocation } from 'types';
import { convertTimeStampToDayDate } from 'utils';
import { convertDecimalToPercent, convertPercentToDecimal } from 'utils/converters';

type ItemFormProps = {
  isEditForm?: boolean;
  isCreateOfferStep?: boolean;
};

type onArrayCheckboxChange = (
  e: ChangeEvent<HTMLInputElement>,
  values: API.Admin.OfferingItems.Request,
  setValues: (values: React.SetStateAction<API.Admin.OfferingItems.Request>, shouldValidate?: boolean) => void,
  targetFieldName: keyof API.Admin.OfferingItems.Request
) => void;

const toast = createStandaloneToast();

const today = new Date();
const nextYear = new Date(new Date().setMonth(today.getMonth() + 1));

const currentTimestamp = today.getTime();
const nextYearTimestamp = nextYear.getTime();

const initialValues: API.Admin.OfferingItems.Request = {
  kyc_required: true,
  price: 1,
  asset: 'USDT',
  investment_start: currentTimestamp,
  investment_end: nextYearTimestamp,
  min_investment: 1,
  compliance_id: 2,
  commitment_period: 1,
  yearly_income: 1,
  exit_price: 1,
  total: 1, //tokens count
  investor_categories_id: [],
  prepay: false,
  prepay_value: 0,
  hide_goals: false,
  soft_cap: 0,
  project_goals: [],
  platfrom_expenses: 0,
  voting: false,
  payment_types_id: [],
};

const emptyGoal: API.Admin.OfferingItems.OfferingItemsProjectGoal = {
  project_goals_id: null,
  value: '',
};

export default function ItemForm({ isEditForm = false, isCreateOfferStep = false }: ItemFormProps) {
  const [createItemStatus, setCreateItemStatus] = useState<LoadingStatus>();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { disableLeaveConfirmation } = useLeaveConfirmation();

  const [itemInfo, itemInfoStatus, getItemInfo] = useDownloadData<API.Admin.OfferingItems.Response>(
    offering_items.getById
  );

  const dispatch = useAppDispatch();
  const history = useHistory<THistory>();
  const location = useLocation<TLocation>();
  const params = new URLSearchParams(location.search);
  const offerId = params.get('offerId');
  const itemId = params.get('itemId');

  const investorCategories = useAppSelector(selectInvestorCategories);
  const paymentTypes = useAppSelector(selectPaymentTypes);
  const compliance = useAppSelector(selectCompliance);
  const projectGoals = useAppSelector(selectProjectGoals);

  const loadInvestorCategoriesStatus = useAppSelector(selectLoadInvestorCategoriesStatus);
  const loadComplianceStatus = useAppSelector(selectLoadComplianceStatus);

  const isInvestorCategoriesFullfilled = loadInvestorCategoriesStatus === LoadingStatus.FULFILLED;
  const isComplianceFullfilled = loadComplianceStatus === LoadingStatus.FULFILLED;
  const isProjectGoalsFullfilled = projectGoals.meta.nextPage === null;
  const isItemFullfilled = itemInfoStatus === LoadingStatus.FULFILLED || !isEditForm;

  const isLoaded =
    isInvestorCategoriesFullfilled && isProjectGoalsFullfilled && isItemFullfilled && isComplianceFullfilled;

  const createItem = async (data: API.Admin.OfferingItems.Request) => {
    setCreateItemStatus(LoadingStatus.PENDING);

    try {
      await offering_items.create(offerId, data);
      setCreateItemStatus(LoadingStatus.FULFILLED);
      disableLeaveConfirmation();
      if (isCreateOfferStep) {
        history.push({
          pathname: '/admin/offers/list',
        });
      } else {
        history.push({
          pathname: `/admin/offers/items`,
          search: `?offerId=${offerId}`,
        });
      }
    } catch (e) {
      setCreateItemStatus(LoadingStatus.REJECTED);
      throw e;
    }
  };

  const patchItem = async (data: API.Admin.OfferingItems.Request) => {
    setCreateItemStatus(LoadingStatus.PENDING);

    try {
      await offering_items.update(itemId, data);

      setCreateItemStatus(LoadingStatus.FULFILLED);
      disableLeaveConfirmation();
      toast({
        title: `Data has been successfully updated`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });

      history.push({
        pathname: `/admin/offers/items`,
        search: `?offerId=${offerId}`,
      });
    } catch (e) {
      setCreateItemStatus(LoadingStatus.REJECTED);
      throw e;
    }
  };

  const onArrayCheckboxChange: onArrayCheckboxChange = (e, values, setValues, targetFieldName) => {
    const currentId = e.target?.labels[0]?.dataset?.id;
    const targetValue = isNaN(+currentId) ? currentId : +currentId;
    const targetField = (values[targetFieldName] as Array<number | string>) || [];

    const indexOfCurrentId = targetField?.findIndex(id => id === targetValue);

    if (indexOfCurrentId !== -1) {
      targetField.splice(indexOfCurrentId, 1);
    } else {
      targetField.push(targetValue);
    }
    setValues({ ...values, [targetFieldName]: targetField });
  };

  useEffect(() => {
    !isInvestorCategoriesFullfilled && dispatch(loadInvestorCategories());
    !isComplianceFullfilled && dispatch(loadCompliance());
    !isProjectGoalsFullfilled && dispatch(loadAllProjectGoals());

    if (isEditForm && itemId) {
      getItemInfo(itemId);
    }
  }, []);

  if (!isLoaded) {
    return (
      <Flex w='100%' h='500px' justifyContent='center' alignItems='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brand.500' size='xl' />
      </Flex>
    );
  }

  const prepareFormData = (values: API.Admin.OfferingItems.Response) => {
    return {
      ...values,
      prepay_value: convertDecimalToPercent(values.prepay_value),
      compliance_id: values.compliance?.id,
    };
  };

  const prepareSendingData = (values: API.Admin.OfferingItems.Request) => {
    const sendData: API.Admin.OfferingItems.Request = {
      ...values,
      prepay_value: convertPercentToDecimal(values.prepay_value),
      compliance_id: +values.compliance_id,
      investment_start: values.investment_start && new Date(values.investment_start).getTime(),
      investment_end: values.investment_end && new Date(values.investment_end).getTime(),
    };
    return sendData;
  };

  const submitFunc = (values: API.Admin.OfferingItems.Request) => {
    const data = prepareSendingData(values);
    isEditForm ? patchItem(data) : createItem(data);
  };

  return (
    <Formik
      validateOnChange={isFormDirty}
      validateOnBlur={isFormDirty}
      initialValues={isEditForm ? prepareFormData(itemInfo) : initialValues}
      validationSchema={Yup.object({
        kyc_required: Yup.boolean(),
        hide_goals: Yup.boolean(),
        asset: Yup.string(),
        total: Yup.number(),
        price: Yup.number(),
        commitment_period: Yup.number(),
        yearly_income: Yup.number(),
        exit_price: Yup.number(),
        lookup_month: Yup.number(),
        funding_goal: Yup.number(),
        min_investment: Yup.number(),
        max_investment: Yup.number(),
        soft_cap: Yup.number(),
        project_goals: Yup.array().of(
          Yup.object().shape({
            project_goals_id: Yup.string().required('Required'),
            value: Yup.string().required('Required'),
          })
        ),
        platfrom_expenses: Yup.number().required('Required'),
      })}
      onSubmit={submitFunc}
    >
      {({ values, errors, handleChange, handleSubmit, setValues }) => (
        <form
          onSubmit={e => {
            setIsFormDirty(true);
            handleSubmit(e);
          }}
        >
          <Flex direction='column' w='100%'>
            <Stack direction='column' spacing='20px' mb='50px'>
              <SimpleGrid columns={{ base: 1, md: 2 }} gap='20px'>
                <FormControl isInvalid={!!errors.price} mb='0'>
                  <InputField
                    mb='0px'
                    name='price'
                    type='number'
                    label='Price per token, $'
                    placeholder='Price'
                    onChange={handleChange}
                    value={values.price}
                    // disabled
                  />
                  {errors.price ? (
                    <FormErrorMessage>{errors.price}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='26px'></FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.total} mb='0'>
                  <InputField
                    mb='0px'
                    name='total'
                    type='number'
                    label='Total, tokens'
                    placeholder='Total'
                    onChange={handleChange}
                    value={values.total}
                  />
                  {errors.total ? (
                    <FormErrorMessage>{errors.total}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='26px'></FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.investment_start} mb='0'>
                  <InputField
                    mb='0px'
                    name='investment_start'
                    type='date'
                    label='Start date of investment collection'
                    onChange={handleChange}
                    value={values.investment_start ? convertTimeStampToDayDate(values.investment_start) : undefined}
                  />
                  {errors.investment_start ? (
                    <FormErrorMessage>{errors.investment_start}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='26px'></FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.investment_end} mb='0'>
                  <InputField
                    mb='0px'
                    name='investment_end'
                    type='date'
                    label='End date of the investment collection'
                    onChange={handleChange}
                    value={values.investment_end ? convertTimeStampToDayDate(values.investment_end) : undefined}
                  />
                  {errors.investment_end ? (
                    <FormErrorMessage>{errors.investment_end}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='26px'></FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.commitment_period} mb='0'>
                  <InputField
                    mb='0px'
                    name='commitment_period'
                    type='number'
                    label='Сommitment period, years'
                    placeholder='Input years'
                    onChange={handleChange}
                    value={values.commitment_period}
                  />
                  {errors.commitment_period ? (
                    <FormErrorMessage>{errors.commitment_period}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='26px'></FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.lookup_month} mb='0'>
                  <InputField
                    mb='0px'
                    name='lookup_month'
                    type='number'
                    label='Lookup month'
                    placeholder='Input month'
                    onChange={handleChange}
                    value={values.lookup_month}
                  />
                  {errors.lookup_month ? (
                    <FormErrorMessage>{errors.lookup_month}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='26px'></FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.funding_goal} mb='0'>
                  <InputField
                    mb='0px'
                    name='funding_goal'
                    type='number'
                    label='Funding goal, $'
                    placeholder='Input funding goal'
                    onChange={handleChange}
                    value={values.funding_goal}
                  />
                  {errors.funding_goal ? (
                    <FormErrorMessage>{errors.funding_goal}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='26px'></FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.exit_price} mb='0'>
                  <InputField
                    mb='0px'
                    name='exit_price'
                    type='number'
                    label='Exit price, $'
                    placeholder='Input exit price'
                    onChange={handleChange}
                    value={values.exit_price}
                  />
                  {errors.exit_price ? (
                    <FormErrorMessage>{errors.exit_price}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='26px'></FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.yearly_income} mb='0'>
                  <InputField
                    mb='0px'
                    name='yearly_income'
                    type='number'
                    label='Yearly income, $'
                    placeholder='Input income'
                    onChange={handleChange}
                    value={values.yearly_income}
                  />
                  {errors.yearly_income ? (
                    <FormErrorMessage>{errors.yearly_income}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='26px'></FormHelperText>
                  )}
                </FormControl>

                <FormControl isInvalid={!!errors.asset} mb='0'>
                  <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                    Asset
                  </Text>
                  <Select
                    fontSize='sm'
                    name='asset'
                    h='44px'
                    maxH='44px'
                    me='20px'
                    onChange={handleChange}
                    defaultValue={values.asset}
                  >
                    <option value='USDT'>USDT</option>
                  </Select>
                </FormControl>

                <FormControl isInvalid={!!errors.min_investment} mb='0'>
                  <InputField
                    mb='0px'
                    type='number'
                    placeholder='Minimal investment amount'
                    label='Minimal investment, $'
                    name='min_investment'
                    h='100px'
                    value={values.min_investment}
                    onChange={handleChange}
                  />
                  {errors.min_investment ? (
                    <FormErrorMessage>{errors.min_investment}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='26px'></FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.max_investment} mb='0'>
                  <InputField
                    mb='0px'
                    placeholder='Maximum investment amount'
                    label='Maximum investment, $'
                    name='max_investment'
                    h='100px'
                    value={values.max_investment}
                    onChange={handleChange}
                  />
                  {errors.max_investment ? (
                    <FormErrorMessage>{errors.max_investment}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='26px'></FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.soft_cap} mb='0'>
                  <InputField
                    mb='0px'
                    placeholder='Soft cap amount'
                    label='Soft cap, $'
                    name='soft_cap'
                    h='100px'
                    type='number'
                    value={values.soft_cap}
                    onChange={handleChange}
                  />
                  {errors.soft_cap ? (
                    <FormErrorMessage>{errors.soft_cap}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='26px'></FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.platfrom_expenses} mb='0'>
                  <InputField
                    mb='0px'
                    placeholder='Platform expenses amount'
                    label='Platform expenses, $'
                    name='platfrom_expenses'
                    h='100px'
                    type='number'
                    value={values.platfrom_expenses}
                    onChange={handleChange}
                  />
                  {errors.platfrom_expenses ? (
                    <FormErrorMessage>{errors.platfrom_expenses}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='26px'></FormHelperText>
                  )}
                </FormControl>
              </SimpleGrid>
              <FormControl isInvalid={!!errors.compliance_id} mb='0'>
                <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                  Compliance
                </Text>
                <Select
                  fontSize='sm'
                  name='compliance_id'
                  h='44px'
                  maxH='44px'
                  me='20px'
                  onChange={handleChange}
                  value={values.compliance_id}
                >
                  {compliance.map(({ id, regulation }) => (
                    <option key={id} value={id}>
                      {regulation}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isInvalid={!!errors.compliance_id} mb='0'>
                <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                  Project goals
                </Text>
                <FieldArray name='project_goals'>
                  {({ push, remove }) => (
                    <Flex direction='column' justifyContent='center' scrollMarginTop='180' gap={2}>
                      {values.project_goals.map((item, index) => (
                        <Grid key={index} templateColumns='repeat(2, 1fr)' columnGap={2} w='100%'>
                          <Field name={`project_goals[${index}].project_goals_id`}>
                            {({ field, form }: FieldAttributes<any>) => (
                              <FormControl isInvalid={!!form.errors.project_goals?.[index]?.project_goals_id}>
                                <Flex direction='column'>
                                  <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                                    Goal
                                    <Button
                                      variant='ghost'
                                      onClick={() => remove(index)}
                                      colorScheme='red'
                                      size='xs'
                                      height='fit-content'
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </Text>
                                  <Select
                                    fontSize='sm'
                                    id='locales_id'
                                    name={`project_goals[${index}].project_goals_id`}
                                    h='44px'
                                    maxH='44px'
                                    onBlur={handleChange}
                                    placeholder='Select goal'
                                    defaultValue={field.value}
                                  >
                                    {projectGoals.items.map(goal => (
                                      <option key={goal.id} value={goal.id}>
                                        {goal.name}
                                      </option>
                                    ))}
                                  </Select>
                                </Flex>
                                {form.errors.project_goals?.[index]?.project_goals_id ? (
                                  <FormErrorMessage>
                                    {form.errors.project_goals?.[index]?.project_goals_id}
                                  </FormErrorMessage>
                                ) : (
                                  <FormHelperText mb='25px' />
                                )}
                              </FormControl>
                            )}
                          </Field>
                          <Field name={`project_goals[${index}].value`}>
                            {({ field, form }: FieldAttributes<any>) => (
                              <FormControl isInvalid={!!form.errors.project_goals?.[index]?.value}>
                                <InputField
                                  mb='0px'
                                  id={`project_goals[${index}].value`}
                                  name={`project_goals[${index}].value`}
                                  placeholder={`Input goal ${index + 1} value`}
                                  label={`Goal ${index + 1} value`}
                                  onChange={field.onChange}
                                  value={field.value}
                                />
                                {form.errors.project_goals?.[index]?.value ? (
                                  <FormErrorMessage>{form.errors.project_goals?.[index]?.value}</FormErrorMessage>
                                ) : (
                                  <FormHelperText mb='25px' />
                                )}
                              </FormControl>
                            )}
                          </Field>
                        </Grid>
                      ))}
                      <Button colorScheme='blue' onClick={() => push(emptyGoal)}>
                        Add new project goal
                      </Button>
                    </Flex>
                  )}
                </FieldArray>
              </FormControl>
              <Flex flexDirection='column'>
                <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                  Payments types
                </Text>
                <FormControl isInvalid={!!errors.payment_types_id} mb='0'>
                  <Grid gap='20px' gridTemplateColumns={{ base: 'min-content', md: 'repeat(4,min-content)' }}>
                    {paymentTypes &&
                      paymentTypes.map(({ id, code }) => (
                        <Checkbox
                          colorScheme='brand'
                          onChange={e => onArrayCheckboxChange(e, values, setValues, 'payment_types_id')}
                          isChecked={values.payment_types_id?.includes(id)}
                          key={id}
                          data-id={id}
                        >
                          {code}
                        </Checkbox>
                      ))}
                  </Grid>
                </FormControl>
              </Flex>
              <Flex flexDirection='column'>
                <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                  Investor categories
                </Text>
                <FormControl isInvalid={!!errors.investor_categories_id} mb='0'>
                  <Flex gap='20px' flexWrap={'wrap'} flexDirection={{ base: 'column', md: 'row' }}>
                    {investorCategories &&
                      investorCategories.map(({ id, category_name }) => (
                        <Checkbox
                          colorScheme='brand'
                          onChange={e => onArrayCheckboxChange(e, values, setValues, 'investor_categories_id')}
                          isChecked={values.investor_categories_id?.includes(id)}
                          key={id}
                          data-id={id}
                        >
                          {category_name}
                        </Checkbox>
                      ))}
                  </Flex>
                </FormControl>
              </Flex>
              <Flex gap='20px' flexDirection={{ base: 'column', md: 'row' }}>
                <Flex flexDirection='column'>
                  <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                    Voting
                  </Text>
                  <Switch
                    id='voting'
                    colorScheme='brand'
                    name='voting'
                    onChange={handleChange}
                    isChecked={values.voting}
                    ml='1'
                  />
                </Flex>
                <Flex flexDirection='column'>
                  <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                    KYC required
                  </Text>
                  <Switch
                    id='kyc_required'
                    colorScheme='brand'
                    name='kyc_required'
                    onChange={handleChange}
                    isChecked={values.kyc_required}
                    ml='1'
                  />
                </Flex>
                <Flex flexDirection='column'>
                  <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                    Hide goals
                  </Text>
                  <Switch
                    id='hide_goals'
                    colorScheme='brand'
                    name='hide_goals'
                    onChange={handleChange}
                    isChecked={values.hide_goals}
                    ml='1'
                  />
                </Flex>
                <Flex flexDirection='column'>
                  <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                    Prepay, %
                  </Text>
                  <Switch
                    id='prepay'
                    colorScheme='brand'
                    name='prepay'
                    onChange={handleChange}
                    isChecked={values.prepay}
                    defaultChecked={values.prepay}
                    ml='1'
                  />
                </Flex>
              </Flex>

              {values.prepay && (
                <InputField
                  mb='0px'
                  label='Prepay value, %'
                  type='number'
                  placeholder='Input prepay %'
                  name='prepay_value'
                  value={values.prepay_value}
                  onChange={handleChange}
                  w='170px'
                />
              )}
              <CustomPhotoUploader
                photoFromServer={itemInfo?.image}
                acceptedFileTypes={acceptedImgFileTypes}
                allowMultiple
                setExternalPhotos={photos => (values.image = photos)}
              />
            </Stack>
            <Flex justify='flex-end' mt='24px'>
              <Button
                isLoading={createItemStatus === LoadingStatus.PENDING}
                variant='darkBrand'
                fontSize='sm'
                borderRadius='16px'
                type='submit'
                w={{ base: '128px', md: '148px' }}
                h='46px'
              >
                Submit
              </Button>
            </Flex>
          </Flex>
        </form>
      )}
    </Formik>
  );
}
