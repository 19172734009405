/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  createStandaloneToast,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';

import 'filepond/dist/filepond.min.css';

import { Formik } from 'formik';
import { FC, useState } from 'react';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { agent_offers } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';

import InputField from 'components/fields/InputField';
import { LoadingStatus } from 'helpers/constants';
import { useLeaveConfirmation } from 'hooks/useLeaveConfirmation';
import { THistory } from 'types';

type AgentOfferFormProps = {
  itemId: number;
  offerId: number;
  offerName: string;
};

const AgentOfferForm: FC<AgentOfferFormProps> = ({ itemId, offerName, offerId }) => {
  const initialValues: API.Admin.AgentOffers.Request = {
    item_id: itemId,
    offering_id: offerId,
    comission: 10,
    max_discount: 5,
    description: '',
  };

  const [createAgentOfferStatus, setCreateAgentOfferStatus] = useState<LoadingStatus>(LoadingStatus.NONE);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { disableLeaveConfirmation } = useLeaveConfirmation();

  const history = useHistory<THistory>();

  const toast = createStandaloneToast();

  const isAgentOfferCreating = createAgentOfferStatus === LoadingStatus.PENDING;

  const submitData = async (formData: API.Admin.AgentOffers.Request) => {
    const sendingData = { ...formData };

    try {
      setCreateAgentOfferStatus(LoadingStatus.PENDING);
      await agent_offers.create(sendingData);

      toast({
        title: `AgentOffer has been successfully created`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      setCreateAgentOfferStatus(LoadingStatus.FULFILLED);
      disableLeaveConfirmation();
      history.push({
        pathname: '/admin/agent-offers/list',
      });
    } catch (e) {
      setCreateAgentOfferStatus(LoadingStatus.REJECTED);
    }
  };

  return (
    <Card p='30px'>
      <Text mb='10' fontSize='2xl'>
        Offer name: <strong>{offerName}</strong>
      </Text>
      <Formik
        validateOnChange={isFormDirty}
        validateOnBlur
        initialValues={initialValues}
        validationSchema={Yup.object({
          item_id: Yup.number().required('Field is required'),
          comission: Yup.number()
            .moreThan(0, 'Number must be greater than zero')
            .lessThan(100000, 'Number must be less than 100')
            .required('Field is required'),
          max_discount: Yup.number()
            .moreThan(0, 'Number must be greater than zero')
            .lessThan(100000, 'Number must be less than 100')
            .required('Field is required')
            .test('max_discount', 'Max discount must be less than comission', function (value) {
              return value < this.parent.comission;
            }),
        })}
        onSubmit={submitData}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form
            onSubmit={e => {
              setIsFormDirty(true);
              handleSubmit(e);
            }}
          >
            <Flex direction='column' w='100%'>
              <Stack direction={{ base: 'column' }} spacing='20px'>
                <Stack direction='row' spacing='20px'>
                  <FormControl isInvalid={!!errors.comission} mb='0'>
                    <InputField
                      mb='0px'
                      id='comission'
                      name='comission'
                      placeholder='Input comission'
                      label='Comission'
                      onChange={handleChange}
                      value={values.comission}
                      type='number'
                    />
                    {errors?.comission ? (
                      <FormErrorMessage>{errors?.comission}</FormErrorMessage>
                    ) : (
                      <FormHelperText mb='25px' />
                    )}
                  </FormControl>
                  <FormControl isInvalid={!!errors.max_discount} mb='0'>
                    <InputField
                      mb='0px'
                      id='max_discount'
                      name='max_discount'
                      placeholder='Input max discount'
                      label='Max discount'
                      onChange={handleChange}
                      value={values.max_discount}
                    />
                    {errors?.max_discount ? (
                      <FormErrorMessage>{errors?.max_discount}</FormErrorMessage>
                    ) : (
                      <FormHelperText mb='25px' />
                    )}
                  </FormControl>
                </Stack>

                <FormControl>
                  <Text fontSize='sm' mb='8px' ml='10px' fontWeight='700'>
                    Description
                  </Text>
                  <Textarea
                    fontSize='sm'
                    id='description'
                    name='description'
                    placeholder='Input description'
                    resize='vertical'
                    onChange={handleChange}
                    value={values.description}
                  />
                </FormControl>
              </Stack>
              <Flex justify='space-between' mt='24px'>
                <Button
                  isLoading={isAgentOfferCreating}
                  variant='darkBrand'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: '128px', md: '148px' }}
                  h='46px'
                  ms='auto'
                  type='submit'
                  disabled={!!Object.values(errors).length}
                >
                  Create
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default AgentOfferForm;
