import { API } from './types';

import { deleteRequest, getRequest, instance, patchRequest, postRequest } from 'api';
import { OfferUploadPhoto } from 'types';
import { GeocodeResult, GeocodeResultGeo } from 'utils/geocoder';

export const assets = {
  getAll: (item_uuid: string) => {
    return getRequest<API.Admin.Assets.Asset[]>('api:admin/assets', { params: { item_uuid } });
  },
  documents: {
    getAll: (asset_id: number) => {
      return getRequest<API.Admin.Assets.Document.Response[]>(`api:admin/assets/${asset_id}/documents`);
    },
    create: (asset_id: number, data: API.Admin.Assets.Document.PostRequest) => {
      return postRequest<API.Admin.Assets.Document.Response[]>(`api:admin/assets/${asset_id}/documents`, { data });
    },
    delete: (asset_document_id: string) => {
      return deleteRequest(`api:admin/assets/${asset_document_id}/documents`);
    },
    update: (asset_id: number, data: API.Admin.Assets.Document.PatchRequest) => {
      return patchRequest<API.Admin.Assets.Document.Response[]>(`api:admin/assets/${asset_id}/documents`, {
        data,
      });
    },
  },
};

export const agent_offers = {
  getAll: () => {
    return getRequest<API.Admin.AgentOffers.Response[]>('api:admin/agent_offers');
  },
  getById: (id: string) => {
    return getRequest<API.Admin.AgentOffers.Response>(`api:admin/agent_offers/${id}`);
  },
  create: (data: API.Admin.AgentOffers.Request) => {
    return postRequest<API.Admin.AgentOffers.Response>('/api:admin/agent_offers', { data });
  },
  delete: (id: number) => {
    return deleteRequest(`/api:admin/agent_offers/${id}`);
  },
};

export const blockchains = {
  getAll: () => {
    return getRequest<API.Admin.Blockchains.Response[]>('api:admin/blockchains');
  },
};

export const compliance = {
  getAll: () => {
    return getRequest<API.Admin.Compliance.Response[]>('api:admin/compliance');
  },
};

export const crypto_assets = {
  getAll: () => {
    return getRequest<API.Admin.CryptoAssets.Response[]>('api:admin/crypto_assets');
  },
};

export const developers = {
  getAll: () => {
    return getRequest<API.Admin.Developer.Response[]>('api:admin/developers');
  },
  getById: (developer_uuid: string) => {
    return getRequest<API.Admin.Developer.Response>(`api:admin/developers/${developer_uuid}`);
  },
  create: (data: API.Admin.Developer.Request) => {
    return postRequest<API.Admin.Developer.Response>(`/api:admin/developers`, { data });
  },
  update: (developer_uuid: string, data: API.Admin.Developer.Request) => {
    return patchRequest<API.Admin.Developer.Response>(`/api:admin/developers/${developer_uuid}`, { data });
  },
  delete: (developer_uuid: string) => {
    return deleteRequest(`api:admin/developers/${developer_uuid}`);
  },
};

export const fundraisers = {
  getAll: () => {
    return getRequest<API.Admin.Fundraisers.Response[]>('api:admin/fundraiser');
  },
  getById: (uuid: string) => {
    return getRequest<API.Admin.Fundraisers.Response>(`api:admin/fundraiser/${uuid}`);
  },
  create: (data: API.Admin.Fundraisers.Request) => {
    return postRequest<API.Admin.Fundraisers.Response>('api:admin/fundraiser', { data });
  },
  update: (uuid: string, data: API.Admin.Fundraisers.Request) => {
    return patchRequest<API.Admin.Fundraisers.Response>(`api:admin/fundraiser/${uuid}`, { data });
  },
  delete: (uuid: string) => {
    return deleteRequest(`api:admin/fundraiser/${uuid}`);
  },
};

export const giftcards = {
  getById: (giftcardId: number) => getRequest<API.Admin.Giftcards.GiftCardItem>(`api:admin/giftcards/${giftcardId}`),
  getAll: () => getRequest<API.Admin.Giftcards.GiftCardItem[]>('api:admin/giftcards'),
  create: (data: API.Admin.Giftcards.CreateData) =>
    postRequest<API.Admin.Giftcards.GiftCardItem>('api:admin/giftcards', { data }),
  createBulk: (data: API.Admin.Giftcards.BulkData) =>
    postRequest<API.Admin.Giftcards.GiftCardItem>('api:admin/giftcards/bulk', { data }),
  delete: (giftcardId: number) => deleteRequest(`api:admin/giftcards/${giftcardId}`),
};

export const investor_categories = {
  getAll: () => {
    return getRequest<API.Admin.InvestorCategories.Response[]>('api:admin/investor_categories');
  },
  create: (data: API.Admin.InvestorCategories.Request) => {
    return postRequest<API.Admin.InvestorCategories.Response>(`/api:admin/investor_categories`, { data });
  },
};

export const offerings = {
  getAll: () => {
    return getRequest<API.Admin.Offerings.Response[]>('api:admin/offerings');
  },
  getById: (uuid: string) => {
    return getRequest<API.Admin.Offerings.Response>(`api:admin/offerings/${uuid}`);
  },
  create: (data: API.Admin.Offerings.Request) => {
    return postRequest<API.Admin.Offerings.Response>(`/api:admin/offerings`, { data });
  },
  delete: (uuid: string) => {
    return deleteRequest(`/api:admin/offerings/${uuid}`);
  },
  changeAddress: (uuid: string, address: GeocodeResult, geo: GeocodeResultGeo) => {
    return patchRequest(`/api:admin/offerings/${uuid}/address`, { data: { address, geo } });
  },
  changeStatus: (uuid: string, status: string) => {
    return patchRequest(`/api:admin/offerings/${uuid}/status`, { data: { status } });
  },
  changeVisibility: (uuid: string, visibility: string) => {
    return patchRequest(`/api:admin/offerings/${uuid}/visibility`, { data: { visibility } });
  },
  uploadImages: (uuid: string, images: OfferUploadPhoto[]) => {
    return patchRequest(`/api:admin/offerings/${uuid}/images`, { data: { images } });
  },
  getCryptoAccounts: (uuid: string) => {
    return getRequest<API.Admin.Offerings.CryptoAccounts.Response>(`api:admin/offerings/${uuid}/crypto_accounts`);
  },
  importCryptoAccounts: (uuid: string, data: API.Admin.Offerings.CryptoAccounts.ImportAdress) => {
    return postRequest<API.Admin.Offerings.CryptoAccounts.Response>(
      `api:admin/offerings/${uuid}/crypto_accounts/import`,
      {
        data,
      }
    );
  },
  generateCryptoAccount: (uuid: string, data: API.Admin.Offerings.CryptoAccounts.GenerateAddress) => {
    return postRequest<API.Admin.CryptoAssets.Response>(`api:admin/offerings/${uuid}/crypto_accounts/generate`, {
      data,
    });
  },
  deleteCryptoAccount: (offering_uuid: string, crypto_account_uuid: string) => {
    return deleteRequest(`api:admin/offerings/${offering_uuid}/crypto_accounts/${crypto_account_uuid}`);
  },
};

export const offerings_info = {
  get: async (uuid: string, lang: string) => {
    return getRequest<API.Admin.OfferingsAdditionalInfo.Response>(`/api:admin/offerings_info/${uuid}/${lang}`);
  },
  create: async (uuid: string, data: API.Admin.OfferingsAdditionalInfo.Request) => {
    return postRequest<API.Admin.OfferingsAdditionalInfo.Response>(`/api:admin/offerings_info/${uuid}`, { data });
  },
  update: async (uuid: string, lang: string, data: API.Admin.OfferingsAdditionalInfo.Request) => {
    return patchRequest<API.Admin.OfferingsAdditionalInfo.Response>(`/api:admin/offerings_info/${uuid}/${lang}`, {
      data,
    });
  },
};

export const offering_items = {
  getAll: (offering_uuid: string) => {
    return getRequest<API.Admin.OfferingItems.Response[]>(`/api:admin/offering_items/${offering_uuid}`);
  },
  getById: (item_uuid: string) => {
    return getRequest<API.Admin.OfferingItems.Response>(`/api:admin/offering_items/item/${item_uuid}`);
  },
  create: (offering_uuid: string, data: API.Admin.OfferingItems.Request) => {
    return postRequest<API.Admin.OfferingItems.Response>(`/api:admin/offering_items/${offering_uuid}`, { data });
  },
  update: (item_uuid: string, data: API.Admin.OfferingItems.Request) => {
    return patchRequest<API.Admin.OfferingItems.Response>(`/api:admin/offering_items/item/${item_uuid}`, { data });
  },
  updates: {
    getAll: (item_uuid: string) => {
      return getRequest<API.Admin.OfferingItems.Updates.Response[]>(
        `/api:admin/offering_items/item/${item_uuid}/updates`
      );
    },
    create: (item_uuid: string, data: API.Admin.OfferingItems.Updates.Request) => {
      return postRequest<API.Admin.OfferingItems.Updates.Response>(
        `/api:admin/offering_items/item/${item_uuid}/updates`,
        { data }
      );
    },
    update: (update_id: number, data: API.Admin.OfferingItems.Updates.Request) => {
      return patchRequest<API.Admin.OfferingItems.Updates.Response>(
        `/api:admin/offering_items/item/${update_id}/updates`,
        { data }
      );
    },
    delete: (update_id: number) => {
      return deleteRequest(`/api:admin/offering_items/item/${update_id}/updates`);
    },
  },
};

export const order = {
  getAll: (params: API.Admin.Order.GetAll.Request) => {
    return getRequest<API.Admin.Order.GetAll.Response>('api:admin/order', { params });
  },
  create: (data: API.Admin.Order.Create.Request) => {
    return postRequest<API.Admin.Order.Order>('api:admin/order', { data });
  },
  delete: (uuid: string) => {
    return deleteRequest(`api:admin/order/${uuid}`);
  },
};

export const payment_types = {
  getAll: () => {
    return getRequest<API.Admin.PaymentTypes.PaymentType[]>('api:admin/payment_types');
  },
};

export const project_goals = {
  getAll: (params: API.Pagination.Request) => {
    return getRequest<API.Admin.ProjectGoals.Response>('api:admin/project_goals', { params });
  },
  getById: (id: string) => {
    return getRequest<API.Admin.ProjectGoals.Item>(`/api:admin/project_goals/${id}`);
  },
  create: (data: API.Admin.ProjectGoals.Request) => {
    return postRequest<API.Admin.ProjectGoals.Item>('/api:admin/project_goals', { data });
  },
  update: (id: string, data: API.Admin.ProjectGoals.Request) => {
    return patchRequest<API.Admin.ProjectGoals.Item>(`/api:admin/project_goals/${id}`, { data });
  },
  delete: (id: string) => {
    return deleteRequest(`/api:admin/project_goals/${id}`);
  },
};

export const rbac = {
  permissions: {
    get: () => {
      return getRequest<API.Admin.RBAC.Permissions.Response>('/api:admin/RBAC/permissions/user');
    },
  },
};

export const tenant = {
  create: (data: API.Admin.Tenants.Request) => {
    return postRequest<API.Admin.Tenants.Response>('/api:admin/tenant', { data });
  },
  getAll: () => {
    return getRequest<API.Admin.Tenants.Response[]>('/api:base/tenant');
  },
  getById: (tenant_uuid: string) => {
    return getRequest<API.Admin.Tenants.Response>(`/api:admin/tenant/${tenant_uuid}`);
  },
  update: (tenant_uuid: string, data: API.Admin.Tenants.Request) => {
    return patchRequest<API.Admin.Tenants.Response>(`api:admin/tenant/${tenant_uuid}`, { data });
  },
};

export const token = {
  mint: async (item_uuid: string, amount: number, seller_uuid: number, blockchains_id: number, type = 'ERC-1155') => {
    const data = {
      item_uuid,
      amount,
      seller_uuid,
      type,
      blockchains_id,
    };
    return postRequest(`/api:admin/token/mint`, { data });
  },
};

export const upload = {
  google: (formData: FormData) =>
    instance.request({
      timeout: 0,
      method: 'POST',
      url: `/api:admin/upload/google`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    }),
};

export const user = {
  getAll: (params?: API.Admin.User.Request.Params) => {
    return getRequest<API.Admin.User.Response>(`/api:admin/user`, { params });
  },
  getById: (user_id: number) => {
    return getRequest<API.Admin.User.Item>(`/api:admin/user/${user_id}`);
  },
  create: (data: API.Admin.User.Request.Data) => {
    return postRequest<API.Admin.User.Response>(`/api:admin/user`, { data });
  },
  update: (user_id: number | string, data: API.Admin.User.Request.Data) => {
    return patchRequest<API.Admin.User.Response>(`/api:admin/user/${user_id}`, { data });
  },
  referrals: {
    getBuUserId: (user_id: number) => {
      return getRequest<API.Admin.User.Referrals.Item[]>(`/api:admin/user/referals/${user_id}`);
    },
  },
};

export const user_roles = {
  getAll: () => {
    return getRequest<API.Admin.UserRoles.Response>('api:admin/user_roles');
  },
};
