import { AddIcon } from '@chakra-ui/icons';
import { Flex, Heading, Link } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link as ReachLink } from 'react-router-dom';

import DevelopersTable from '../../components/DevelopersTable';

import Card from 'components/card/Card';

import BasePreloader from 'components/preloader/BasePreloader';

import { LoadingStatus } from 'helpers/constants';
import usePermissions from 'hooks/usePermissions';
import { loadDevelopers } from 'store/developers';
import { selectDevelopers, selectLoadDevelopersStatus } from 'store/selectors';
import { useAppDispatch, useAppSelector } from 'store/store';

export default function DevelopersList() {
  const dispatch = useAppDispatch();
  const LoadDevelopersStatus = useAppSelector(selectLoadDevelopersStatus);
  const developers = useAppSelector(selectDevelopers);
  const { currentRouteUserPermissions } = usePermissions();

  useEffect(() => {
    !developers.length && dispatch(loadDevelopers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = LoadDevelopersStatus === LoadingStatus.PENDING;

  return (
    <Flex
      direction='column'
      minH='100vh'
      align='start'
      justifyContent='start'
      pt={{ sm: '125px', lg: '75px' }}
      position='relative'
    >
      {currentRouteUserPermissions.canCreate && (
        <Link as={ReachLink} to='/admin/developers/create' maxWidth='400' width='100%'>
          <Card cursor='pointer'>
            <Flex me='-16px' justifyContent='space-between' alignItems='center'>
              <Heading size='xs'>Create new developer</Heading>
              <AddIcon mr='5' />
            </Flex>
          </Card>
        </Link>
      )}
      {isLoading ? <BasePreloader /> : <DevelopersTable tableData={developers} />}
    </Flex>
  );
}
