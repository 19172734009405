import { FC } from 'react';

const errorPageStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
};

const buttonStyle = {
  backgroundColor: 'blue',
  color: 'white',
  padding: '10px 20px',
  marginTop: '10px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

type ErrorFallbackProps = {
  error: Error;
};

export const logError = (error: Error) => {
  console.log('errorBounder', error);
};

export const ErrorFallback: FC<ErrorFallbackProps> = ({ error }) => {
  return (
    <div style={errorPageStyle}>
      <div role='alert'>
        <p>Something went wrong:</p>
        <p>{error.message}</p>
        <a href='/'>
          <button type='button' style={buttonStyle}>
            Go to Main
          </button>
        </a>
      </div>
    </div>
  );
};
