import { Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Column } from '@tanstack/react-table';

import { useEffect, useState } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { TbFilter, TbFilterFilled } from 'react-icons/tb';

import { ReactTableFilterTypes } from 'helpers/constants';

export const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return <input {...props} value={value} onChange={e => setValue(e.target.value)} />;
};

type ColumnFilterMeta = {
  filterData?: { value: string | number; label: string }[];
  filterVariant?: ReactTableFilterTypes;
};

export const Filter = ({ column }: { column: Column<any, unknown> }) => {
  const columnFilterValue = column.getFilterValue();
  const filterVariant = (column.columnDef.meta as ColumnFilterMeta)?.filterVariant ?? {};
  const filterData = (column.columnDef.meta as ColumnFilterMeta)?.filterData ?? [];
  const [isOpen, setIsOpen] = useState(false);

  const toogleIsOpen = () => setIsOpen(prev => !prev);
  const closeMenu = () => setIsOpen(false);

  if (filterVariant === ReactTableFilterTypes.RANGE) {
    return (
      <div>
        <div className='flex space-x-2'>
          <DebouncedInput
            type='number'
            value={(columnFilterValue as [number, number])?.[0] ?? ''}
            onChange={value => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
            placeholder={`Min`}
            className='w-24 border shadow rounded'
          />
          <DebouncedInput
            type='number'
            value={(columnFilterValue as [number, number])?.[1] ?? ''}
            onChange={value => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
            placeholder={`Max`}
            className='w-24 border shadow rounded'
          />
        </div>
        <div className='h-1' />
      </div>
    );
  }

  if (filterVariant === ReactTableFilterTypes.SELECT) {
    const clearSelection = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      column.setFilterValue(null);
      closeMenu();
    };
    return (
      <Menu isOpen={isOpen} onClose={closeMenu}>
        <MenuButton onClick={toogleIsOpen} flexShrink={0} _hover={{ opacity: 0.7 }}>
          {columnFilterValue ? <TbFilterFilled /> : <TbFilter />}
        </MenuButton>
        <MenuList>
          {filterData.map(({ value, label }) => (
            <MenuItem
              as={Flex}
              key={value}
              cursor='pointer'
              justifyContent='space-between'
              fontWeight={columnFilterValue === value ? 'bold' : 'normal'}
              onClick={() => column.setFilterValue(value)}
              bgColor={columnFilterValue === value ? 'gray.200' : undefined}
              _hover={{ bgColor: columnFilterValue === value ? 'gray.200' : 'gray.100' }}
            >
              {label}
              {columnFilterValue === value && (
                <button onClick={clearSelection}>
                  <IoMdCloseCircle />
                </button>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  }

  if (filterVariant === ReactTableFilterTypes.TEXT) {
    return (
      <DebouncedInput
        className='w-36 border shadow rounded'
        onChange={value => column.setFilterValue(value)}
        placeholder={`Search...`}
        type='text'
        value={(columnFilterValue ?? '') as string}
      />
      // See faceted column filters example for datalist search suggestions
    );
  }
};
