/* eslint-disable */

import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import Card from 'components/card/Card';
import * as React from 'react';
// Assets
import { MdEdit, MdDelete, MdTouchApp, MdToken, MdPeopleAlt } from 'react-icons/md';
import { useHistory } from 'react-router';
import { useAppSelector } from 'store/store';
import { THistory } from 'types';
import { selectLoadTenantsStatus, selectTenants } from 'store/selectors';
import BasePreloader from 'components/preloader/BasePreloader';
import { LoadingStatus } from 'helpers/constants';
import { API } from 'api/types';
import usePermissions from 'hooks/usePermissions';
import { FaSortDown, FaSortUp } from 'react-icons/fa';

interface TableHelper extends API.Admin.Tenants.Response {}

const columnHelper = createColumnHelper<TableHelper>();

export default function TenantTable() {
  const data = useAppSelector(selectTenants);
  const loadTenantsStatus = useAppSelector(selectLoadTenantsStatus);
  const history = useHistory<THistory>();
  const { currentRouteUserPermissions } = usePermissions();
  const { canUpdate, canDelete } = currentRouteUserPermissions;

  const isActionsAvailable = canUpdate;
  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const editTenant = (uuid: string) => {
    history.push({
      pathname: `/admin/tenants/edit`,
      search: `?tenantUuid=${uuid}`,
    });
  };

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Fundraiser name
        </Text>
      ),
      cell: info => (
        <Flex alignItems='center'>
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('manager', {
      id: 'manager',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Manager
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('phone', {
      id: 'phone',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Phone
        </Text>
      ),
      cell: info => (
        <Text color={textColor} whiteSpace='nowrap' fontSize='sm' fontWeight='600'>
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Email
        </Text>
      ),
      cell: info => (
        <Text color={textColor} whiteSpace='nowrap' fontSize='sm' fontWeight='600'>
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Actions
        </Text>
      ),
      cell: info => {
        const { uuid } = info.row?.original;
        return (
          isActionsAvailable && (
            <Menu>
              <MenuButton
                as={Button}
                leftIcon={<MdTouchApp />}
                fontSize='14px'
                textTransform='uppercase'
                colorScheme='blue'
                bgColor='blue.400'
                h='28px'
                borderRadius='6px'
              >
                Actions
              </MenuButton>
              <MenuList minWidth='0px' fontSize='large' padding='10px'>
                {canUpdate && (
                  <MenuItem icon={<MdEdit />} onClick={() => editTenant(uuid)}>
                    Edit tenant
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          )
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  if (loadTenantsStatus === LoadingStatus.PENDING) {
    return <BasePreloader />;
  }

  return (
    <Card mt='20px'>
      <Flex direction='column' w='100%' overflowX={{ sm: 'auto', lg: 'auto' }}>
        <Box overflowX='auto'>
          <Table variant='simple' color='gray.500' mt='12px' minWidth='800'>
            <Thead>
              {table.getHeaderGroups().map(headerGroup => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers?.map(header => {
                    return (
                      <Th key={header.id} colSpan={header.colSpan} pe='10px' borderColor={borderColor}>
                        <Flex
                          justifyContent='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'
                          cursor='pointer'
                          onClick={header.column?.getToggleSortingHandler()}
                        >
                          {flexRender(header.column?.columnDef?.header, header.getContext())}
                          {{
                            asc: <FaSortDown />,
                            desc: <FaSortUp />,
                          }[header.column?.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          {flexRender(cell.column?.columnDef?.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </Card>
  );
}
