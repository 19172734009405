import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Select,
} from '@chakra-ui/react';

import { FC, useRef } from 'react';

import { KYCStatus, LoadingStatus } from 'helpers/constants';

type ChangeKYCStatusModalProps = {
  isOpen: boolean;
  changeStatusHandler: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  onClose: () => void;
  loadingStatus: LoadingStatus;
  currentStatus: string;
  changeTargetStatus: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const ChangeKYCStatusModal: FC<ChangeKYCStatusModalProps> = props => {
  const { isOpen, changeStatusHandler, loadingStatus, onClose, currentStatus, changeTargetStatus } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <Modal initialFocusRef={buttonRef} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg='none' backdropFilter='auto' backdropBlur='10px' />
      <ModalContent>
        <ModalHeader>Change KYC status</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Are you sure you want change KYC status?</p>
          <Select
            fontSize='sm'
            h='44px'
            maxH='44px'
            me='20px'
            my='20px'
            defaultValue={currentStatus}
            onChange={changeTargetStatus}
            placeholder='select KYC status'
          >
            {Object.values(KYCStatus).map((status, index) => (
              <option key={index}>{status}</option>
            ))}
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button ref={buttonRef} variant='outline' mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme={'green'}
            onClick={changeStatusHandler}
            isLoading={loadingStatus === LoadingStatus.PENDING}
          >
            Change status
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangeKYCStatusModal;
