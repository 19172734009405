// Chakra imports
import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

// Custom components
import { useState } from 'react';

import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';

import LeaveConfirmation from 'components/common/LeaveConfirmation';

enum bullets {
  offerInfo,
  additionalInfo,
  itemInfo,
}

export default function OfferCreation() {
  const [activeTab, setActiveTab] = useState(bullets.offerInfo);

  const setAdditionalInfoTab = () => setActiveTab(bullets.additionalInfo);
  const setItemInfoTab = () => setActiveTab(bullets.itemInfo);

  const isOfferInfoTabActive = activeTab === bullets.offerInfo;
  const isAdditionalInfoTabActive = activeTab === bullets.additionalInfo;
  const isItemInfoTabActive = activeTab === bullets.itemInfo;

  return (
    <Flex direction='column' minH='100vh' align='center' pt={{ sm: '125px', lg: '75px' }} position='relative'>
      <LeaveConfirmation />
      <Box
        h='45vh'
        bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
        position='absolute'
        w='100%'
        borderRadius='30px'
      />
      <Tabs
        variant='unstyled'
        zIndex='0'
        mt={{ base: '60px', md: '165px' }}
        display='flex'
        flexDirection='column'
        isManual={true}
        index={activeTab}
      >
        <TabList display='flex' alignItems='center' alignSelf='center' justifySelf='center'>
          <Tab w={{ sm: '120px', md: '250px', lg: '300px' }} _focus={{ boxShadow: 'none' }}>
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'
              _before={{
                content: "''",
                width: { sm: '120px', md: '250px', lg: '300px' },
                height: '3px',
                bg: isOfferInfoTabActive ? '#8476FF' : 'white',
                left: { sm: '12px', md: '60px' },
                top: {
                  sm: isOfferInfoTabActive ? '6px' : '6px',
                  md: null,
                },
                position: 'absolute',
                bottom: isOfferInfoTabActive ? '40px' : '38px',

                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex='1'
                border='2px solid'
                borderColor={'white'}
                bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
                w='16px'
                h='16px'
                mb='8px'
                borderRadius='50%'
              />
              <Text
                color={isOfferInfoTabActive ? 'white' : 'gray.300'}
                fontWeight={isOfferInfoTabActive ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Offer information
              </Text>
            </Flex>
          </Tab>
          <Tab w={{ sm: '120px', md: '250px', lg: '300px' }} _focus={{ boxShadow: 'none' }}>
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'
              _before={{
                content: "''",
                width: { sm: '120px', md: '250px', lg: '300px' },
                height: '3px',
                bg: isItemInfoTabActive ? 'white' : '#8476FF',
                left: { sm: '12px', md: '110px' },
                top: '6px',
                position: 'absolute',
                bottom: isAdditionalInfoTabActive ? '40px' : '38px',
                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex='1'
                border='2px solid'
                borderColor={isOfferInfoTabActive ? '#8476FF' : 'white'}
                bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
                w='16px'
                h='16px'
                mb='8px'
                borderRadius='50%'
              />
              <Text
                color={isAdditionalInfoTabActive ? 'white' : 'gray.300'}
                fontWeight={isAdditionalInfoTabActive ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
                whiteSpace='nowrap'
              >
                Offer additional information
              </Text>
            </Flex>
          </Tab>
          <Tab w={{ sm: '120px', md: '250px', lg: '300px' }} _focus={{ boxShadow: 'none' }}>
            <Flex direction='column' justify='center' align='center' position='relative'>
              <Box
                zIndex='1'
                border='2px solid'
                borderColor={isItemInfoTabActive ? 'white' : '#8476FF'}
                bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
                w='16px'
                h='16px'
                mb='8px'
                borderRadius='50%'
              />
              <Text
                color={isItemInfoTabActive ? 'white' : 'gray.300'}
                fontWeight={isItemInfoTabActive ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Add your first item
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels mt='24px' maxW={{ md: '90%', lg: '100%' }} mx='auto'>
          <TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
            {isOfferInfoTabActive && <Step1 nextStep={setAdditionalInfoTab} />}
          </TabPanel>
          <TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
            {isAdditionalInfoTabActive && <Step2 nextStep={setItemInfoTab} />}
          </TabPanel>
          <TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
            {isItemInfoTabActive && <Step3 />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
