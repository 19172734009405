/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  createStandaloneToast,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Grid,
  GridItem,
  Select,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';

import 'filepond/dist/filepond.min.css';

import { Field, FieldArray, FieldAttributes, Formik } from 'formik';
import { FC, useState } from 'react';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { project_goals } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';

import InputField from 'components/fields/InputField';
import { LoadingStatus } from 'helpers/constants';
import { useLeaveConfirmation } from 'hooks/useLeaveConfirmation';
import { THistory } from 'types';

type ProjectGoalFormProps = {
  name?: string;
  data?: API.Admin.ProjectGoals.ProjectGoalData[];
  id?: string;
};

const localesMock = [
  {
    id: 1,
    created_at: 1681763210691,
    code: 'en',
    title: 'English',
    alias: 'EN',
    slug: 'en',
    text_direction: 'ltr',
    default: true,
  },
  {
    id: 2,
    created_at: 1681763260787,
    code: 'ru',
    title: 'Русский',
    alias: 'RU',
    slug: 'ru',
    text_direction: 'ltr',
    default: false,
  },
];

const emptyData: API.Admin.ProjectGoals.ProjectGoalData = {
  locales_id: localesMock[0].id,
  name: '',
  description: '',
  tooltip: '',
};

const ProjectGoalForm: FC<ProjectGoalFormProps> = ({ name, data, id }) => {
  const initialValues: API.Admin.ProjectGoals.Request = {
    name: name || '',
    data: data || [emptyData],
  };

  const isEditForm = !!id;

  const [createProjectGoalStatus, setCreateProjectGoalStatus] = useState<LoadingStatus>(LoadingStatus.NONE);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { disableLeaveConfirmation } = useLeaveConfirmation();

  const history = useHistory<THistory>();

  const toast = createStandaloneToast();

  const isProjectGoalCreating = createProjectGoalStatus === LoadingStatus.PENDING;

  const submitData = async (formData: API.Admin.ProjectGoals.Request) => {
    const sendingData = { ...formData };

    try {
      setCreateProjectGoalStatus(LoadingStatus.PENDING);
      isEditForm ? await project_goals.update(id, sendingData) : await project_goals.create(sendingData);

      toast({
        title: `ProjectGoal has been successfully ${isEditForm ? 'updated' : 'created'}`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      setCreateProjectGoalStatus(LoadingStatus.FULFILLED);
      disableLeaveConfirmation();
      history.push({
        pathname: '/admin/offers/project-goals/list',
      });
    } catch (e) {
      setCreateProjectGoalStatus(LoadingStatus.REJECTED);
    }
  };

  return (
    <Card p='30px'>
      <Formik
        validateOnChange={isFormDirty}
        validateOnBlur
        initialValues={initialValues}
        validationSchema={Yup.object({
          name: Yup.string().required('Name is required'),
          data: Yup.array().of(
            Yup.object({
              locales_id: Yup.number().required('Locales id is required'),
              name: Yup.string().required('Name is required'),
            })
          ),
        })}
        onSubmit={submitData}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form
            onSubmit={e => {
              setIsFormDirty(true);
              handleSubmit(e);
            }}
          >
            <Flex direction='column' w='100%'>
              <Stack direction={{ base: 'column' }} spacing='20px'>
                <Stack direction='row' spacing='20px'>
                  <FormControl isInvalid={!!errors.name} mb='0'>
                    <InputField
                      mb='0px'
                      id='name'
                      name='name'
                      placeholder='Input goal name'
                      label='Goal name'
                      onChange={handleChange}
                      value={values.name}
                    />
                    {errors?.name ? <FormErrorMessage>{errors?.name}</FormErrorMessage> : <FormHelperText mb='25px' />}
                  </FormControl>
                </Stack>

                <FieldArray name='data'>
                  {({ push, remove }) => (
                    <Flex direction='column' justifyContent='center' scrollMarginTop='180'>
                      {values.data.map((item, index) => (
                        <Grid key={index} templateColumns='repeat(2, 1fr)' gap={2} rowGap={6} w='100%' mb='20px'>
                          <GridItem gridColumnStart={1} gridColumnEnd={3} display='flex'>
                            <Text fontSize='xl' fontWeight='bold'>
                              Info {index + 1}
                            </Text>
                            <Button variant='ghost' onClick={() => remove(index)} colorScheme='red' size='sm'>
                              <DeleteIcon />
                            </Button>
                          </GridItem>
                          <Field name={`data[${index}].locales_id`}>
                            {({ field, form }: FieldAttributes<any>) => (
                              <Flex direction='column'>
                                <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                                  Locale
                                </Text>
                                <Select
                                  fontSize='sm'
                                  id='locales_id'
                                  name={`data[${index}].locales_id`}
                                  h='44px'
                                  maxH='44px'
                                  onBlur={handleChange}
                                  defaultValue={field.value}
                                >
                                  {localesMock.map(locale => (
                                    <option key={locale.id} value={locale.id}>
                                      {locale.title}
                                    </option>
                                  ))}
                                </Select>
                              </Flex>
                            )}
                          </Field>
                          <Field name={`data[${index}].name`}>
                            {({ field, form }: FieldAttributes<any>) => (
                              <FormControl isInvalid={!!form.errors.data?.[index]?.name}>
                                <InputField
                                  mb='0px'
                                  id={`data[${index}].name`}
                                  name={`data[${index}].name`}
                                  placeholder={`Input goal info ${index + 1} name`}
                                  label={`Goal info ${index + 1} name`}
                                  onChange={field.onChange}
                                  value={field.value}
                                />
                                {form.errors.data?.[index]?.name ? (
                                  <FormErrorMessage>{form.errors.data?.[index]?.name}</FormErrorMessage>
                                ) : (
                                  <FormHelperText mb='25px' />
                                )}
                              </FormControl>
                            )}
                          </Field>
                          <Field name={`data[${index}].description`}>
                            {({ field, form }: FieldAttributes<any>) => (
                              <FormControl isInvalid={!!form.errors.data?.[index]?.description}>
                                <Textarea
                                  id={`data[${index}].description`}
                                  name={`data[${index}].description`}
                                  placeholder='Input description'
                                  onChange={field.onChange}
                                  value={field.value}
                                />
                                {form.errors.data?.[index]?.description ? (
                                  <FormErrorMessage>{form.errors.data?.[index]?.description}</FormErrorMessage>
                                ) : (
                                  <FormHelperText mb='25px' />
                                )}
                              </FormControl>
                            )}
                          </Field>
                          <Field name={`data[${index}].tooltip`}>
                            {({ field, form }: FieldAttributes<any>) => (
                              <FormControl isInvalid={!!form.errors.data?.[index]?.tooltip}>
                                <Textarea
                                  id={`data[${index}].tooltip`}
                                  name={`data[${index}].tooltip`}
                                  placeholder='Input tooltip'
                                  onChange={field.onChange}
                                  value={field.value}
                                />
                                {form.errors.data?.[index]?.tooltip ? (
                                  <FormErrorMessage>{form.errors.data?.[index]?.tooltip}</FormErrorMessage>
                                ) : (
                                  <FormHelperText mb='25px' />
                                )}
                              </FormControl>
                            )}
                          </Field>
                        </Grid>
                      ))}
                      <Button colorScheme='blue' onClick={() => push(emptyData)}>
                        Add goal info
                      </Button>
                    </Flex>
                  )}
                </FieldArray>
              </Stack>
              <Flex justify='space-between' mt='24px'>
                <Button
                  isLoading={isProjectGoalCreating}
                  variant='darkBrand'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: '128px', md: '148px' }}
                  h='46px'
                  ms='auto'
                  type='submit'
                  disabled={!!Object.values(errors).length}
                >
                  {isEditForm ? 'Save' : 'Create'}
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default ProjectGoalForm;
