import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fundraisers } from 'api/admin';
import { API } from 'api/types';
import { LoadingStatus } from 'helpers/constants';

interface CounterState {
  fundraisers: API.Admin.Fundraisers.Response[];
  status: LoadingStatus;
}

const initialState: CounterState = {
  fundraisers: [],
  status: LoadingStatus.NONE,
};

export const getFundraisers = createAsyncThunk('fundraisers/list', async () => {
  const res = await fundraisers.getAll();
  return res.data;
});

export const deleteFundraiser = createAsyncThunk('fundraisers/list', async (uuid: string) => {
  await fundraisers.delete(uuid);
  const res = await fundraisers.getAll();
  return res.data;
});

export const fundraisersSlice = createSlice({
  name: 'fundraisers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFundraisers.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(getFundraisers.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.fundraisers = action.payload;
      })
      .addCase(getFundraisers.rejected, state => {
        state.status = LoadingStatus.REJECTED;
      });
  },
});

export default fundraisersSlice.reducer;
