import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

import DeveloperForm from '../../components/forms/DeveloperForm';

import LeaveConfirmation from 'components/common/LeaveConfirmation';

export default function EditDeveloper() {
  return (
    <Flex direction='column' minH='100vh' align='center' pt={{ sm: '125px', lg: '75px' }} position='relative'>
      <LeaveConfirmation />
      <Box
        h='45vh'
        bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
        position='absolute'
        w='100%'
        borderRadius='30px'
      />
      <Tabs
        variant='unstyled'
        zIndex='0'
        mt={{ base: '60px', md: '165px' }}
        display='flex'
        flexDirection='column'
        isManual={true}
      >
        <TabList display='flex' alignItems='center' alignSelf='center' justifySelf='center'>
          <Tab w={{ sm: '120px', md: '250px', lg: '300px' }} _focus={{ boxShadow: 'none' }}>
            <Flex direction='column' justify='center' align='center' position='relative'>
              <Box
                zIndex='1'
                border='2px solid'
                borderColor={'white'}
                bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
                w='16px'
                h='16px'
                mb='8px'
                borderRadius='50%'
              />
              <Text color={'white'} fontWeight={'bold'} display={{ sm: 'none', md: 'block' }}>
                Edit developer info
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels mt='24px' maxW={{ md: '90%', lg: '100%' }} mx='auto'>
          <TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
            <Flex direction='column' minH='100vh' align='center' position='relative'>
              <DeveloperForm />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
