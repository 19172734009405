import { CheckIcon, DeleteIcon } from '@chakra-ui/icons';
import { Stack, Flex, Button, FormLabel, Select } from '@chakra-ui/react';

import { Dispatch, FC, SetStateAction, SyntheticEvent } from 'react';

import { MdArrowCircleDown, MdArrowCircleUp } from 'react-icons/md';

import { upload } from 'api/admin';
import InputField from 'components/fields/InputField';
import { AssetDocumentsStatus, LoadingStatus, OfferingDocumentsType } from 'helpers/constants';
import { swapArrElements } from 'utils';

type WithId = {
  id: string | number;
  external_id?: never;
};

type WithExternalId = {
  id?: never;
  external_id: string;
};

type DocsFieldsBase = {
  title: string;
  doc_loading_status: LoadingStatus;
  id?: string | number;
  external_id?: string;
  type: OfferingDocumentsType;
  responsefile?: any;
  personal_doc_status?: AssetDocumentsStatus;
};

export type DocsFieldsDocument = (WithId | WithExternalId) & DocsFieldsBase;

type DocsFieldsProps = {
  documents: DocsFieldsDocument[];
  setDocuments: Dispatch<SetStateAction<DocsFieldsDocument[]>>;
  financeTypes?: boolean;
  sortable?: boolean;
  assetDocStatuses?: boolean;
};

const getTargetType = (
  doc: DocsFieldsDocument,
  docIndex: number,
  targetType: OfferingDocumentsType,
  targetIndex: number
) => {
  if (docIndex === targetIndex) {
    return targetType;
  }

  if (doc.type === OfferingDocumentsType.FINANCIAL && targetType === OfferingDocumentsType.FINANCIAL) {
    return OfferingDocumentsType.REGULAR;
  }

  return doc.type;
};

const DocsFields: FC<DocsFieldsProps> = props => {
  const { documents, setDocuments, financeTypes, sortable, assetDocStatuses } = props;

  const deleteDocument = (e: SyntheticEvent<HTMLButtonElement>) => {
    const targetIndex = Number(e.currentTarget.dataset.index);
    setDocuments(prevDocuments => prevDocuments.filter((_, index) => index !== targetIndex));
  };

  const uploadDocuments = (e: SyntheticEvent<HTMLButtonElement>) => e.currentTarget?.parentElement.click();

  const changeFileName = (e: SyntheticEvent<HTMLInputElement>) => {
    const index = Number(e.currentTarget.dataset.index);
    const value = (e.currentTarget as HTMLInputElement).value;
    setDocuments(prev => prev.map((doc, i) => (i === index ? { ...doc, title: value } : doc)));
  };

  const changeFileType = (e: SyntheticEvent<HTMLSelectElement>, targetIndex: number) => {
    const targetType = e.currentTarget.value as OfferingDocumentsType;
    setDocuments(prev => prev.map((doc, i) => ({ ...doc, type: getTargetType(doc, i, targetType, targetIndex) })));
  };

  const changeAssetDocStatus = (e: SyntheticEvent<HTMLSelectElement>, targetIndex: number) => {
    const targetStatus = e.currentTarget.value as AssetDocumentsStatus;
    setDocuments(prev =>
      prev.map((doc, i) => (i === targetIndex ? { ...doc, personal_doc_status: targetStatus } : doc))
    );
  };

  const changeFile = async (e: SyntheticEvent, index: number) => {
    const file = (e.currentTarget as HTMLInputElement).files[0];

    try {
      setDocuments(prev =>
        prev.map((doc, i) => (i === index ? { ...doc, doc_loading_status: LoadingStatus.PENDING } : doc))
      );
      const formData = new FormData();
      formData.append('content', file);

      const { status, data } = await upload.google(formData);

      if (status === 200) {
        setDocuments(prev =>
          prev.map((doc, i) =>
            i === index
              ? { ...doc, responsefile: data, title: data.name, doc_loading_status: LoadingStatus.FULFILLED }
              : doc
          )
        );
        return;
      }

      setDocuments(prev =>
        prev.map((doc, i) => (i === index ? { ...doc, doc_loading_status: LoadingStatus.REJECTED } : doc))
      );
    } catch (e) {
      console.error(e);
      setDocuments(prev =>
        prev.map((doc, i) => (i === index ? { ...doc, doc_loading_status: LoadingStatus.REJECTED } : doc))
      );
    }
  };

  const moveUp = (index: number) => {
    setDocuments(prevDocuments => {
      const newDocuments = [...prevDocuments];
      swapArrElements(newDocuments, index, index + 1);
      return newDocuments;
    });
  };

  const moveDown = (index: number) => {
    setDocuments(prevDocuments => {
      const newDocuments = [...prevDocuments];
      swapArrElements(newDocuments, index, index - 1);
      return newDocuments;
    });
  };
  return (
    <Stack direction='column' spacing='20px' mb='20px'>
      {documents.map(({ title, doc_loading_status, id, external_id, type, personal_doc_status }, i: number) => (
        <Flex gap={4} key={id || external_id} alignItems='end' direction={{ md: 'row', base: 'column' }}>
          {financeTypes && (
            <Flex>
              <Flex flexDirection='column' alignSelf='baseline' gap='8px'>
                <FormLabel color={'black'} whiteSpace='nowrap' fontSize='14px' fontWeight='bold'>
                  Document type
                </FormLabel>
                <Select onChange={e => changeFileType(e, i)} value={type}>
                  {Object.values(OfferingDocumentsType).map(type => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Select>
              </Flex>
            </Flex>
          )}
          {assetDocStatuses && (
            <Flex>
              <Flex flexDirection='column' alignSelf='baseline' gap='8px'>
                <FormLabel color={'black'} whiteSpace='nowrap' fontSize='14px' fontWeight='bold'>
                  Asset documents status
                </FormLabel>
                <Select onChange={e => changeAssetDocStatus(e, i)} value={personal_doc_status}>
                  {Object.values(AssetDocumentsStatus).map(type => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Select>
              </Flex>
            </Flex>
          )}

          <InputField
            data-index={i}
            containerWidth='100%'
            mb='0px'
            placeholder={
              doc_loading_status === LoadingStatus.FULFILLED ? 'Input document name' : 'Please, upload document'
            }
            label={`Name of document ${i + 1}`}
            value={title}
            disabled={doc_loading_status !== LoadingStatus.FULFILLED}
            onChange={changeFileName}
          />
          <Flex align='end' h='100%' gap='10px' w={{ md: 'auto', base: '100%' }}>
            <Flex width={{ md: '220px', base: '100%' }}>
              <label style={{ width: '100%' }}>
                <input type='file' style={{ display: 'none' }} onChange={e => changeFile(e, i)} />
                <Button
                  w='100%'
                  height='44px'
                  borderRadius='5px'
                  rightIcon={doc_loading_status === LoadingStatus.FULFILLED ? <CheckIcon /> : null}
                  isLoading={doc_loading_status === LoadingStatus.PENDING}
                  variant={doc_loading_status === LoadingStatus.FULFILLED ? 'outline' : 'brand'}
                  onClick={uploadDocuments}
                >
                  {doc_loading_status === LoadingStatus.FULFILLED ? 'Document is loaded' : 'Upload documents'}
                </Button>
              </label>
            </Flex>
            {sortable && (
              <>
                <Button
                  p='0'
                  borderRadius='10px'
                  variant='ghost'
                  disabled={i === 0}
                  colorScheme={'blue'}
                  onClick={() => moveDown(i)}
                >
                  <MdArrowCircleUp size='26px' />
                </Button>
                <Button
                  p='0'
                  borderRadius='10px'
                  disabled={i === documents.length - 1}
                  variant='ghost'
                  colorScheme={'blue'}
                  onClick={() => moveUp(i)}
                >
                  <MdArrowCircleDown size='26px' />
                </Button>
              </>
            )}
            <Button
              data-index={i}
              height='44px'
              borderRadius='5px'
              disabled={doc_loading_status === LoadingStatus.PENDING}
              colorScheme='red'
              variant='outline'
              onClick={deleteDocument}
            >
              <DeleteIcon />
            </Button>
          </Flex>
        </Flex>
      ))}
    </Stack>
  );
};

export default DocsFields;
