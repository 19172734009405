import Card from 'components/card/Card';
import FundraiserForm from 'views/admin/fundraisers/components/forms/FundraiserForm';

function Step1() {
  return (
    <Card p='30px'>
      <FundraiserForm />
    </Card>
  );
}

export default Step1;
