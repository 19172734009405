/* eslint-disable react-hooks/exhaustive-deps */
import { AddIcon } from '@chakra-ui/icons';
import { Flex, Heading, Link } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link as ReachLink } from 'react-router-dom';

import GiftCardTable from '../components/tables/GiftCardTable';

import Card from 'components/card/Card';
import usePermissions from 'hooks/usePermissions';
import { loadGiftcards } from 'store/giftcards';
import { useAppDispatch } from 'store/store';

const GiftCardsList = () => {
  const dispatch = useAppDispatch();

  const { currentRouteUserPermissions } = usePermissions();

  useEffect(() => {
    dispatch(loadGiftcards());
  }, []);

  return (
    <Flex
      direction='column'
      minH='100vh'
      align='start'
      justifyContent='start'
      pt={{ sm: '125px', lg: '75px' }}
      position='relative'
    >
      {currentRouteUserPermissions.canCreate && (
        <Link as={ReachLink} to='/admin/giftcards/create' maxWidth='400' width='100%'>
          <Card cursor='pointer'>
            <Flex me='-16px' justifyContent='space-between' alignItems='center'>
              <Heading size='xs'>Create new giftcards</Heading>
              <AddIcon mr='5' />
            </Flex>
          </Card>
        </Link>
      )}
      <GiftCardTable />
    </Flex>
  );
};

export default GiftCardsList;
