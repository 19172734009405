import { falsyValues } from '../helpers/constants';

import { API } from 'api/types';

export const getBlockchainNameById = (id: number, blockchainArr: API.Admin.Blockchains.Response[]) => {
  return blockchainArr?.find(blockchain => blockchain.id === id)?.name || 'unknown blockchain';
};

export const convertToBoolean = (value: any): boolean => {
  if (typeof value === 'string') {
    value = value.toLowerCase();
  }
  return !falsyValues.includes(value);
};

export const prettyId = (id: string | number) => {
  if (!id) return;

  const idStr = id.toString();

  return idStr.substring(0, 4) + '****' + idStr.substring(idStr.length - 6);
};

export const roundToDecimals = (value: number, count = 2) => {
  const multiplier = 10 ** count;

  return Math.round(value * multiplier) / multiplier;
};

export const convertDecimalToPercent = (value: number) => value * 100;
export const convertPercentToDecimal = (value: number) => value / 100;
