/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  createStandaloneToast,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';

import 'filepond/dist/filepond.min.css';

import { Formik } from 'formik';
import { useState } from 'react';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { giftcards } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';

import InputField from 'components/fields/InputField';
import { LoadingStatus } from 'helpers/constants';
import { useLeaveConfirmation } from 'hooks/useLeaveConfirmation';
import { THistory } from 'types';
import { convertToBoolean } from 'utils/converters';

const initialValues: API.Admin.Giftcards.BulkData = {
  series: '',
  amount: 1000,
  quantity: 1,
  active: true,
};

export default function GiftCardForm() {
  const [createGiftcardsStatus, setCreateGiftcardsStatus] = useState<LoadingStatus>(LoadingStatus.NONE);

  const { disableLeaveConfirmation } = useLeaveConfirmation();

  const history = useHistory<THistory>();

  const toast = createStandaloneToast();

  const isGiftcardsCreating = createGiftcardsStatus === LoadingStatus.PENDING;

  const submitData = async (formData: API.Admin.Giftcards.BulkData) => {
    const sendingData = { ...formData };
    sendingData.active = convertToBoolean(formData.active);

    try {
      setCreateGiftcardsStatus(LoadingStatus.PENDING);
      await giftcards.createBulk(sendingData);

      toast({
        title: `Giftcards has been successfully created`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      setCreateGiftcardsStatus(LoadingStatus.FULFILLED);
      disableLeaveConfirmation();
      history.push({
        pathname: 'list',
      });
    } catch (e) {
      setCreateGiftcardsStatus(LoadingStatus.REJECTED);
    }
  };

  return (
    <Card p='30px'>
      <Formik
        validateOnChange
        validateOnBlur
        initialValues={initialValues}
        validationSchema={Yup.object({
          series: Yup.string().min(4, 'minimum 4 symbols').max(20, 'maximum 20 symbols').required('field required'),
          amount: Yup.number()
            .moreThan(0, 'Number must be greater than zero')
            .lessThan(100000, 'Number must be less than 100К')
            .required('Field is required'),
          quantity: Yup.number()
            .moreThan(0, 'Number must be greater than zero')
            .lessThan(100, 'Number must be less than 100')
            .required('Field is required'),
        })}
        onSubmit={submitData}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Flex direction='column' w='100%'>
              <Stack direction={{ base: 'column', lg: 'row' }} spacing='20px'>
                <FormControl isInvalid={!!errors.series} mb='0'>
                  <InputField
                    mb='0px'
                    id='series'
                    name='series'
                    placeholder='Input giftcard series'
                    label='Series'
                    onChange={handleChange}
                    value={values.series}
                  />
                  {errors?.series ? (
                    <FormErrorMessage>{errors?.series}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='25px' />
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.amount} mb='0'>
                  <InputField
                    mb='0px'
                    id='amount'
                    name='amount'
                    placeholder='Input amount'
                    label='Amount'
                    onChange={handleChange}
                    value={values.amount}
                    type='number'
                  />
                  {errors?.amount ? (
                    <FormErrorMessage>{errors?.amount}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='25px' />
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.quantity} mb='0'>
                  <InputField
                    mb='0px'
                    id='quantity'
                    name='quantity'
                    placeholder='Input giftcard quantity'
                    label='Quantity'
                    onChange={handleChange}
                    value={values.quantity}
                  />
                  {errors?.quantity ? (
                    <FormErrorMessage>{errors?.quantity}</FormErrorMessage>
                  ) : (
                    <FormHelperText mb='25px' />
                  )}
                </FormControl>
                <FormControl>
                  <Text fontSize='sm' mb='8px' ml='10px' fontWeight='700'>
                    Status
                  </Text>
                  <Select
                    fontSize='sm'
                    id='active'
                    name='active'
                    h='44px'
                    maxH='44px'
                    me='20px'
                    onChange={handleChange}
                    defaultValue={values.active ? 'Active' : 'Inactive'}
                  >
                    <option>Active</option>
                    <option>Inactive</option>
                  </Select>
                </FormControl>
              </Stack>
              <Flex justify='space-between' mt='24px'>
                <Button
                  isLoading={isGiftcardsCreating}
                  variant='darkBrand'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: '128px', md: '148px' }}
                  h='46px'
                  ms='auto'
                  type='submit'
                  disabled={!!Object.values(errors).length}
                >
                  Generate
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </Card>
  );
}
