/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@chakra-ui/react';
import { useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import AssetsTable from '../components/tables/AssetsTable';

import { loadAssets } from 'store/assets';
import { useAppDispatch } from 'store/store';

const AssetsList = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const queryParam = new URLSearchParams(location.search);

  const itemUuid = queryParam.get('itemUuid');

  const loadItems = () => dispatch(loadAssets(itemUuid));
  const clearFilters = () => {
    history.replace({
      search: '',
    });
    dispatch(loadAssets(null));
  };

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <Flex
      direction='column'
      minH='100vh'
      align='start'
      justifyContent='start'
      pt={{ sm: '125px', lg: '75px' }}
      position='relative'
    >
      <AssetsTable itemUuid={itemUuid} clearFilters={clearFilters} />
    </Flex>
  );
};

export default AssetsList;
