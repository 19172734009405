/* eslint-disable react-hooks/exhaustive-deps */
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Route, Redirect, useHistory, Prompt } from 'react-router-dom';

import BasePreloader from 'components/preloader/BasePreloader';

import { defaultRoute, RouteLayouts } from 'helpers/constants';
import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import { loadBlockchains } from 'store/blockhains';
import { loadCryptoAssets } from 'store/cryptoAssets';
import { loadPaymentTypes } from 'store/paymentTypes';
import { selectIsAuthUser, selectIsLeaveConfirmationAcitve, selectLeaveConfirmationTargetPath } from 'store/selectors';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getUser } from 'store/user';
import { THistory } from 'types';

import PageNotFound from 'views/static/PageNotFound';

export default function App() {
  const isAuth = useAppSelector(selectIsAuthUser);
  const history = useHistory<THistory>();
  const dispatch = useAppDispatch();
  const isLeaveConfirmationActive = useAppSelector(selectIsLeaveConfirmationAcitve);
  const leaveConfirmationTargetPath = useAppSelector(selectLeaveConfirmationTargetPath);
  const hasToken = !!Cookies.get('authToken');

  const [isAppInitialized, setIsAppInitialized] = useState(isAuth);

  const initApp = async () => {
    try {
      await Promise.all([
        dispatch(loadBlockchains()),
        dispatch(loadCryptoAssets()),
        dispatch(loadPaymentTypes()),
        !isAuth && hasToken && dispatch(getUser()),
      ]);
    } finally {
      setIsAppInitialized(true);
    }
  };

  useEffect(() => {
    const currentLocation = history.location.pathname;
    if (currentLocation === '/' || currentLocation === RouteLayouts.ADMIN) {
      history.push(defaultRoute);
    }

    initApp();
  }, []);

  if (!isAppInitialized) {
    return <BasePreloader />;
  }

  return (
    <>
      <Prompt
        message={(location, action) => {
          if (isLeaveConfirmationActive && !location.pathname.startsWith(leaveConfirmationTargetPath)) {
            return `Are you sure you want to leave this page? All unsaved data will be lost.`;
          }
        }}
      />

      <Route path={RouteLayouts.AUTH} component={AuthLayout} />
      <Route
        path={RouteLayouts.ADMIN}
        render={props => {
          return isAuth ? <AdminLayout /> : <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />;
        }}
      />
      <Route path='/404' component={PageNotFound} />
    </>
  );
}
