import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Cookies from 'js-cookie';

import { rbac } from 'api/admin';
import { auth } from 'api/auth';
import { API } from 'api/types';
import { LoadingStatus } from 'helpers/constants';

interface CounterState {
  info: API.Auth.Me.Response;
  status: LoadingStatus;
  permissions: API.Admin.RBAC.Permissions.Response;
  isAuth: boolean;
}

const initialState: CounterState = {
  info: null,
  permissions: null,
  status: LoadingStatus.NONE,
  isAuth: false,
};

export const getUser = createAsyncThunk('user/me', async () => {
  const [user, permissions] = await Promise.all([auth.getUser(), rbac.permissions.get()]);

  const res = {
    user: user.data,
    permissions: permissions.data,
  };

  return res;
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: state => {
      Cookies.remove('authToken');
      state.info = null;
      state.permissions = null;
      state.isAuth = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUser.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.info = action.payload.user;
        state.permissions = action.payload.permissions;
        state.isAuth = true;
      })
      .addCase(getUser.rejected, state => {
        state.status = LoadingStatus.REJECTED;
        state.info = null;
        state.permissions = null;
      });
  },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
