import { Flex } from '@chakra-ui/react';

import BasePreloader from './BasePreloader';

const OverlayPreloader = () => {
  return (
    <Flex position='absolute' w='100%' h='100%' zIndex={1} justifyContent='center' alignItems='center'>
      <Flex w='100%' h='100%' background='white' opacity={0.8} position='absolute' />
      <BasePreloader />
    </Flex>
  );
};

export default OverlayPreloader;
