/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  createStandaloneToast,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import 'filepond/dist/filepond.min.css';

import { Formik } from 'formik';
import { FC, useState } from 'react';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { BsQuestionCircle } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { order } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';

import InputField from 'components/fields/InputField';
import { LoadingStatus } from 'helpers/constants';
import { useLeaveConfirmation } from 'hooks/useLeaveConfirmation';
import { THistory } from 'types';
import { roundToDecimals } from 'utils/converters';

type AgentOrderFormProps = {
  agentOffer: API.Admin.AgentOffers.Response;
};

const AgentOrderForm: FC<AgentOrderFormProps> = ({ agentOffer }) => {
  const { item, offering, comission } = agentOffer;
  const { uuid, total, funded, min_investment, platfrom_expenses } = item;
  const initialValues: API.Admin.Order.Create.Request = {
    item_uuid: uuid,
    quantity: undefined,
    customer_email: '',
  };

  const [createAgentOrderStatus, setCreateAgentOrderStatus] = useState<LoadingStatus>(LoadingStatus.NONE);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const investmentLimit = total - funded;
  const totalWithoutPlatformExpenses = total - platfrom_expenses;

  const fullAgentComission = totalWithoutPlatformExpenses * (comission / 100);

  const { disableLeaveConfirmation } = useLeaveConfirmation();

  const history = useHistory<THistory>();

  const toast = createStandaloneToast();

  const isAgentOrderCreating = createAgentOrderStatus === LoadingStatus.PENDING;

  const submitData = async (formData: API.Admin.Order.Create.Request) => {
    try {
      setCreateAgentOrderStatus(LoadingStatus.PENDING);
      await order.create(formData);

      toast({
        title: `AgentOrder has been successfully created`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      setCreateAgentOrderStatus(LoadingStatus.FULFILLED);
      disableLeaveConfirmation();
      history.push({
        pathname: '/admin/agent-offers/list',
      });
    } catch (e) {
      setCreateAgentOrderStatus(LoadingStatus.REJECTED);
    }
  };

  return (
    <Card p='30px'>
      <Text mb='10' fontSize='2xl'>
        Offer name: <strong>{offering.name}</strong>
      </Text>
      <Formik
        validateOnChange={isFormDirty}
        validateOnBlur
        initialValues={initialValues}
        validationSchema={Yup.object({
          quantity: Yup.number().moreThan(0, 'Number must be greater than zero').required('Field is required'),
          customer_email: Yup.string().required('Field is required'),
        })}
        onSubmit={submitData}
      >
        {({ values, errors, handleChange, handleSubmit }) => {
          const agentCommision = fullAgentComission * (values.quantity / total);
          return (
            <form
              onSubmit={e => {
                setIsFormDirty(true);
                handleSubmit(e);
              }}
            >
              <Flex direction='column' w='100%'>
                <Stack direction={{ base: 'column' }} spacing='20px'>
                  <Stack direction='row' spacing='20px'>
                    <FormControl isInvalid={!!errors.quantity} mb='0'>
                      <InputField
                        mb='0px'
                        id='quantity'
                        name='quantity'
                        placeholder={`Input amount, min ${min_investment}, max ${investmentLimit}`}
                        label={`Amount, $`}
                        onChange={handleChange}
                        value={values.quantity}
                        type='number'
                        step={1}
                        min={min_investment}
                        max={investmentLimit}
                      />
                      {errors?.quantity ? (
                        <FormErrorMessage>{errors?.quantity}</FormErrorMessage>
                      ) : (
                        <FormHelperText mb='25px' />
                      )}
                    </FormControl>
                    <FormControl isInvalid={!!errors.customer_email} mb='0'>
                      <InputField
                        mb='0px'
                        id='customer_email'
                        name='customer_email'
                        placeholder='Input customer email'
                        min
                        label={
                          <Flex>
                            <span>Customer email</span>
                            <Tooltip label='You can create an order for registred and unregistred users'>
                              <Text ml='0.5rem'>
                                <BsQuestionCircle />
                              </Text>
                            </Tooltip>
                          </Flex>
                        }
                        onChange={handleChange}
                        value={values.customer_email}
                      />
                      {errors?.customer_email ? (
                        <FormErrorMessage>{errors?.customer_email}</FormErrorMessage>
                      ) : (
                        <FormHelperText mb='25px' />
                      )}
                    </FormControl>
                  </Stack>
                  {!!agentCommision && (
                    <InputField
                      mb='0px'
                      disabled
                      min
                      w='fit-content'
                      style={{ opacity: '1', cursor: 'default', border: 'none' }}
                      label={
                        <Flex>
                          <span>Agent comission</span>
                          <Tooltip label='The commission is calculated based on the cost of the object without taking into account costs and markups'>
                            <Text ml='0.5rem'>
                              <BsQuestionCircle />
                            </Text>
                          </Tooltip>
                        </Flex>
                      }
                      value={roundToDecimals(agentCommision)}
                    />
                  )}
                </Stack>
                <Flex justify='space-between' mt='24px'>
                  <Button
                    isLoading={isAgentOrderCreating}
                    variant='darkBrand'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: '128px', md: '148px' }}
                    h='46px'
                    ms='auto'
                    type='submit'
                    disabled={!!Object.values(errors).length}
                  >
                    Create
                  </Button>
                </Flex>
              </Flex>
            </form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default AgentOrderForm;
