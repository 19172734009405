/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@chakra-ui/react';
import { useEffect } from 'react';

import AgentOffersTable from '../components/tables/AgentOffersTable';

import { loadAgentOffers } from 'store/agentOffers';
import { useAppDispatch } from 'store/store';

const AgentOffersList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadAgentOffers());
  }, []);

  return (
    <Flex
      direction='column'
      minH='100vh'
      align='start'
      justifyContent='start'
      pt={{ sm: '125px', lg: '75px' }}
      position='relative'
    >
      <AgentOffersTable />
    </Flex>
  );
};

export default AgentOffersList;
