/* eslint-disable */

import {
  Avatar,
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import { MdEdit, MdDelete, MdTouchApp } from 'react-icons/md';
import { useHistory } from 'react-router';
import { useAppDispatch } from 'store/store';
import { THistory } from 'types';
import { format } from 'date-fns';
import { printAddress } from 'utils/geocoder';
import { API } from 'api/types';
import { FC, MouseEvent, useState } from 'react';
import { LoadingStatus } from 'helpers/constants';
import { developers } from 'api/admin';
import { loadDevelopers } from 'store/developers';
import usePermissions from 'hooks/usePermissions';
import DeleteConfirmation from 'components/dialogs/DeleteConfirmation';
import { FaSortDown, FaSortUp } from 'react-icons/fa';

const columnHelper = createColumnHelper<API.Admin.Developer.Response>();

type DeveloperTableProps = {
  tableData: API.Admin.Developer.Response[];
};
const DevelopersTable: FC<DeveloperTableProps> = ({ tableData }) => {
  const history = useHistory<THistory>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const { currentRouteUserPermissions } = usePermissions();
  const { canDelete, canUpdate } = currentRouteUserPermissions;
  const isActionsAvailable = canDelete || canUpdate;
  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const [activeId, setActiveId] = useState(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [deletingStatus, setDeletingStatus] = useState(LoadingStatus.NONE);

  const deleteCurrentDeveloper = async () => {
    try {
      setDeletingStatus(LoadingStatus.PENDING);
      await developers.delete(activeId);
      setDeletingStatus(LoadingStatus.FULFILLED);
    } catch (e) {
      setDeletingStatus(LoadingStatus.REJECTED);
      throw e;
    }
    dispatch(loadDevelopers());
  };

  const openDeleteModal = (uuid: string) => {
    setActiveId(uuid);
    onOpen();
  };

  const editDeveloper = (uuid: string) => {
    history.push({
      pathname: `/admin/developers/edit`,
      search: `?developerUuid=${uuid}`,
    });
  };

  const closeModal = () => {
    setActiveId(null);
    onClose();
  };

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Developer name
        </Text>
      ),
      cell: info => (
        <Flex alignItems='center'>
          <Avatar size='lg' name='Offer image' mr='14px' src={info.row?.original?.logo?.url} />
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('location', {
      id: 'location',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Location
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {printAddress(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor('created_at', {
      id: 'developer',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Created
        </Text>
      ),
      cell: info => (
        <Text color={textColor} whiteSpace='nowrap' fontSize='sm' fontWeight='600'>
          {format(new Date(info.getValue()), 'yyyy-MM-dd')}
        </Text>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Actions
        </Text>
      ),
      cell: info => {
        const { uuid } = info.row?.original;
        return (
          isActionsAvailable && (
            <Menu>
              <MenuButton
                as={Button}
                leftIcon={<MdTouchApp />}
                fontSize='14px'
                textTransform='uppercase'
                colorScheme='blue'
                bgColor='blue.400'
                h='28px'
                borderRadius='6px'
              >
                Actions
              </MenuButton>
              <MenuList minWidth='0px' fontSize='large' padding='10px'>
                {canUpdate && (
                  <MenuItem icon={<MdEdit />} onClick={() => editDeveloper(uuid)}>
                    Edit developer
                  </MenuItem>
                )}
                {canDelete && (
                  <MenuItem icon={<MdDelete />} onClick={() => openDeleteModal(uuid)}>
                    Delete developer
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          )
        );
      },
    }),
  ];
  const [data] = useState(() => [...tableData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card mt='20px'>
      <Flex direction='column' w='100%' overflowX={{ sm: 'auto', lg: 'auto' }}>
        <Box overflowX='auto'>
          <Table variant='simple' color='gray.500' mt='12px' minWidth='800'>
            <Thead>
              {table.getHeaderGroups().map(headerGroup => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers?.map(header => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe='10px'
                        borderColor={borderColor}
                        cursor='pointer'
                        onClick={header.column?.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'
                        >
                          {flexRender(header.column?.columnDef?.header, header.getContext())}
                          {{
                            asc: <FaSortDown />,
                            desc: <FaSortUp />,
                          }[header.column?.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          {flexRender(cell.column?.columnDef?.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Flex>
      <DeleteConfirmation
        isOpen={isOpen}
        onClose={closeModal}
        confirmationHandler={deleteCurrentDeveloper}
        text={"Are you sure you want to delete developer? It's an irreversible process"}
        loadingStatus={deletingStatus}
      />
    </Card>
  );
};

export default DevelopersTable;
