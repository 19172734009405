import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { crypto_assets } from 'api/admin';
import { API } from 'api/types';
import { LoadingStatus } from 'helpers/constants';

interface CounterState {
  cryptoAssets: API.Admin.CryptoAssets.Response[];
  status: LoadingStatus;
}

const initialState: CounterState = {
  cryptoAssets: [],
  status: LoadingStatus.NONE,
};

export const loadCryptoAssets = createAsyncThunk('cryptoAssets/list', async () => {
  const res = await crypto_assets.getAll();
  return res.data;
});

export const cryptoAssetsSlice = createSlice({
  name: 'blockchains',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadCryptoAssets.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(loadCryptoAssets.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.cryptoAssets = action.payload;
      })
      .addCase(loadCryptoAssets.rejected, state => {
        state.status = LoadingStatus.REJECTED;
        state.cryptoAssets = [];
      });
  },
});

export default cryptoAssetsSlice.reducer;
