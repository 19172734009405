import { FormControl, FormHelperText, FormErrorMessage } from '@chakra-ui/react';

import { ChangeEvent } from 'react';

import InputField from 'components/fields/InputField';

type FormFieldProps = {
  errors: any;
  index: number;
  handleChange: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  name: string;
  placeholder: string;
  label: string;
  arrayName: string;
  value?: string;
  defaultValue?: string;
  type?: string;
};

function FormField({
  errors,
  index,
  handleChange,
  name,
  placeholder,
  label,
  arrayName,
  value,
  defaultValue,
  type = 'string',
}: FormFieldProps) {
  return (
    <FormControl isInvalid={errors[arrayName] && errors?.[arrayName][index] && errors?.[arrayName][index][name]} mb='0'>
      <InputField
        mb='0px'
        placeholder={placeholder}
        name={`${arrayName}.${index}.${name}`}
        label={label}
        onBlur={handleChange}
        value={value}
        defaultValue={defaultValue}
        type={type}
      />
      {errors[arrayName] && errors[arrayName][index] && errors?.[arrayName][index][name] ? (
        <FormErrorMessage>{errors[arrayName] && errors?.[arrayName][index][name]}</FormErrorMessage>
      ) : (
        <FormHelperText mb='26px'></FormHelperText>
      )}
    </FormControl>
  );
}

export default FormField;
