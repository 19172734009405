import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Menu,
  MenuButton,
  Badge,
  Avatar,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { useState } from 'react';
import { FaCartPlus, FaSortDown, FaSortUp } from 'react-icons/fa';
import { MdDelete, MdOutlineVisibility, MdTouchApp } from 'react-icons/md';

import { useHistory } from 'react-router-dom';

import { agent_offers } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';
import DeleteConfirmation from 'components/dialogs/DeleteConfirmation';

import BasePreloader from 'components/preloader/BasePreloader';

import { LoadingStatus, offeringStatusesInfo, PermissionEndpoints, PermissionLevels } from 'helpers/constants';
import usePermissions from 'hooks/usePermissions';
import { loadAgentOffers } from 'store/agentOffers';
import { selectAgentOffers, selectLoadAgentOffersStatus } from 'store/selectors';
import { useAppDispatch, useAppSelector } from 'store/store';

import { getImageUrl } from 'views/admin/offers/components/tables/OffersTable';

type AgentOffers = API.Admin.AgentOffers.Response;

interface AgentOffersColumnHelper extends AgentOffers {
  actions?: () => void;
}

const columnHelper = createColumnHelper<AgentOffersColumnHelper>();

const AgentOffersTable = () => {
  const agentOffersList = useAppSelector(selectAgentOffers);
  const loadAgentOffersStatus = useAppSelector(selectLoadAgentOffersStatus);
  const { currentRouteUserPermissions, checkUserPermission } = usePermissions();
  const { canDelete } = currentRouteUserPermissions;
  const canCreateOrder = checkUserPermission(PermissionEndpoints.ORDERS, PermissionLevels.CREATE);
  const toast = useToast();
  const dispatch = useAppDispatch();

  const isActionsAvailable = canDelete || canCreateOrder;

  const isAgentOffersPending = loadAgentOffersStatus === LoadingStatus.PENDING;
  const isAgentOffersLoaded = loadAgentOffersStatus === LoadingStatus.FULFILLED;

  const history = useHistory();

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const [activeOfferId, setActiveOfferUuid] = useState<number | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteOfferStatus, setDeleteOfferStatus] = useState(LoadingStatus.NONE);

  const openDeleteDialog = (offerId: number) => {
    setActiveOfferUuid(offerId);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setActiveOfferUuid(null);
    setIsDeleteDialogOpen(false);
  };

  const deleteDialogText = `Do you realy want to delete agent offer ${activeOfferId}? It's an irreversible process`;
  const deleteOrder = async () => {
    try {
      setDeleteOfferStatus(LoadingStatus.PENDING);
      await agent_offers.delete(activeOfferId);
      toast({
        title: `Agent offer ${activeOfferId} successfully deleted`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      closeDeleteDialog();
      dispatch(loadAgentOffers());
      setDeleteOfferStatus(LoadingStatus.FULFILLED);
    } catch (e) {
      setDeleteOfferStatus(LoadingStatus.REJECTED);
    }
  };

  const createOrder = (agentOffer: API.Admin.AgentOffers.Response) => {
    const agentOfferId = agentOffer.id;

    history.push({
      pathname: `/admin/agent-offers/create/order`,
      search: `?agentOfferId=${agentOfferId}`,
    });
  };

  const viewOffer = (uuid: string) => {
    const appDomain = process.env.REACT_APP_APP_URL || 'https://squarefi.co';
    window.open(`${appDomain}/investment/${uuid}`, '_blank');
  };

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          ID
        </Text>
      ),
      cell: info => (
        <Flex alignItems='center'>
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('offering.name', {
      id: 'offer.name',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Offer name
        </Text>
      ),
      cell: info => (
        <Flex alignItems='center'>
          <Avatar size='lg' name='Offer image' mr='14px' src={getImageUrl(info.row.original.offering?.images)} />
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('offering.address', {
      id: 'offer.address',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Address
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {info.getValue() ? info.getValue().country : 'No address'}
        </Text>
      ),
    }),
    columnHelper.accessor('comission', {
      id: 'comission',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Comission
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {info.getValue()}%
        </Text>
      ),
    }),

    columnHelper.accessor('offering.status', {
      id: 'offer.status',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Status
        </Text>
      ),
      cell: info => (
        <Badge
          colorScheme={offeringStatusesInfo[info.getValue()]?.theme}
          color={offeringStatusesInfo[info.getValue()]?.fontColor}
          fontSize={offeringStatusesInfo[info.getValue()]?.fontSize}
          fontWeight={offeringStatusesInfo[info.getValue()]?.fontWeight}
          width={'140px'}
          textAlign='center'
        >
          {offeringStatusesInfo[info.getValue()]?.name}
        </Badge>
      ),
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Actions
        </Text>
      ),
      cell: info => {
        const agentOffer = info.row.original;
        return (
          isActionsAvailable && (
            <Menu>
              <MenuButton
                as={Button}
                leftIcon={<MdTouchApp />}
                fontSize='14px'
                textTransform='uppercase'
                colorScheme='blue'
                bgColor='blue.400'
                h='28px'
                borderRadius='6px'
              >
                Actions
              </MenuButton>
              <MenuList minWidth='0px' fontSize='large' padding='10px'>
                <MenuItem icon={<MdOutlineVisibility />} onClick={() => viewOffer(agentOffer.offering.uuid)}>
                  View offer
                </MenuItem>
                {canCreateOrder && (
                  <MenuItem icon={<FaCartPlus />} onClick={() => createOrder(agentOffer)}>
                    Create order
                  </MenuItem>
                )}
                {canDelete && (
                  <MenuItem icon={<MdDelete />} onClick={() => openDeleteDialog(agentOffer.id)}>
                    Delete
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          )
        );
      },
    }),
  ];

  const data = agentOffersList;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const rows = table.getRowModel().rows;
  const headerGroups = table.getHeaderGroups();

  if (isAgentOffersPending) {
    return <BasePreloader />;
  }

  if (!agentOffersList.length && isAgentOffersLoaded) {
    return (
      <Text textTransform='uppercase' fontSize='4xl' margin='auto'>
        No active agent offers
      </Text>
    );
  }

  return (
    <Card mt='20px'>
      <Flex direction='column' w='100%' overflowX={{ sm: 'auto', lg: 'auto' }}>
        <Box overflowX='auto'>
          <Table variant='simple' color='gray.500' mt='12px' minWidth='800'>
            <Thead>
              {headerGroups.map(headerGroup => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers?.map(header => {
                    return (
                      <Th key={header.id} colSpan={header.colSpan} pe='10px' borderColor={borderColor}>
                        <Flex
                          justifyContent='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'
                          cursor='pointer'
                          onClick={header.column?.getToggleSortingHandler()}
                        >
                          {flexRender(header.column?.columnDef?.header, header.getContext())}
                          {{
                            asc: <FaSortDown />,
                            desc: <FaSortUp />,
                          }[header.column?.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {rows.map(row => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          {flexRender(cell.column?.columnDef?.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Flex>
      <DeleteConfirmation
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        confirmationHandler={deleteOrder}
        text={deleteDialogText}
        loadingStatus={deleteOfferStatus}
      />
    </Card>
  );
};

export default AgentOffersTable;
