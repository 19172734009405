/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Grid,
  GridItem,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { Formik } from 'formik';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { emptyAddress } from '../modals/ChangeOfferAddressModal';

import { developers, fundraisers, offerings } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { LoadingStatus } from 'helpers/constants';
import useDownloadData from 'hooks/useDownloadData';
import { THistory } from 'types';
import { GeocodeResult, geocodeAddress } from 'utils/geocoder';
type InfoFormProps = {
  nextStep?: () => void;
};

const initialValues: API.Admin.Offerings.Request = {
  type: '',
  name: '',
  seller_id: null,
  developer_id: null,
  address: emptyAddress,
};

export default function InfoForm({ nextStep }: InfoFormProps) {
  const [createOfferStatus, setCreateOfferStatus] = useState<LoadingStatus>(LoadingStatus.NONE);

  const [address, setAddress] = useState('');
  const [geocoderData, setGeocoderData] = useState<GeocodeResult | null>(null);
  const [addressStatus, setAddressStatus] = useState({ isLoad: false, status: 'none' });

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const history = useHistory<THistory>();

  const [developersList, developersStatus, getDevelopers] = useDownloadData<API.Admin.Developer.Response[]>(
    developers.getAll
  );
  const [fundraisersList, fundraisersStatus, getFundraisers] = useDownloadData<API.Admin.Fundraisers.Response[]>(
    fundraisers.getAll
  );

  const checkAddress = () => {
    if (!address) {
      setAddressStatus({ status: 'none', isLoad: false });
      setGeocoderData(emptyAddress);
      return;
    }
    setAddressStatus({ status: 'none', isLoad: true });

    geocodeAddress(address)
      .then(result => {
        if (result.country) {
          setGeocoderData(result);
          return setAddressStatus({ status: 'valid', isLoad: false });
        }
        setAddressStatus({ status: 'invalid', isLoad: false });
      })
      .catch(error => {
        console.error(error);
        setAddressStatus({ status: 'invalid', isLoad: false });
      });
  };

  const createOffer = async (info: API.Admin.Offerings.Request) => {
    let offerData = {
      ...info,
      tenant_id: 1, //пока просто текстовое поле с предзапллненной цифрой 1
    };

    if (addressStatus.status === 'valid') {
      offerData.geo = geocoderData.geo;
      offerData.address = {
        country: geocoderData.country,
        city: geocoderData.city || '',
        house: geocoderData.house || '',
        code: geocoderData.code || '',
        state: geocoderData.state || '',
        street: geocoderData.street || '',
        block: geocoderData.block || '',
      };
    }

    setCreateOfferStatus(LoadingStatus.PENDING);

    offerings
      .create(offerData)
      .then(({ data }) => {
        setCreateOfferStatus(LoadingStatus.FULFILLED);
        history.push({
          pathname: '',
          search: `?offerId=${data.uuid}`,
        });
        nextStep();
      })
      .catch(e => {
        console.error(e);
        setCreateOfferStatus(LoadingStatus.REJECTED);
      });
  };

  useEffect(() => {
    getDevelopers();
    getFundraisers();
  }, []);

  const isLoaded = developersStatus === LoadingStatus.FULFILLED && fundraisersStatus === LoadingStatus.FULFILLED;

  if (!isLoaded) {
    return (
      <Flex w='100%' h='500px' justifyContent='center' alignItems='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brand.500' size='xl' />
      </Flex>
    );
  }

  return (
    <Card p='30px'>
      <Formik
        validateOnChange
        validateOnBlur
        initialValues={initialValues}
        validationSchema={Yup.object({
          name: Yup.string().max(30, 'maximum 30 symbols'),
          seller: Yup.string().max(50, 'maximum 30 symbols'),
          developer: Yup.string().max(50, 'maximum 30 symbols'),
        })}
        onSubmit={createOffer}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Text color={textColor} fontSize='2xl' fontWeight='700' mb='0'>
              Offer information
            </Text>
            <Flex direction='column' w='100%'>
              <Stack direction='column' spacing='10px'>
                <FormControl isInvalid={!!errors.name} mb='0'>
                  <InputField
                    mb='0px'
                    id='name'
                    name='name'
                    placeholder='Internal system name of the offer'
                    label='Offer Name'
                    onChange={handleChange}
                    value={values.name}
                  />
                  {!errors?.name ? (
                    <FormHelperText mb='25px'></FormHelperText>
                  ) : (
                    <FormErrorMessage>{errors?.name}</FormErrorMessage>
                  )}
                </FormControl>
                <SimpleGrid columns={{ base: 1, md: 2 }} gap='20px'>
                  <Flex flexDirection='column' mb='20px'>
                    <Text fontSize='sm' px='10px' fontWeight='700' mb='1'>
                      Seller
                    </Text>
                    <FormControl isInvalid={!!errors.seller_id} mb='0'>
                      <Select
                        disabled={fundraisersStatus !== LoadingStatus.FULFILLED}
                        fontSize='sm'
                        id='seller_id'
                        name='seller_id'
                        h='44px'
                        maxH='44px'
                        me='20px'
                        onChange={handleChange}
                        placeholder='select seller'
                      >
                        {fundraisersList.map(({ id, name }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                  <Flex flexDirection='column' mb='0'>
                    <Text fontSize='sm' px='10px' fontWeight='700' mb='1'>
                      Developer
                    </Text>
                    <FormControl isInvalid={!!errors.developer_id} mb='0'>
                      <Select
                        disabled={developersStatus !== LoadingStatus.FULFILLED}
                        fontSize='sm'
                        id='developer_id'
                        name='developer_id'
                        h='44px'
                        maxH='44px'
                        me='20px'
                        onChange={handleChange}
                        placeholder='select developer'
                      >
                        {developersList.map(({ id, name }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                </SimpleGrid>
                <Grid templateRows='1fr' templateColumns='repeat(3, 1fr)' gap={4}>
                  <GridItem colSpan={2}>
                    <FormControl isInvalid={addressStatus.status === 'invalid'} mb='0'>
                      <InputField
                        mb='0'
                        id='address'
                        name='address'
                        placeholder='If object doesn’t have an address, leave it empty.'
                        label='Offer address'
                        onChange={(e: SyntheticEvent) => setAddress((e.target as HTMLInputElement).value)}
                        value={address}
                        onBlur={checkAddress}
                      />
                      {addressStatus.status === 'none' && <FormHelperText mb='27px'></FormHelperText>}
                      {addressStatus.status === 'valid' && (
                        <FormHelperText color='green'>The address is valid</FormHelperText>
                      )}
                      {addressStatus.status === 'invalid' && (
                        <FormErrorMessage>The address is not valid</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Flex align='start' h='100%'>
                      <Button
                        mt='30px'
                        width='100%'
                        variant='brand'
                        size='md'
                        onClick={checkAddress}
                        isLoading={addressStatus.isLoad}
                      >
                        Check address
                      </Button>
                    </Flex>
                  </GridItem>
                </Grid>
              </Stack>
              <Flex justify='space-between' mt='24px'>
                <Button
                  isLoading={createOfferStatus === LoadingStatus.PENDING || addressStatus.isLoad}
                  variant='darkBrand'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: '128px', md: '148px' }}
                  h='46px'
                  ms='auto'
                  type='submit'
                  disabled={!values.name || !values.developer_id || !values.seller_id}
                >
                  Next Step
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </Card>
  );
}
