import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Select,
} from '@chakra-ui/react';

import { FC, useRef } from 'react';

import { LoadingStatus, ReferralStatus } from 'helpers/constants';

type ChangeReferralStatusModalProps = {
  isOpen: boolean;
  changeStatusHandler: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  onClose: () => void;
  loadingStatus: LoadingStatus;
  currentStatus: number;
  changeTargetStatus: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const ChangeReferralStatusModal: FC<ChangeReferralStatusModalProps> = props => {
  const { isOpen, changeStatusHandler, loadingStatus, onClose, currentStatus, changeTargetStatus } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const referralStatuses = Object.entries(ReferralStatus).filter(([_, value]) => typeof value === 'number');

  return (
    <Modal initialFocusRef={buttonRef} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg='none' backdropFilter='auto' backdropBlur='10px' />
      <ModalContent>
        <ModalHeader>Change Referral status</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Are you sure you want change referral status?</p>
          <Select
            fontSize='sm'
            h='44px'
            maxH='44px'
            me='20px'
            my='20px'
            defaultValue={currentStatus}
            onChange={changeTargetStatus}
            placeholder='select referral status'
          >
            {referralStatuses.map(([status, value]) => (
              <option key={value} value={value}>
                {status}
              </option>
            ))}
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button ref={buttonRef} variant='outline' mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme={'green'}
            onClick={changeStatusHandler}
            isLoading={loadingStatus === LoadingStatus.PENDING}
          >
            Change status
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangeReferralStatusModal;
