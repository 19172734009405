import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { compliance, investor_categories, user_roles } from 'api/admin';
import { API } from 'api/types';
import { LoadingStatus } from 'helpers/constants';

interface CounterState {
  leaveConfirmationModal: {
    targetPath: string;
    isActive: boolean;
  };
  investorCategories: {
    items: API.Admin.InvestorCategories.Response[];
    status: LoadingStatus;
  };
  userRoles: {
    items: API.Admin.UserRoles.Item[];
    status: LoadingStatus;
  };
  compliance: {
    items: API.Admin.Compliance.Response[];
    status: LoadingStatus;
  };
}

const initialState: CounterState = {
  leaveConfirmationModal: {
    targetPath: '',
    isActive: false,
  },
  investorCategories: {
    items: [],
    status: LoadingStatus.NONE,
  },
  userRoles: {
    items: [],
    status: LoadingStatus.NONE,
  },
  compliance: {
    items: [],
    status: LoadingStatus.NONE,
  },
};

export const loadInvestorCategories = createAsyncThunk('common/investorCategories', async () => {
  const { data } = await investor_categories.getAll();
  return data;
});

export const loadUserRoles = createAsyncThunk('common/userRoles', async () => {
  const { data } = await user_roles.getAll();
  return data;
});

export const loadCompliance = createAsyncThunk('common/compliance', async () => {
  const { data } = await compliance.getAll();
  return data;
});

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLeaveConfirmationPath(state, { payload }) {
      state.leaveConfirmationModal.targetPath = payload;
    },
    setLeaveConfirmationActive(state, { payload }) {
      state.leaveConfirmationModal.isActive = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadInvestorCategories.pending, ({ investorCategories }) => {
        investorCategories.status = LoadingStatus.PENDING;
      })
      .addCase(loadInvestorCategories.fulfilled, ({ investorCategories }, action) => {
        investorCategories.status = LoadingStatus.FULFILLED;
        investorCategories.items = action.payload;
      })
      .addCase(loadInvestorCategories.rejected, ({ investorCategories }) => {
        investorCategories.status = LoadingStatus.REJECTED;
      })
      .addCase(loadCompliance.pending, ({ compliance }) => {
        compliance.status = LoadingStatus.PENDING;
      })
      .addCase(loadCompliance.fulfilled, ({ compliance }, action) => {
        compliance.status = LoadingStatus.FULFILLED;
        compliance.items = action.payload;
      })
      .addCase(loadCompliance.rejected, ({ compliance }) => {
        compliance.status = LoadingStatus.REJECTED;
      })
      .addCase(loadUserRoles.pending, ({ userRoles }) => {
        userRoles.status = LoadingStatus.PENDING;
      })
      .addCase(loadUserRoles.fulfilled, ({ userRoles }, action) => {
        userRoles.status = LoadingStatus.FULFILLED;
        userRoles.items = action.payload;
      })
      .addCase(loadUserRoles.rejected, ({ userRoles }) => {
        userRoles.status = LoadingStatus.REJECTED;
      });
  },
});

export const { setLeaveConfirmationActive, setLeaveConfirmationPath } = commonSlice.actions;

export default commonSlice.reducer;
