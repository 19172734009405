import { Box, Button, Flex, Image } from '@chakra-ui/react';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { FC, useEffect, useState } from 'react';
import { FilePond } from 'react-filepond';
import { registerPlugin } from 'react-filepond';
import { MdOutlineOndemandVideo, MdOutlineInsertDriveFile } from 'react-icons/md';
import { RiImageEditFill } from 'react-icons/ri';

import { MediaFileTypes } from 'helpers/constants';
import usePhotoUpload, { UploadPhotos } from 'hooks/usePhotoUpload';
import { Attachment, AttachmentWithMediaType } from 'types';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { getFileType } from 'utils';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

export type SetExternalPhotos = (photos: Attachment[]) => void;

const viewModeSizes = {
  sm: '50px',
  md: '100px',
  lg: '150px',
};

type CustomFilePondUploaderProps = {
  setExternalPhotos?: SetExternalPhotos;
  photoFromServer: UploadPhotos; // have to fix
  acceptedFileTypes: string[];
  allowMultiple?: boolean;
  viewModeSize?: keyof typeof viewModeSizes;
};

const CustomFilePondUploader: FC<CustomFilePondUploaderProps> = props => {
  const { photoFromServer, setExternalPhotos, acceptedFileTypes, allowMultiple } = props;
  const { deletePhoto, serverUploadPhotoConfig, preloadPhotos, setFiles, files } = usePhotoUpload(setExternalPhotos);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (photoFromServer) {
        if (Array.isArray(photoFromServer) && !photoFromServer[0]?.url) {
          return;
        }

        if (!Array.isArray(photoFromServer) && !photoFromServer?.url) {
          return;
        }

        preloadPhotos(photoFromServer);
      }
    });

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilePond
      allowMultiple={allowMultiple}
      files={files}
      onupdatefiles={setFiles}
      maxFiles={10}
      server={serverUploadPhotoConfig}
      acceptedFileTypes={acceptedFileTypes}
      onremovefile={deletePhoto}
    />
  );
};

const CustomPhotoUploader: FC<CustomFilePondUploaderProps> = props => {
  const { viewModeSize = 'sm', photoFromServer } = props;
  const mediaArr = Array.isArray(photoFromServer) ? photoFromServer : [photoFromServer];
  const nonEmptyMedia = mediaArr.filter(media => media?.url);

  const [isViewMode, setIsViewMode] = useState(!!nonEmptyMedia.length);

  if (isViewMode) {
    const mediaWithType: AttachmentWithMediaType[] = nonEmptyMedia.map(media => ({
      src: media.url,
      type: getFileType(media?.url),
    }));

    const boxSize = viewModeSizes[viewModeSize];

    return (
      <Flex gap='10px' flexWrap='wrap' width='fit-content'>
        {mediaWithType.map(({ type, src }, index) => (
          <>
            {type === MediaFileTypes.IMAGE && (
              <Image
                bgColor='gray.200'
                rounded='md'
                boxSize={boxSize}
                objectFit='contain'
                key={index}
                src={src}
                alt='media'
              />
            )}
            {type === MediaFileTypes.VIDEO && (
              <Box boxSize={boxSize} bgColor='gray.200' key={index} rounded='md'>
                <MdOutlineOndemandVideo fontSize={boxSize} />
              </Box>
            )}
            {type === MediaFileTypes.OTHER && (
              <Flex boxSize={boxSize} bgColor='gray.200' key={index}>
                <MdOutlineInsertDriveFile fontSize={boxSize} />
              </Flex>
            )}
          </>
        ))}

        <Flex boxSize={boxSize} justifyContent='center' alignItems='center'>
          <Button
            variant='outline'
            color='#422AFB'
            fontSize='40px'
            w='full'
            h='full'
            alignSelf='center'
            colorScheme='facebook'
            justifySelf='center'
            rounded='md'
            p='8px'
            flexShrink={0}
            onClick={() => setIsViewMode(false)}
          >
            <RiImageEditFill />
          </Button>
        </Flex>
      </Flex>
    );
  }

  return <CustomFilePondUploader {...props} />;
};

export default CustomPhotoUploader;
