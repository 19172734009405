import { AddIcon } from '@chakra-ui/icons';
import { Flex, Heading, Link } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link as ReachLink } from 'react-router-dom';

import Card from 'components/card/Card';

import BasePreloader from 'components/preloader/BasePreloader';

import { LoadingStatus } from 'helpers/constants';
import usePermissions from 'hooks/usePermissions';
import { getOffers } from 'store/offers';
import { selectLoadOffersStatus, selectOffers } from 'store/selectors';
import { useAppDispatch, useAppSelector } from 'store/store';
import OffersTable from 'views/admin/offers/components/tables/OffersTable';

export default function Offers() {
  const dispatch = useAppDispatch();
  const offers = useAppSelector(selectOffers);
  const status = useAppSelector(selectLoadOffersStatus);
  const { currentRouteUserPermissions } = usePermissions();
  const { canCreate } = currentRouteUserPermissions;

  useEffect(() => {
    dispatch(getOffers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoaded = status === LoadingStatus.FULFILLED;

  return (
    <Flex
      direction='column'
      minH='100vh'
      align='start'
      justifyContent='start'
      pt={{ sm: '125px', lg: '75px' }}
      position='relative'
    >
      {canCreate && (
        <Link as={ReachLink} to='/admin/offers/create' maxWidth='400' width='100%'>
          <Card cursor='pointer'>
            <Flex me='-16px' justifyContent='space-between' alignItems='center'>
              <Heading size='xs'>Create new offer</Heading>
              <AddIcon mr='5' />
            </Flex>
          </Card>
        </Link>
      )}
      {isLoaded ? <OffersTable tableData={offers} /> : <BasePreloader />}
    </Flex>
  );
}
