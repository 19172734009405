/* eslint-disable react-hooks/exhaustive-deps */
import { Button, createStandaloneToast, Flex, Spinner, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { FormikProps } from 'formik';

import { uniqueId } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { useHistory, useLocation } from 'react-router';

import { acceptedImgFileTypes, acceptedVideoFileTypes } from '../../../components/config';
import AdditionalInfoForm from '../../../components/forms/AdditionalInfoForm';

import { offerings, offerings_info } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';
import CustomPhotoUploader from 'components/common/CustomPhotoUploader';
import { LoadingStatus, OfferingDocumentsType, OfferingNavMenuItems } from 'helpers/constants';
import useDownloadData from 'hooks/useDownloadData';
import { useLeaveConfirmation } from 'hooks/useLeaveConfirmation';

import { Langs } from 'types';
import { convertDateToTimeStamp } from 'utils';
import { DocsFieldsDocument } from 'views/admin/offers/components/DocsFields';

const toast = createStandaloneToast();

export const initialValues: API.Admin.OfferingsAdditionalInfo.Request = {
  name: '',
  description: '',
  features: [],
  highlights: [],
  info: [],
  badges: [],
  documents: [],
  tags: [],
  popup: [],
  article_data: [],
  perks: [],
  milestones: [],
};

const initialValueDocuments: DocsFieldsDocument[] = [
  {
    id: uniqueId(),
    title: '',
    type: OfferingDocumentsType.REGULAR,
    responsefile: {},
    doc_loading_status: LoadingStatus.NONE,
  },
];

type Step2Props = {
  nextStep?: () => void;
  isEditForm?: boolean;
};

function Step2({ nextStep, isEditForm = false }: Step2Props) {
  const [lang, setLang] = useState<Langs>('English');
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.NONE);
  const [ruDocuments, setRuDocuments] = useState(initialValueDocuments);
  const [enDocuments, setEnDocuments] = useState(initialValueDocuments);
  const [sortedPhotos, setSortedPhotos] = useState([]);

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { disableLeaveConfirmation } = useLeaveConfirmation();

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const offerId = params.get('offerId');

  const [ruServerData, ruServerDataStatus, getRuServerData] =
    useDownloadData<API.Admin.OfferingsAdditionalInfo.Response>(offerings_info.get);
  const [enServerData, enServerDataStatus, getEnServerData] =
    useDownloadData<API.Admin.OfferingsAdditionalInfo.Response>(offerings_info.get);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [offering, _, getOffering] = useDownloadData<API.Admin.Offerings.Response>(offerings.getById);

  const isUpdateInfo = !!(isEditForm && offering?.offering_info?.length);

  const ruFormRef = useRef<FormikProps<API.Admin.OfferingsAdditionalInfo.Request>>();
  const enFormRef = useRef<FormikProps<API.Admin.OfferingsAdditionalInfo.Request>>();

  const isLoading = ruServerDataStatus === LoadingStatus.PENDING || enServerDataStatus === LoadingStatus.PENDING;

  const setEngLang = () => setLang('English');
  const setRusLang = () => setLang('Russian');

  const sendAdditionalData = async () => {
    const compileDocuments = (documentsArr: DocsFieldsDocument[]) => {
      return documentsArr.map(document => ({
        url: document.responsefile ? document.responsefile.url : null,
        label: document.title,
        type: document.type,
      }));
    };

    setStatus(LoadingStatus.PENDING);

    try {
      // const sortedPhotos = getSortedPhotos();

      const resRu = () => {
        const documents = compileDocuments(ruDocuments);
        const data = {
          ...ruFormRef.current?.values,
          milestones: ruFormRef.current?.values?.milestones.map(milestone => ({
            ...milestone,
            date: convertDateToTimeStamp(milestone.date),
          })),
          lang: 'ru',
          documents,
        };
        return isUpdateInfo ? offerings_info.update(offerId, 'ru', data) : offerings_info.create(offerId, data);
      };

      const resEn = () => {
        const documents = compileDocuments(enDocuments);
        const data = {
          ...enFormRef.current?.values,
          milestones: enFormRef.current?.values?.milestones.map(milestone => ({
            ...milestone,
            date: convertDateToTimeStamp(milestone.date),
          })),
          lang: 'en',
          documents,
        };
        return isUpdateInfo ? offerings_info.update(offerId, 'en', data) : offerings_info.create(offerId, data);
      };

      const addPhotoToOffer = () => offerings.uploadImages(offerId, sortedPhotos);

      Promise.all([resRu(), resEn(), addPhotoToOffer()])
        .then(() => {
          setStatus(LoadingStatus.FULFILLED);
          if (isEditForm) {
            toast({
              title: `Data has been successfully updated`,
              position: 'top',
              status: 'success',
              isClosable: true,
            });
            disableLeaveConfirmation();
            history.push({ pathname: '/admin/offers/list' });
          } else {
            nextStep();
          }
        })
        .catch(() => setStatus(LoadingStatus.REJECTED));
    } catch (e) {
      console.error(e);
      setStatus(LoadingStatus.REJECTED);
    }
  };

  useEffect(() => {
    if (isEditForm) {
      getRuServerData(offerId, 'ru');
      getEnServerData(offerId, 'en');
      getOffering(offerId);
    }
  }, []);

  useEffect(() => {
    offering?.images && setSortedPhotos(offering.images);
  }, [offering]);

  if (isLoading) {
    return (
      <Flex w='100%' h='500px' justifyContent='center' alignItems='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brand.500' size='xl' />
      </Flex>
    );
  }

  return (
    <Card p='30px'>
      <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
        Offer additional information
        <Button onClick={setEngLang} variant={lang === 'English' && 'outline'} size='sm' ml='20px'>
          English
        </Button>
        <Button onClick={setRusLang} variant={lang === 'Russian' && 'outline'} size='sm' ml='10px'>
          Russian
        </Button>
      </Text>
      <Flex display={lang === 'English' ? 'block' : 'none'}>
        <AdditionalInfoForm
          data={enServerData || initialValues}
          formRef={enFormRef}
          documents={enDocuments}
          setDocuments={setEnDocuments}
        />
      </Flex>
      <Flex display={lang === 'Russian' ? 'block' : 'none'}>
        <AdditionalInfoForm
          data={ruServerData || initialValues}
          formRef={ruFormRef}
          documents={ruDocuments}
          setDocuments={setRuDocuments}
        />
      </Flex>
      <Text color={textColor} fontSize='2xl' fontWeight='700' mt='40px'>
        Photos
      </Text>
      <Flex direction='column' id={OfferingNavMenuItems.PHOTOS.value} scrollMarginTop={180}>
        <Text px='10px' color={textColor} fontSize='sm' fontWeight='700' mb='30px'>
          Offer images or videos (minimum 3)
        </Text>
        <CustomPhotoUploader
          photoFromServer={offering?.images}
          acceptedFileTypes={[...acceptedImgFileTypes, ...acceptedVideoFileTypes]}
          allowMultiple
          setExternalPhotos={photos => setSortedPhotos(photos)}
        />
      </Flex>

      <Flex align='end' h='100%' mt='100px'>
        <Stack direction='row' spacing='20px' mb='20px'>
          <Button onClick={setEngLang} variant={lang === 'English' && 'outline'} size='sm' ml='20px'>
            English
          </Button>
          <Button onClick={setRusLang} variant={lang === 'Russian' && 'outline'} size='sm' ml='10px'>
            Russian
          </Button>
        </Stack>
        <Button
          isLoading={status === LoadingStatus.PENDING}
          variant='darkBrand'
          fontSize='sm'
          borderRadius='16px'
          w={{ base: '128px', md: '148px' }}
          h='46px'
          ms='auto'
          type='submit'
          onClick={sendAdditionalData}
        >
          {isEditForm ? 'Save changes' : 'Next Step'}
        </Button>
      </Flex>
    </Card>
  );
}

export default Step2;
