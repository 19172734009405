import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { payment_types } from 'api/admin';
import { API } from 'api/types';
import { LoadingStatus } from 'helpers/constants';

interface CounterState {
  items: API.Admin.PaymentTypes.PaymentType[];
  status: LoadingStatus;
}

const initialState: CounterState = {
  items: [],
  status: LoadingStatus.NONE,
};

export const loadPaymentTypes = createAsyncThunk('paymentTypes/list', async () => {
  const res = await payment_types.getAll();
  return res.data;
});

export const paymentTypesSlice = createSlice({
  name: 'paymentTypes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadPaymentTypes.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(loadPaymentTypes.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.items = action.payload;
      })
      .addCase(loadPaymentTypes.rejected, state => {
        state.status = LoadingStatus.REJECTED;
        state.items = [];
      });
  },
});

export default paymentTypesSlice.reducer;
