import { createAsyncThunk, createSlice, Slice } from '@reduxjs/toolkit';

import { assets } from 'api/admin';
import { API } from 'api/types';
import { LoadingStatus } from 'helpers/constants';

interface AssetState {
  items: API.Admin.Assets.Asset[];
  status: LoadingStatus;
}

const initialState: AssetState = {
  items: [],
  status: LoadingStatus.NONE,
};

export const loadAssets = createAsyncThunk('assets/list', async (item_uuid: string) => {
  const { data } = await assets.getAll(item_uuid);

  return data;
});

export const ordersSlice: Slice<AssetState> = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadAssets.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(loadAssets.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.items = action.payload;
      })
      .addCase(loadAssets.rejected, state => {
        state.status = LoadingStatus.REJECTED;
        state.items = [];
      });
  },
});

export default ordersSlice.reducer;
