import { Flex, Image } from '@chakra-ui/react';

import squarefiWhiteLogo from 'assets/svg/sqrf_white_svg.svg';
import { SidebarContext } from 'contexts/SidebarContext';

export default function AuthNavbar(props: { sidebarWidth?: any }) {
  const { sidebarWidth } = props;

  let navbarBg = 'none';
  let navbarShadow = 'initial';
  let navbarPosition = 'absolute' as 'absolute';

  return (
    <SidebarContext.Provider value={{ sidebarWidth }}>
      <Flex
        position={navbarPosition}
        top='16px'
        left='50%'
        transform='translate(-50%, 0px)'
        background={navbarBg}
        boxShadow={navbarShadow}
        borderRadius='15px'
        px='16px'
        py='22px'
        mx='auto'
        width='1044px'
        maxW='90%'
        alignItems='center'
        zIndex='3'
      >
        <Flex w='100%' justifyContent={{ sm: 'start', lg: 'space-between' }}>
          <Image src={squarefiWhiteLogo} height='52px' width='175px' />
        </Flex>
      </Flex>
    </SidebarContext.Provider>
  );
}
