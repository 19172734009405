import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { ChangeEvent, FC, useRef, useState } from 'react';

import * as Yup from 'yup';

import { API } from 'api/types';
import CustomPhotoUploader from 'components/common/CustomPhotoUploader';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { LoadingStatus, Locales } from 'helpers/constants';
import { convertDateToTimeStamp, convertTimeStampToDayDate } from 'utils';

type ItemUpdateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  itemUpdate?: API.Admin.OfferingItems.Updates.Response;
  clickHandler: (value: API.Admin.OfferingItems.Updates.Request) => void;
  loadingStatus?: LoadingStatus;
};

type HandleChangeType = {
  (e: ChangeEvent<any>): void;
  <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void;
};

const initialValues: API.Admin.OfferingItems.Updates.Request = {
  lang: Locales.EN,
  date: convertDateToTimeStamp(new Date().toISOString()),
  title: '',
  details: '',
  private: false,
  images: [],
};

const ItemUpdateModal: FC<ItemUpdateModalProps> = props => {
  const initialRef = useRef(null);
  const { isOpen, onClose, itemUpdate, clickHandler, loadingStatus } = props;
  const [isFormDirty, setIsFormDirty] = useState(false);

  const submitHandler = async (values: API.Admin.OfferingItems.Updates.Request) => {
    setIsFormDirty(true);
    clickHandler(values);
  };

  const handleDateCheck = (handleChange: HandleChangeType) => (e: ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value);
    handleChange({
      target: { name: 'date', value: convertDateToTimeStamp(date) },
    });
  };

  return (
    <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg='none' backdropFilter='auto' backdropBlur='10px' />
      <ModalContent>
        <ModalHeader>Item update</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            validateOnChange={isFormDirty}
            validateOnBlur={isFormDirty}
            initialValues={itemUpdate || initialValues}
            onSubmit={submitHandler}
            validationSchema={Yup.object({
              title: Yup.string().required('Title is required'),
              details: Yup.string().required('Details is required'),
              date: Yup.number().required('Date is required'),
            })}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid templateColumns='repeat(2, 1fr)' gap='2'>
                  <GridItem colSpan={2}>
                    <FormControl isInvalid={!!errors.title}>
                      <InputField
                        mb='0'
                        type='text'
                        name='title'
                        placeholder='Title'
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.title && <FormErrorMessage>{errors.title}</FormErrorMessage>}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl isInvalid={!!errors.details}>
                      <TextField
                        mb='0'
                        type='text'
                        name='details'
                        placeholder='Details'
                        value={values.details}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.details && <FormErrorMessage>{errors.details}</FormErrorMessage>}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl isInvalid={!!errors.date}>
                      <InputField
                        mb='0'
                        type='date'
                        name='date'
                        value={convertTimeStampToDayDate(values.date)}
                        onChange={handleDateCheck(handleChange)}
                      />
                      {errors.date && <FormErrorMessage>{errors.date}</FormErrorMessage>}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2} mt={2}>
                    <Select name='lang' value={values.lang} onChange={handleChange} onBlur={handleBlur}>
                      <option value={Locales.EN}>EN</option>
                      <option value={Locales.RU}>RU</option>
                    </Select>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Checkbox name='private' isChecked={values.private} onChange={handleChange} onBlur={handleBlur}>
                      Private
                    </Checkbox>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <CustomPhotoUploader
                      acceptedFileTypes={['image/jpeg', 'image/png']}
                      photoFromServer={values.images}
                      setExternalPhotos={photos => (values.images = photos)}
                      allowMultiple
                    />
                  </GridItem>
                  <GridItem colSpan={2} mt='8'>
                    <Flex justifyContent='end'>
                      <Button ref={initialRef} variant='outline' mr={3} onClick={onClose}>
                        Close
                      </Button>
                      <Button colorScheme='green' type='submit' isLoading={loadingStatus === LoadingStatus.PENDING}>
                        Save
                      </Button>
                    </Flex>
                  </GridItem>
                </Grid>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ItemUpdateModal;
