/* eslint-disable react-hooks/exhaustive-deps */
import { AddIcon } from '@chakra-ui/icons';
import { Avatar, Flex, Heading, Link, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link as ReactLink, useLocation } from 'react-router-dom';

import ItemsTable from '../../components/tables/ItemsTable';

import { offerings, offering_items } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';
import { LoadingStatus } from 'helpers/constants';

import useDownloadData from 'hooks/useDownloadData';

export default function OfferItems() {
  const [tableData, setTableData] = useState<API.Admin.OfferingItems.Response[]>([]);
  const [itemsLoadingStatus, setItemsLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NONE);
  const location = useLocation<Location>();
  const params = new URLSearchParams(location.search);
  const offerId = params.get('offerId');

  const [offering, offeringStatus, getOffering] = useDownloadData<API.Admin.Offerings.Response>(offerings.getById);

  const getItems = (offerId: string) => {
    setItemsLoadingStatus(LoadingStatus.PENDING);

    offering_items
      .getAll(offerId)
      .then(({ data }) => {
        setTableData(data);
        setItemsLoadingStatus(LoadingStatus.FULFILLED);
      })
      .catch(e => {
        console.error(e);
        setItemsLoadingStatus(LoadingStatus.REJECTED);
      });
  };

  useEffect(() => {
    if (offerId) {
      getOffering(offerId);
      getItems(offerId);
    }
  }, []);

  const isLoading = itemsLoadingStatus === LoadingStatus.PENDING || offeringStatus === LoadingStatus.PENDING;

  if (isLoading) {
    return (
      <Flex w='100%' h='500px' justifyContent='center' alignItems='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brand.500' size='xl' />
      </Flex>
    );
  }

  return (
    <Flex
      direction='column'
      minH='100vh'
      align='start'
      justifyContent='start'
      pt={{ sm: '125px', lg: '75px' }}
      position='relative'
    >
      <Flex alignItems='center'>
        <Avatar size='lg' name='Offer image' src={offering?.images[0]?.url || ''} />
        <Flex ml='20px' alignItems='center' w='350px'>
          {offering?.name}
        </Flex>
        {!tableData.length && (
          <Link as={ReactLink} to={`/admin/offers/item/create?offerId=${offerId}`} maxWidth='600' width='100%'>
            <Card cursor='pointer'>
              <Flex me='-16px' justifyContent='space-between' alignItems='center'>
                <Heading size='xs'>Create details</Heading>
                <AddIcon mr='5' />
              </Flex>
            </Card>
          </Link>
        )}
      </Flex>
      {tableData.length ? (
        <ItemsTable tableData={tableData} />
      ) : (
        <Card mt='20px' p='50px' fontSize='xl' textAlign='center'>
          This offering has no details
        </Card>
      )}
    </Flex>
  );
}
