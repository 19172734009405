import { AxiosResponse } from 'axios';

import { useState } from 'react';

import { LoadingStatus } from 'helpers/constants';

type AxiosFunc<T> = (...arg: any) => Promise<AxiosResponse<T>>;
type GetDownloadData = (...arg: any) => Promise<void>;
type UseDownloadData = <T>(axiosFunc: AxiosFunc<T>) => [T, LoadingStatus, GetDownloadData];

const useDownloadData: UseDownloadData = axiosFunc => {
  const [downloadData, setDownloadData] = useState(null);
  const [downloadDataStatus, setDownloadDataStatus] = useState<LoadingStatus>(LoadingStatus.NONE);

  const getDownloadData: GetDownloadData = async (...args) => {
    setDownloadDataStatus(LoadingStatus.PENDING);
    try {
      const { data } = await axiosFunc(...args);
      setDownloadData(data);
      setTimeout(() => {
        setDownloadDataStatus(LoadingStatus.FULFILLED);
      }, 1000);
    } catch (e) {
      console.error(e);
      setDownloadDataStatus(LoadingStatus.REJECTED);
    }
  };
  return [downloadData, downloadDataStatus, getDownloadData];
};

export default useDownloadData;
