import Card from 'components/card/Card';
import TenantForm from 'views/admin/tenants/components/forms/TenantForm';

function Step1() {
  return (
    <Card p='30px'>
      <TenantForm />
    </Card>
  );
}

export default Step1;
