import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

// Assets
import { omit } from 'lodash';
import { useState } from 'react';
import { BsGeoAltFill } from 'react-icons/bs';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import {
  MdEdit,
  MdDelete,
  MdOutlineVisibility,
  MdTouchApp,
  MdRebaseEdit,
  MdToken,
  MdVisibility,
  MdVisibilityOff,
  MdOutlineRealEstateAgent,
  MdLocalOffer,
  MdOutlineContentCopy,
} from 'react-icons/md';
import { RiDraftLine } from 'react-icons/ri';
import { useHistory } from 'react-router';

import ChangeOfferAddressModal from '../modals/ChangeOfferAddressModal';
import ChangeOfferStatusModal from '../modals/ChangeOfferStatusModal';

import { offerings, offering_items } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';
import DeleteConfirmation from 'components/dialogs/DeleteConfirmation';
import {
  offeringStatusesInfo,
  OfferingStatus,
  offeringVisibilityInfo,
  OfferingVisibility,
  PermissionEndpoints,
  PermissionLevels,
} from 'helpers/constants';
import useCustomClipboard from 'hooks/useCustomClipboard';
import useDownloadData from 'hooks/useDownloadData';
import usePermissions from 'hooks/usePermissions';
import { changeOfferStatus, deleteOffer, getOffers } from 'store/offers';
import { useAppDispatch } from 'store/store';
import { THistory } from 'types';
import { GeocodeResult, printAddress } from 'utils/geocoder';

const columnHelper = createColumnHelper<API.Admin.Offerings.Response>();

export const getImageUrl = (images: { url: string }[]) => {
  if (images?.length > 0 && images[0]) {
    return images[0].url;
  }
  return '';
};

type OffersTableProps = {
  tableData: API.Admin.Offerings.Response[];
};

const OffersTable: React.FC<OffersTableProps> = props => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isChangeAddressModalOpen, setIsChangeAddressModalOpen] = useState(false);
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
  const [targetAddress, setTargetAddress] = useState<GeocodeResult | null>(null);

  const { tableData } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const history = useHistory<THistory>();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { currentRouteUserPermissions, checkUserPermission } = usePermissions();
  const canCreateAgentOffer = checkUserPermission(PermissionEndpoints.AGENT_OFFERS, PermissionLevels.CREATE);
  const canViewOrders = checkUserPermission(PermissionEndpoints.ORDERS, [
    PermissionLevels.VIEW,
    PermissionLevels.VIEW_OWN,
  ]);

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const copyToClipboard = useCustomClipboard();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, changeAdressStatus, changeAddress] = useDownloadData(offerings.changeAddress);

  const [activeId, setActiveId] = useState(null);
  const [targetStatus, setTargetStatus] = useState(null);
  const [actionPending, setActionPending] = useState(false);

  const isOfferAvailableForViewOnPlatform = (status: string) =>
    status !== OfferingStatus.DRAFT && status !== OfferingStatus.CANCELED && status !== OfferingStatus.FUNDED;

  const openChangeAddressModal = (uuid: string, address: GeocodeResult) => {
    setActiveId(uuid);
    setTargetAddress(address);
    setIsChangeAddressModalOpen(true);
  };

  const openDeleteModal = (uuid: string) => {
    setActiveId(uuid);
    setIsDeleteDialogOpen(true);
  };

  const openChangeStatusModal = (status: string, uuid: string) => {
    setTargetStatus(status);
    setActiveId(uuid);
    setIsChangeStatusModalOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setActiveId(null);
  };

  const closeChangeAddressModal = () => {
    setIsChangeAddressModalOpen(false);
    setActiveId(null);
  };

  const closeChangeStatusModal = () => {
    setIsChangeStatusModalOpen(false);
    setActiveId(null);
    setTargetStatus(null);
  };

  const changeStatus = () => {
    dispatch(changeOfferStatus({ uuid: activeId, status: targetStatus }));
  };

  const changeVisibility = async (uuid: string, visibility: string) => {
    setActionPending(true);
    try {
      await offerings.changeVisibility(uuid, visibility);
      dispatch(getOffers());
    } finally {
      setActionPending(false);
    }
  };

  const viewItems = (uuid: string) => {
    history.push({
      pathname: `/admin/offers/items`,
      search: `?offerId=${uuid}`,
    });
  };

  const viewOrders = (uuid: string) => {
    history.push({
      pathname: `/admin/orders/list`,
      search: `?offering_uuid=${uuid}`,
    });
  };

  const changeOfferAddress = async () => {
    const address = omit(targetAddress, ['geo']);
    const { geo } = targetAddress;
    await changeAddress(activeId, address, geo);
    dispatch(getOffers());
  };

  const deleteCurrentOffer = async () => {
    dispatch(deleteOffer(activeId));
  };

  const viewOffer = (uuid: string) => {
    const appDomain = process.env.REACT_APP_APP_URL || 'https://squarefi.co';
    window.open(`${appDomain}/investment/${uuid}`, '_blank');
  };

  const editOffer = async (uuid: string) => {
    history.push(`/admin/offers/edit?offerId=${uuid}`);
  };

  const openEditCryptoAccounts = (uuid: string) => {
    history.push({
      pathname: `/admin/offers/crypto-accounts/edit`,
      search: `?offerUuid=${uuid}`,
    });
  };

  const createAgentOffer = async (uuid: string) => {
    const response = await offering_items.getAll(uuid);
    const offeringItems = response.data;

    if (!offeringItems?.length) {
      return toast({
        title: `Items not found. To create agent offer you need to add items to the offer`,
        position: 'top',
        status: 'error',
        isClosable: true,
      });
    }

    const itemId = offeringItems[0].id;

    history.push({
      pathname: `/admin/agent-offers/create/offer`,
      search: `?itemId=${itemId}&offerUuid=${uuid}`,
    });
  };

  const columns = [
    columnHelper.accessor('name', {
      id: 'offerName',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Offer name
        </Text>
      ),
      cell: (info: any) => (
        <Flex alignItems='center'>
          <Avatar size='lg' name='Offer image' mr='14px' src={getImageUrl(info.row.original.images)} />
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('fundraiser', {
      id: 'fundraiser',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Seller
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {info.getValue() ? info.getValue().name : ''}
        </Text>
      ),
    }),
    columnHelper.accessor('developer', {
      id: 'developer',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Developer
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {info.getValue() ? info.getValue().name : ''}
        </Text>
      ),
    }),
    columnHelper.accessor('address', {
      id: 'address',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Address
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {printAddress(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Status
        </Text>
      ),
      cell: info => (
        <Badge
          colorScheme={offeringStatusesInfo[info.getValue()]?.theme}
          color={offeringStatusesInfo[info.getValue()]?.fontColor}
          fontSize={offeringStatusesInfo[info.getValue()]?.fontSize}
          fontWeight={offeringStatusesInfo[info.getValue()]?.fontWeight}
          width={'140px'}
          textAlign='center'
        >
          {offeringStatusesInfo[info.getValue()]?.name}
        </Badge>
      ),
    }),
    columnHelper.accessor('visibility', {
      id: 'visibility',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Visibility
        </Text>
      ),
      cell: info => (
        <Badge
          color={offeringVisibilityInfo[info.getValue()].fontColor}
          fontSize={offeringVisibilityInfo[info.getValue()].fontSize}
          fontWeight={offeringVisibilityInfo[info.getValue()].fontWeight}
          width={'100px'}
          textAlign='center'
        >
          {info.getValue().toLowerCase()}
        </Badge>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Actions
        </Text>
      ),
      cell: info => {
        const { uuid, status, visibility, address } = info.row?.original;

        const offerStatusDetails = offeringStatusesInfo[status];
        const offerVisibilityDetails = offeringVisibilityInfo[visibility];
        const isPrivateOffer = visibility === OfferingVisibility.PRIVATE;
        return (
          <Menu>
            <MenuButton
              as={Button}
              leftIcon={actionPending ? <Spinner alignSelf='center' size={'xs'} /> : <MdTouchApp />}
              fontSize='14px'
              textTransform='uppercase'
              colorScheme='blue'
              bgColor='blue.400'
              h='28px'
              borderRadius='6px'
              display='flex'
              alignItems='center'
            >
              Actions
            </MenuButton>
            <MenuList minWidth='0px' fontSize='large' padding='10px'>
              <MenuItem icon={<MdOutlineContentCopy />} onClick={() => copyToClipboard(uuid)}>
                Copy offer UUID
              </MenuItem>
              {isOfferAvailableForViewOnPlatform(status) && (
                <MenuItem icon={<MdLocalOffer />} onClick={() => viewOffer(uuid)}>
                  View offer
                </MenuItem>
              )}
              {currentRouteUserPermissions.canUpdate && (
                <MenuItem icon={<MdEdit />} onClick={() => editOffer(uuid)}>
                  Edit offer
                </MenuItem>
              )}
              {currentRouteUserPermissions.canView && (
                <MenuItem icon={<MdOutlineVisibility />} onClick={() => viewItems(uuid)}>
                  View items
                </MenuItem>
              )}
              {currentRouteUserPermissions.canUpdate && (
                <MenuItem icon={<MdToken />} onClick={() => openEditCryptoAccounts(uuid)}>
                  Edit crypto accounts
                </MenuItem>
              )}
              {currentRouteUserPermissions.canUpdate && (
                <MenuItem icon={<BsGeoAltFill />} onClick={() => openChangeAddressModal(uuid, address)}>
                  Change address
                </MenuItem>
              )}

              {status !== OfferingStatus.DRAFT && currentRouteUserPermissions.canUpdate && (
                <MenuItem icon={<RiDraftLine />} onClick={() => openChangeStatusModal(OfferingStatus.DRAFT, uuid)}>
                  Change status to "{offeringStatusesInfo[OfferingStatus.DRAFT]?.name}"
                </MenuItem>
              )}
              {offerStatusDetails?.nextStatus && currentRouteUserPermissions.canUpdate && (
                <MenuItem
                  icon={<MdRebaseEdit />}
                  onClick={() => openChangeStatusModal(offerStatusDetails?.nextStatus, uuid)}
                >
                  Change status to "{offeringStatusesInfo[offerStatusDetails.nextStatus]?.name}"
                </MenuItem>
              )}
              {offerVisibilityDetails.nextStatus && currentRouteUserPermissions.canUpdate && (
                <MenuItem
                  icon={isPrivateOffer ? <MdVisibility /> : <MdVisibilityOff />}
                  onClick={() => changeVisibility(uuid, offerVisibilityDetails?.nextStatus)}
                >
                  {isPrivateOffer ? 'Show offer' : 'Hide offer'}
                </MenuItem>
              )}
              {offerStatusDetails?.canDelete && currentRouteUserPermissions.canDelete && (
                <MenuItem icon={<MdDelete />} onClick={() => openDeleteModal(uuid)}>
                  Delete offer
                </MenuItem>
              )}
              {canViewOrders && (
                <MenuItem icon={<MdOutlineRealEstateAgent />} onClick={() => viewOrders(uuid)}>
                  View orders
                </MenuItem>
              )}
              {canCreateAgentOffer && (
                <MenuItem icon={<MdOutlineRealEstateAgent />} onClick={() => createAgentOffer(uuid)}>
                  Create agent offer
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        );
      },
    }),
  ];
  const [data] = useState(() => [...tableData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card mt='20px'>
      <Flex direction='column' w='100%' overflowX={{ sm: 'auto', lg: 'auto' }}>
        <Box overflowX='auto'>
          <Table variant='simple' color='gray.500' mt='12px' minWidth='800'>
            <Thead>
              {table.getHeaderGroups().map(headerGroup => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers?.map(header => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe='10px'
                        borderColor={borderColor}
                        cursor='pointer'
                        onClick={header.column?.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'
                        >
                          {flexRender(header.column?.columnDef?.header, header.getContext())}
                          {{
                            asc: <FaSortDown />,
                            desc: <FaSortUp />,
                          }[header.column?.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          {flexRender(cell.column?.columnDef?.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Flex>
      <ChangeOfferAddressModal
        changeTargetAddress={setTargetAddress}
        changeOfferAddressHandler={changeOfferAddress}
        onClose={closeChangeAddressModal}
        currentAddress={targetAddress}
        isOpen={isChangeAddressModalOpen}
        loadingStatus={changeAdressStatus}
      />
      <DeleteConfirmation
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        confirmationHandler={deleteCurrentOffer}
        text={`Are you sure you want to delete this offer? It's an irreversible process`}
        loadingStatus={changeAdressStatus}
      />
      <ChangeOfferStatusModal
        isChangeStatusModalOpen={isChangeStatusModalOpen}
        closeChangeStatusModal={closeChangeStatusModal}
        changeStatus={changeStatus}
        targetStatus={targetStatus}
      />
    </Card>
  );
};

export default OffersTable;
