import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import './assets/css/Customize.css';

import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';

import { BrowserRouter, Switch } from 'react-router-dom';

import theme from './theme/theme';

import App from 'components/app';
import { ErrorFallback, logError } from 'components/common/ErrorFallback';
import { store } from 'store/store';

ReactDOM.render(
  <BrowserRouter>
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <React.StrictMode>
            <Switch>
              <App />
            </Switch>
          </React.StrictMode>
        </ChakraProvider>
      </Provider>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById('root')
);
