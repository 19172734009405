/* eslint-disable */

import {
  Avatar,
  Badge,
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { API } from 'api/types';
// Custom components
import Card from 'components/card/Card';
import { itemsStatusesInfo } from 'helpers/constants';
import usePermissions from 'hooks/usePermissions';
import * as React from 'react';
import { FaCoins, FaSortDown, FaSortUp } from 'react-icons/fa';
// Assets
import { MdEdit, MdNewspaper, MdOutlineNewspaper, MdTouchApp } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import { THistory, TLocation } from 'types';
import { convertTimeStampToDayDate } from 'utils';

const columnHelper = createColumnHelper<API.Admin.OfferingItems.Response>();

const getImageUrl = (image: { url: string }[]) => {
  if (image?.length > 0 && image[0]) {
    return image[0].url;
  }
  return '';
};

type ItemsTableProps = {
  tableData: API.Admin.OfferingItems.Response[];
};

export default function ItemsTable(props: ItemsTableProps) {
  const { tableData } = props;
  const history = useHistory<THistory>();
  const location = useLocation<TLocation>();
  const params = new URLSearchParams(location.search);
  const offerId = params.get('offerId');
  const { currentRouteUserPermissions } = usePermissions();
  const { canUpdate } = currentRouteUserPermissions;

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const editItem = (uuid: string) => {
    history.push({
      pathname: `/admin/offers/item/edit`,
      search: `?itemId=${uuid}&offerId=${offerId}`,
    });
  };

  const editUpdates = (uuid: string) => {
    history.push({
      pathname: `/admin/offers/item/updates`,
      search: `?itemUuid=${uuid}`,
    });
  };

  const viewAssets = (uuid: string) => {
    history.push({
      pathname: `/admin/assets/list`,
      search: `?itemUuid=${uuid}`,
    });
  };

  const columns = [
    columnHelper.display({
      id: 'image',
      header: null,
      cell: (info: any) => (
        <Flex alignItems='center'>
          <Avatar size='lg' name='item image' mr='14px' src={getImageUrl(info.row?.original?.image)} />
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('investment_start', {
      id: 'investment_start',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Investment start
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {convertTimeStampToDayDate(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor('investment_end', {
      id: 'investment_end',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Investment end
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {convertTimeStampToDayDate(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor('total', {
      id: 'total',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Total
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Status
        </Text>
      ),
      cell: info => (
        <Badge
          colorScheme={itemsStatusesInfo[info.getValue()]?.theme}
          color={itemsStatusesInfo[info.getValue()]?.fontColor}
          fontSize={itemsStatusesInfo[info.getValue()]?.fontSize}
          fontWeight={itemsStatusesInfo[info.getValue()]?.fontWeight}
          width={'80px'}
          textAlign='center'
        >
          {info.getValue().toLowerCase()}
        </Badge>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Actions
        </Text>
      ),
      cell: info => {
        const { uuid } = info.row?.original;
        return (
          canUpdate && (
            <Menu>
              <MenuButton
                as={Button}
                leftIcon={<MdTouchApp />}
                fontSize='14px'
                textTransform='uppercase'
                colorScheme='blue'
                bgColor='blue.400'
                h='28px'
                borderRadius='6px'
              >
                Actions
              </MenuButton>
              <MenuList minWidth='0px' fontSize='large' padding='10px'>
                <MenuItem icon={<FaCoins />} onClick={() => viewAssets(uuid)}>
                  View assets
                </MenuItem>
                <MenuItem icon={<MdEdit />} onClick={() => editItem(uuid)}>
                  Edit details
                </MenuItem>
                <MenuItem icon={<MdOutlineNewspaper />} onClick={() => editUpdates(uuid)}>
                  Edit updates
                </MenuItem>
              </MenuList>
            </Menu>
          )
        );
      },
    }),
  ];
  const [data, setData] = React.useState(() => [...tableData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card mt='20px'>
      <Flex direction='column' w='100%' overflowX={{ sm: 'auto', lg: 'auto' }}>
        <Box overflowX='auto'>
          <Table variant='simple' color='gray.500' mt='12px' minWidth='800'>
            <Thead>
              {table.getHeaderGroups().map(headerGroup => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers?.map(header => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe='10px'
                        borderColor={borderColor}
                        cursor='pointer'
                        onClick={header.column?.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'
                        >
                          {flexRender(header.column?.columnDef?.header, header.getContext())}
                          {{
                            asc: <FaSortDown />,
                            desc: <FaSortUp />,
                          }[header.column?.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table
                .getRowModel()
                .rows.slice(0, 11)
                .map(row => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderColor='transparent'
                          >
                            {flexRender(cell.column?.columnDef?.cell, cell.getContext())}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </Card>
  );
}
