type StatusesInfo = {
  [field: string]: {
    theme?: string;
    fontColor?: string;
    fontSize?: string;
    fontWeight?: string;
    nextStatus?: string;
    previousStatus?: string;
    canDelete?: boolean;
    backgroundGradient?: string;
    name?: string;
  };
};

export enum LoadingStatus {
  NONE = 'none',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export enum ItemStatus {
  DRAFT = 'draft',
  READY = 'ready',
  ACTIVE = 'active',
}

export enum OfferingStatus {
  DRAFT = 'draft',
  COMING_SOON = 'comingsoon',
  ACTIVE = 'active',
  FUNDED = 'funded',
  CANCELED = 'canceled',
}

export enum OfferingVisibility {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum KYCStatus {
  APPROVED = 'aproved',
  DECLINED = 'declined',
  PENDING = 'pending',
  HOLD = 'hold',
  DOUBLE = 'double',
  SOFT_REJECT = 'soft_reject',
  REJECT = 'reject',
  UNVERIFIED = 'unverified',
}

export enum ReferralStatus {
  SILVER = 1,
  GOLD = 2,
  PLATINUM = 3,
}

export const itemsStatusesInfo: StatusesInfo = {
  draft: {
    theme: 'gray',
    fontColor: 'gray.500',
    fontSize: 'sm',
    fontWeight: '600',
    nextStatus: ItemStatus.READY,
    canDelete: true,
  },
  ready: {
    theme: 'yellow',
    fontColor: 'yellow.500',
    fontSize: 'sm',
    fontWeight: '600',
    nextStatus: ItemStatus.ACTIVE,
    previousStatus: ItemStatus.DRAFT,
    canDelete: false,
  },
  active: {
    theme: 'green',
    fontColor: 'green.500',
    fontSize: 'sm',
    fontWeight: '600',
    previousStatus: ItemStatus.READY,
    canDelete: false,
  },
};

export const offeringStatusesInfo: StatusesInfo = {
  draft: {
    theme: 'gray',
    fontColor: 'gray.500',
    fontSize: 'sm',
    fontWeight: '600',
    nextStatus: OfferingStatus.COMING_SOON,
    canDelete: true,
    name: 'draft',
  },
  comingsoon: {
    theme: 'yellow',
    fontColor: 'yellow.500',
    fontSize: 'sm',
    fontWeight: '600',
    nextStatus: OfferingStatus.ACTIVE,
    previousStatus: OfferingStatus.DRAFT,
    canDelete: false,
    name: 'coming soon',
  },
  active: {
    theme: 'green',
    fontColor: 'green.500',
    fontSize: 'sm',
    fontWeight: '600',
    nextStatus: OfferingStatus.FUNDED,
    previousStatus: OfferingStatus.COMING_SOON,
    canDelete: false,
    name: 'active',
  },
  funded: {
    theme: 'blue',
    fontColor: 'gray.500',
    fontSize: 'sm',
    fontWeight: '600',
    previousStatus: OfferingStatus.ACTIVE,
    canDelete: false,
    name: 'funded',
  },
  canceled: {
    theme: 'red',
    fontColor: 'red.500',
    fontSize: 'sm',
    fontWeight: '600',
    canDelete: true,
    nextStatus: OfferingStatus.DRAFT,
    name: 'canceled',
  },
};
export const offeringVisibilityInfo: StatusesInfo = {
  private: {
    theme: 'gray',
    fontColor: 'gray.500',
    fontSize: 'sm',
    fontWeight: '600',
    nextStatus: OfferingVisibility.PUBLIC,
  },
  public: {
    theme: 'green',
    fontColor: 'green.500',
    fontSize: 'sm',
    fontWeight: '600',
    nextStatus: OfferingVisibility.PRIVATE,
  },
};
export const kycStatusesInfo: StatusesInfo = {
  unverified: {
    theme: 'gray',
    fontColor: 'gray.500',
    fontSize: 'sm',
    fontWeight: '600',
  },
  aproved: {
    theme: 'green',
    fontColor: 'green.500',
    fontSize: 'sm',
    fontWeight: '600',
  },
  pending: {
    theme: 'yellow',
    fontColor: 'yellow.500',
    fontSize: 'sm',
    fontWeight: '600',
  },
  hold: {
    theme: 'blue',
    fontColor: 'gray.600',
    fontSize: 'sm',
    fontWeight: '600',
  },
  double: {
    theme: 'orange',
    fontColor: 'orange.600',
    fontSize: 'sm',
    fontWeight: '600',
  },
  soft_reject: {
    theme: 'orange',
    fontColor: 'orange.600',
    fontSize: 'sm',
    fontWeight: '600',
  },
  declined: {
    theme: 'red',
    fontColor: 'red.500',
    fontSize: 'sm',
    fontWeight: '600',
  },
  reject: {
    theme: 'red',
    fontColor: 'red.500',
    fontSize: 'sm',
    fontWeight: '600',
  },
};
export const refStatusesInfo: StatusesInfo = {
  SILVER: {
    backgroundGradient: 'linear-gradient(180deg,#eff4f3,#e9edf4)',
    fontWeight: '600',
    fontSize: 'sm',
  },
  GOLD: {
    backgroundGradient: 'linear-gradient(180deg,#fff4de,#f1eadf);',
    fontWeight: '600',
    fontSize: 'sm',
  },
  PLATINUM: {
    backgroundGradient: 'linear-gradient(180deg,#d7f5ff,#efeefe);',
    fontWeight: '600',
    fontSize: 'sm',
  },
};

export const giftcardsBadgeInfo: StatusesInfo = {
  inactive: {
    theme: 'gray',
    fontColor: 'gray.500',
    fontSize: 'sm',
    fontWeight: '600',
  },
  active: {
    theme: 'green',
    fontColor: 'green.500',
    fontSize: 'sm',
    fontWeight: '600',
  },
  used: {
    theme: 'gray',
    fontColor: 'gray.500',
    fontSize: 'sm',
    fontWeight: '600',
  },
  'non-used': {
    theme: 'green',
    fontColor: 'green.500',
    fontSize: 'sm',
    fontWeight: '600',
  },
};

export const smartContractsTypeArr = ['ERC-1155', 'ERC-721', 'ERC-20'];
export const fundraiserManualAddAsset = ['USDT', 'USDC', 'DAI', 'BUSD', 'ETH', 'MATIC', 'TRX', 'BSC', 'BTC'];
export const fundraiserManualAddTokenType = ['ERC-20', 'TRC-20', 'BEP-20'];

export const falsyValues = ['false', '0', '', 'inactive', false, null, undefined, NaN, 0];

export enum OfferingDocumentsType {
  FINANCIAL = 'financial',
  REGULAR = 'regular',
  EXTERNAL = 'external',
  PERSONAL = 'personal',
}

export const DEFAULT_DATA_SOURCE = 'live';

export const defaultTableRowCounts = [10, 25, 50, 100, 500];

export const defaultPaginationParams = {
  page: 1,
  per_page: defaultTableRowCounts[0],
};

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export const OfferingNavMenuItems = {
  OFFER_NAME: { label: 'Offer name', value: 'name' },
  OFFER_DESCRIPTION: { label: 'Offer description', value: 'description' },
  EXTENDED_OFFER_DESCRIPTION: { label: 'Extended description', value: 'article_data' },
  BADGES: { label: 'Badges', value: 'badges' },
  HIGHLIGHTS: { label: 'Highlights', value: 'highlights' },
  FEATURES: { label: 'Features', value: 'features' },
  BONUSES: { label: 'Bonuses', value: 'perks' },
  TIMELINE: { label: 'Timeline', value: 'milestones' },
  INFORMATION: { label: 'Information', value: 'info' },
  DOCUMENTS: { label: 'Documents', value: 'documents' },
  PHOTOS: { label: 'Photos', value: 'photos' },
};

export enum RouteLayouts {
  ADMIN = '/admin',
  AUTH = '/auth',
}
export enum PermissionLevels {
  VIEW = 'view',
  VIEW_OWN = 'view_own',
  CREATE = 'create',
  UPDATE = 'update',
  UPDATE_OWN = 'update_own',
  DELETE = 'delete',
  DELETE_OWN = 'delete_own',
}

export enum PermissionEndpoints {
  AGENT_OFFERS = 'AGENT_OFFERS',
  USER = 'USER',
  FUNDRAISER = 'FUNDRAISER',
  DEVELOPERS = 'DEVELOPERS',
  TENANT = 'TENANT',
  REF_TIERS = 'REF_TIERS',
  GIFTCARDS = 'GIFTCARDS',
  UPLOAD = 'UPLOAD',
  OFFERING_ITEMS = 'OFFERING_ITEMS',
  OFFERINGS_INFO = 'OFFERINGS_INFO',
  OFFERINGS = 'OFFERINGS',
  ORDERS = 'ORDERS',
  INVESTOR_CATEGORIES = 'INVESTOR_CATEGORIES',
  LOCALES = 'LOCALES',
}

export const defaultRoute = '/admin/agent-offers/list';

export enum Locales {
  EN = 'en',
  RU = 'ru',
}

export enum AssetDocumentsStatus {
  NEW = 'new',
  PENDING = 'pending',
  DECLINED = 'declined',
  SIGNED = 'signed',
}

export enum OrderStatus {
  NEW = 'new',
  PENDING = 'pending',
  PAID = 'paid',
  ERROR = 'error',
  CANCELED = 'canceled',
  PARTIALY_PAID = 'patrialy_paid',
}

export enum ReactTableFilterTypes {
  TEXT = 'text',
  SELECT = 'select',
  RANGE = 'range',
}

export enum MediaFileTypes {
  IMAGE = 'image',
  VIDEO = 'video',
  OTHER = 'other',
}

export const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'webm', 'm4v'];
export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'tiff'];
