// Chakra Imports
import { Avatar, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue } from '@chakra-ui/react';

// Custom Components

import { useHistory } from 'react-router';

import { SidebarResponsive } from 'components/sidebar/Sidebar';
// Assets

import usePermissions from 'hooks/usePermissions';
import { selectUser } from 'store/selectors';
import { useAppDispatch, useAppSelector } from 'store/store';
import { logout } from 'store/user';

import { THistory } from 'types';

export default function HeaderLinks(props: { secondary: boolean }) {
  const user = useAppSelector(selectUser);
  const dispath = useAppDispatch();
  const history = useHistory<THistory>();
  const { allowedRoutes } = usePermissions();

  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  const logoutHandler = async () => {
    await dispath(logout());
    history.push('/auth');
  };

  return (
    <Flex
      w='fit'
      gap='20px'
      alignItems='center'
      justifyContent='space-between'
      flexDirection='row'
      bg={menuBg}
      py='10px'
      px='20px'
      borderRadius='30px'
      boxShadow={shadow}
    >
      <SidebarResponsive routes={allowedRoutes} />
      <Menu>
        <MenuButton p='0px'>
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color='white'
            name={user?.first_name && `${user?.first_name} ${user?.last_name}`}
            bg='#11047A'
            size='sm'
            w='40px'
            h='40px'
            src={user?.avatar?.url}
          />
        </MenuButton>
        <MenuList boxShadow={shadow} p='0px' mt='10px' borderRadius='20px' bg={menuBg} border='none'>
          <Flex w='100%' mb='0px'>
            <Text
              ps='20px'
              pt='16px'
              pb='10px'
              w='100%'
              borderBottom='1px solid'
              borderColor={borderColor}
              fontSize='sm'
              fontWeight='700'
              color={textColor}
            >
              {user?.first_name} {user?.last_name}
            </Text>
          </Flex>
          <Flex flexDirection='column' p='10px'>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color='red.400'
              borderRadius='8px'
              px='14px'
              onClick={logoutHandler}
            >
              <Text fontSize='sm'>Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
