import { Text, useColorModeValue } from '@chakra-ui/react';

import ItemForm from '../../../components/forms/ItemForm';

import Card from 'components/card/Card';

function Step3({ isEditForm = false }: { isEditForm?: boolean }) {
  const textColor = useColorModeValue('navy.700', 'white');

  return (
    <Card p='30px'>
      <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
        Add your first item
      </Text>
      <ItemForm isEditForm={isEditForm} isCreateOfferStep />
    </Card>
  );
}

export default Step3;
