import { Flex, Text } from '@chakra-ui/react';

const AccessDenied = () => {
  return (
    <Flex justifyContent='center' alignItems='center' h='100vh' flexDirection='column'>
      <Text fontSize='9xl'>403</Text>
      <Text fontSize='6xl'>ACCESS DENIED</Text>
    </Flex>
  );
};

export default AccessDenied;
