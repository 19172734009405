/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import OrdersTable from '../components/tables/OrdersTable';

import { API } from 'api/types';
import { defaultPaginationParams } from 'helpers/constants';
import { loadOrders } from 'store/orders';
import { useAppDispatch } from 'store/store';

const OrdersList = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const queryParam = new URLSearchParams(location.search);
  const [paginationParams, setPaginationParams] = useState<API.Pagination.Request>(defaultPaginationParams);

  const offeringUuid = queryParam.get('offering_uuid');

  const loadItems = () => dispatch(loadOrders({ ...paginationParams, offering_uuid: offeringUuid }));
  const clearFilters = () => {
    history.replace({
      search: '',
    });
    dispatch(loadOrders(null));
  };

  useEffect(() => {
    loadItems();
  }, [paginationParams]);

  return (
    <Flex
      direction='column'
      minH='100vh'
      align='start'
      justifyContent='start'
      pt={{ sm: '125px', lg: '75px' }}
      position='relative'
    >
      <OrdersTable
        setPaginationParams={setPaginationParams}
        loadItems={loadItems}
        offeringUuid={offeringUuid}
        clearFilters={clearFilters}
      />
    </Flex>
  );
};

export default OrdersList;
