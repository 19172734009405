import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { useFormik, FormikConfig } from 'formik';
import Cookies from 'js-cookie';
import React from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
// Chakra imports
// Assets

// Custom components
import { auth } from 'api/auth';
import { defaultRoute } from 'helpers/constants';
import CenteredAuth from 'layouts/auth/variants/Centered';
import { useAppDispatch } from 'store/store';
import { getUser } from 'store/user';
import { THistory } from 'types';
import { passRegExp } from 'utils/regExps';

type Values = { email: string; password: string };

function SignIn() {
  const dispatch = useAppDispatch();
  const history = useHistory<THistory>();

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const [show, setShow] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const handleClick = () => setShow(!show);

  const formikConfig: FormikConfig<Values> = {
    initialValues: {
      email: '',
      password: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      email: Yup.string().email('Email not correct').required('Email is required'),
      password: Yup.string()
        .min(8, 'Password must be more than 8 characters')
        .matches(passRegExp, 'Password not correct')
        .required('Password is required'),
    }),
    onSubmit: async (values: Values) => {
      setLoading(true);

      try {
        const {
          status,
          data: { authToken },
        } = await auth.login(values);

        if (status === 200) {
          Cookies.set('authToken', authToken);
          await dispatch(getUser());
          history.push(defaultRoute);
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
  };

  const formik = useFormik<Values>(formikConfig);

  return (
    <CenteredAuth
      image={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'}
      cardTop={{ base: '140px', md: '14vh' }}
      cardBottom={{ base: '50px', lg: 'auto' }}
      mx='0px'
    >
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w='100%'
        mx={{ base: 'auto', lg: '0px' }}
        me='auto'
        justifyContent='center'
        px={{ base: '20px', md: '0px' }}
        flexDirection='column'
      >
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text mb='36px' ms='4px' color={textColorSecondary} fontWeight='400' fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: '100%', md: '420px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}
        >
          <form onSubmit={formik.handleSubmit}>
            <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <FormControl isInvalid={!!formik.errors?.email} mb='3'>
              <fieldset>
                <Input
                  name='email'
                  onChange={formik.handleChange}
                  value={formik.values?.email}
                  fontSize='sm'
                  ms={{ base: '0px', md: '0px' }}
                  type='text'
                  placeholder='mail@simmmple.com'
                  fontWeight='500'
                  size='lg'
                  errorBorderColor='crimson'
                />
                {!formik.errors?.email ? (
                  <FormHelperText mb='6'></FormHelperText>
                ) : (
                  <FormErrorMessage>{formik.errors?.email}</FormErrorMessage>
                )}
              </fieldset>
            </FormControl>
            <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <FormControl isInvalid={!!formik.errors?.password} mb='3'>
                <Input
                  name='password'
                  onChange={formik.handleChange}
                  value={formik.values?.password}
                  fontSize='sm'
                  ms={{ base: '0px', md: '4px' }}
                  placeholder='Min. 8 characters'
                  size='lg'
                  type={show ? 'text' : 'password'}
                  errorBorderColor='crimson'
                />
                {!formik.errors?.password ? (
                  <FormHelperText></FormHelperText>
                ) : (
                  <FormErrorMessage>{formik.errors?.password}</FormErrorMessage>
                )}
              </FormControl>
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              my='24px'
              isLoading={isLoading}
              type='submit'
            >
              Sign In
            </Button>
          </form>
        </Flex>
      </Flex>
    </CenteredAuth>
  );
}

export default SignIn;
