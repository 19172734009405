import { AddIcon } from '@chakra-ui/icons';
import { Flex, Heading, Link } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link as ReachLink } from 'react-router-dom';

import FundraisersTable from '../../components/FundraisersTable';

import Card from 'components/card/Card';

import BasePreloader from 'components/preloader/BasePreloader';

import { LoadingStatus } from 'helpers/constants';
import usePermissions from 'hooks/usePermissions';
import { getFundraisers } from 'store/fundraisers';
import { selectFundrisers, selectLoadBlockchainsStatus, selectLoadFundrisersStatus } from 'store/selectors';
import { useAppDispatch, useAppSelector } from 'store/store';

export default function Fundraisers() {
  const dispatch = useAppDispatch();
  const LoadFundraisersStatus = useAppSelector(selectLoadFundrisersStatus);
  const LoadBlockchainsStatus = useAppSelector(selectLoadBlockchainsStatus);
  const fundraisers = useAppSelector(selectFundrisers);
  const { currentRouteUserPermissions } = usePermissions();

  useEffect(() => {
    dispatch(getFundraisers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = LoadFundraisersStatus === LoadingStatus.PENDING || LoadBlockchainsStatus === LoadingStatus.PENDING;

  return (
    <Flex
      direction='column'
      minH='100vh'
      align='start'
      justifyContent='start'
      pt={{ sm: '125px', lg: '75px' }}
      position='relative'
    >
      {currentRouteUserPermissions.canCreate && (
        <Link as={ReachLink} to='/admin/fundraisers/create' maxWidth='400' width='100%'>
          <Card cursor='pointer'>
            <Flex me='-16px' justifyContent='space-between' alignItems='center'>
              <Heading size='xs'>Create new fundraiser</Heading>
              <AddIcon mr='5' />
            </Flex>
          </Card>
        </Link>
      )}
      {!isLoading ? <FundraisersTable tableData={fundraisers} /> : <BasePreloader />}
    </Flex>
  );
}
