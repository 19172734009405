import { parse, isDate } from 'date-fns';

import { imageExtensions, MediaFileTypes, videoExtensions } from 'helpers/constants';

export function parseDateString(value: string, originalValue: string) {
  const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'yyyy-MM-dd', new Date());

  return parsedDate;
}

export const convertTimeStampToDayDate = (timestamp: number | string) => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const convertDateToTimeStamp = (date: string | number | Date) => {
  const timestamp = new Date(date).getTime();

  return timestamp;
};

export const swapArrElements = (arr: any[], indexA: number, indexB: number) => {
  [arr[indexA], arr[indexB]] = [arr[indexB], arr[indexA]];
};

export const smoothScrollById = (id: string) => {
  const element = document.getElementById(id);

  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

export const getFileType = (fileName: string): MediaFileTypes => {
  const fileExtension = fileName.split('.').pop()?.toLowerCase();

  if (!fileExtension) {
    return MediaFileTypes.OTHER;
  }

  if (videoExtensions.includes(fileExtension)) {
    return MediaFileTypes.VIDEO;
  }

  if (imageExtensions.includes(fileExtension)) {
    return MediaFileTypes.IMAGE;
  }

  return MediaFileTypes.OTHER;
};
