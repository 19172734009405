/* eslint-disable react-hooks/exhaustive-deps */
import { useClipboard, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const useCustomClipboard = () => {
  const toast = useToast();
  const [copiedValue, setCopiedValue] = useState<string>(null);

  const { hasCopied, onCopy } = useClipboard(copiedValue);

  useEffect(() => {
    hasCopied &&
      toast({
        title: `value ${copiedValue} successfully copied to clipboard`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
  }, [hasCopied]);

  useEffect(() => {
    copiedValue && onCopy();
  }, [copiedValue]);

  const copyValueToClipboard = (value: string) => {
    setCopiedValue(value);
  };

  return copyValueToClipboard;
};

export default useCustomClipboard;
