// Chakra imports
import { Flex, Image } from '@chakra-ui/react';

// Custom components
import squarefiBlackLogo from 'assets/svg/sqrf_black_svg.svg';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
  //   Chakra color mode

  return (
    <Flex alignItems='center' flexDirection='column' justifyContent='center' pr='5'>
      <Image src={squarefiBlackLogo} fit='contain' width='70%' />
      <HSeparator mt='20px' mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
