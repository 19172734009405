import { createAsyncThunk, createSlice, Slice } from '@reduxjs/toolkit';

import { omit } from 'lodash';

import { order } from 'api/admin';
import { API } from 'api/types';
import { LoadingStatus } from 'helpers/constants';

interface OrderState {
  items: API.Admin.Order.Order[];
  meta: API.Pagination.Response;
  status: LoadingStatus;
}

const initialState: OrderState = {
  items: [],
  meta: {} as API.Pagination.Response,
  status: LoadingStatus.NONE,
};

export const loadOrders = createAsyncThunk('orders/list', async (props: API.Admin.Order.GetAll.Request) => {
  const { data } = await order.getAll(props);
  const orders = { items: data.items, meta: omit(data, 'items') };

  return orders;
});

export const ordersSlice: Slice<OrderState> = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadOrders.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(loadOrders.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.items = action.payload.items;
        state.meta = action.payload.meta;
      })
      .addCase(loadOrders.rejected, state => {
        state.status = LoadingStatus.REJECTED;
        state.items = [];
        state.meta = {} as API.Pagination.Response;
      });
  },
});

export default ordersSlice.reducer;
