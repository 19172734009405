import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { FC, useEffect, useState } from 'react';

import { LoadingStatus } from 'helpers/constants';

type DeleteConfirmationProps = {
  isOpen: boolean;
  onClose: () => void;
  confirmationHandler: () => void;
  text: string;
  loadingStatus?: LoadingStatus;
};

const DeleteConfirmation: FC<DeleteConfirmationProps> = ({
  isOpen,
  onClose,
  confirmationHandler,
  text,
  loadingStatus,
}) => {
  const [delay, setDelay] = useState(5);

  useEffect(() => {
    isOpen && delay > 0 && setTimeout(() => setDelay(delay - 1), 1000);
  }, [delay, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setDelay(5);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg='none' backdropFilter='auto' backdropBlur='10px' />
      <ModalContent>
        <ModalHeader>Delete confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{text}</ModalBody>
        <ModalFooter>
          <Button variant='outline' mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isDisabled={!!delay}
            colorScheme='red'
            onClick={confirmationHandler}
            isLoading={loadingStatus === LoadingStatus.PENDING}
          >
            Delete {!!delay && `(${delay})`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteConfirmation;
