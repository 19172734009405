/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  createStandaloneToast,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Grid,
  GridItem,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';

import { Field, FieldAttributes, Formik } from 'formik';
import { debounce } from 'lodash';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { developers } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';
import CustomPhotoUploader from 'components/common/CustomPhotoUploader';
import InputField from 'components/fields/InputField';
import { LoadingStatus } from 'helpers/constants';
import useDownloadData from 'hooks/useDownloadData';
import { useLeaveConfirmation } from 'hooks/useLeaveConfirmation';
import { loadDevelopers } from 'store/developers';
import { useAppDispatch } from 'store/store';
import { THistory, TLocation } from 'types';
import { geocodeAddress, GeocodeResult } from 'utils/geocoder';
import { acceptedImgFileTypes } from 'views/admin/offers/components/config';

type DeveloperFormProps = {
  nextStep?: () => void;
};

const initialValues: API.Admin.Developer.Request = {
  name: '',
  description: '',
  legal_details: { VAT: '', legal_name: '' },
};

const quillModules = {
  toolbar: [['bold'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
};

const quillFormats = ['bold', 'list', 'bullet', 'link'];

export const DeveloperForm: FC<DeveloperFormProps> = ({ nextStep }) => {
  const [developerActionStatus, setDeveloperActionStatus] = useState<LoadingStatus>(LoadingStatus.NONE);
  const [address, setAddress] = useState('');
  const [geocoderData, setGeocoderData] = useState<GeocodeResult | null>(null);
  const [addressStatus, setAddressStatus] = useState({ isLoad: false, status: 'none' });
  const { disableLeaveConfirmation } = useLeaveConfirmation();
  const dispatch = useAppDispatch();

  const [developerInfo, getDeveloperStatus, getDeveloper] = useDownloadData<API.Admin.Developer.Response>(
    developers.getById
  );

  const history = useHistory<THistory>();
  const location = useLocation<TLocation>();
  const params = new URLSearchParams(location.search);
  const developerUuid = params.get('developerUuid');

  const toast = createStandaloneToast();

  const checkAddress = (e: SyntheticEvent) => {
    setAddressStatus({ status: 'none', isLoad: true });

    geocodeAddress(address)
      .then(result => {
        if (result.country) {
          setGeocoderData(result);
          setAddressStatus({ status: 'valid', isLoad: false });
        } else {
          setAddressStatus({ status: 'invalid', isLoad: false });
        }
      })
      .catch(error => {
        console.error(error);
        setAddressStatus({ status: 'invalid', isLoad: false });
      });
  };

  const submitData = async (developerData: API.Admin.Developer.Request) => {
    if (addressStatus.status === 'valid') {
      developerData.location = {
        country: geocoderData.country,
        country_code: geocoderData.countryCode,
        state: geocoderData.state,
        city: geocoderData.city || '',
        zip: geocoderData.code || '',
        building: geocoderData.house || '',
      };
    }

    setDeveloperActionStatus(LoadingStatus.PENDING);

    if (!!developerUuid) {
      await developers
        .update(developerUuid, developerData)
        .then(() => {
          setDeveloperActionStatus(LoadingStatus.FULFILLED);
          disableLeaveConfirmation();
          toast({
            title: `Developer has been successfully updated`,
            position: 'top',
            status: 'success',
            isClosable: true,
          });
          history.push({
            pathname: `/admin/developers/list`,
          });
          dispatch(loadDevelopers());
        })
        .catch(e => {
          setDeveloperActionStatus(LoadingStatus.REJECTED);
          console.log(e);
        });
    } else {
      await developers
        .create(developerData)
        .then(({ data }) => {
          setDeveloperActionStatus(LoadingStatus.FULFILLED);
          disableLeaveConfirmation();
          if (nextStep) {
            nextStep();
            history.push({
              pathname: '',
              search: `?developerUuid=${data.uuid}`,
            });
          } else {
            history.push({
              pathname: `/admin/developers/list`,
            });
            toast({
              title: `Developer has been successfully created`,
              position: 'top',
              status: 'success',
              isClosable: true,
            });
          }
          dispatch(loadDevelopers());
        })
        .catch(e => {
          setDeveloperActionStatus(LoadingStatus.REJECTED);
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (!!developerUuid) {
      getDeveloper(developerUuid);
    }
  }, []);

  useEffect(() => {
    if (!!developerUuid && getDeveloperStatus === LoadingStatus.FULFILLED) {
      if (developerInfo?.location) {
        const { location } = developerInfo;
        setGeocoderData({ ...developerInfo.location });
        setAddress(`${location.country ?? ''} ${location.state ?? ''} ${location.city ?? ''}`);
      }
    }
  }, [getDeveloperStatus]);

  const isLoading =
    getDeveloperStatus === LoadingStatus.PENDING || (getDeveloperStatus === LoadingStatus.NONE && !!developerUuid);

  if (isLoading) {
    return (
      <Flex w='100%' h='500px' justifyContent='center' alignItems='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brand.500' size='xl' />
      </Flex>
    );
  }

  if (getDeveloperStatus === LoadingStatus.REJECTED) {
    return (
      <Text
        fontSize={{ sm: '16px', lg: '20px' }}
        fontWeight='bold'
        textTransform='uppercase'
        marginTop='80px'
        color='white'
      >
        Something went wrong, please try again
      </Text>
    );
  }

  return (
    <Card p='30px'>
      <Formik
        validateOnChange
        validateOnBlur
        initialValues={!!developerUuid ? developerInfo : initialValues}
        validationSchema={Yup.object({
          name: Yup.string().max(50, 'maximum 50 symbols'),
          description: Yup.string().max(2000, 'maximum 2000 symbols'),
          legal_details: Yup.object().shape({
            legal_name: Yup.string().max(20, 'maximum 20 symbols'),
            VAT: Yup.string().max(10, 'maximum 10 symbols'),
          }),
        })}
        onSubmit={submitData}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Flex direction='column' w='100%'>
              <Stack direction='column' spacing='20px'>
                <FormControl isInvalid={!!errors.name} mb='0'>
                  <InputField
                    mb='0px'
                    id='name'
                    name='name'
                    placeholder='Input developer name'
                    label='Developer name'
                    onChange={handleChange}
                    value={values.name}
                  />
                  {!errors?.name ? (
                    <FormHelperText mb='25px'></FormHelperText>
                  ) : (
                    <FormErrorMessage>{errors?.name}</FormErrorMessage>
                  )}
                </FormControl>
                <Field name='description'>
                  {({ field }: FieldAttributes<any>) => {
                    const onFieldChange = debounce(field.onChange(field.name), 250);
                    return (
                      <ReactQuill
                        modules={quillModules}
                        formats={quillFormats}
                        theme='snow'
                        defaultValue={field.value}
                        onChange={onFieldChange}
                      />
                    );
                  }}
                </Field>
                <Grid templateRows='1fr' templateColumns='repeat(3, 1fr)' gap={4}>
                  <GridItem colSpan={2}>
                    <FormControl isInvalid={addressStatus.status === 'invalid'} mb='0'>
                      <InputField
                        mb='0'
                        id='location'
                        name='location'
                        placeholder='Address'
                        label='Developer address'
                        onChange={(e: SyntheticEvent) => setAddress((e.target as HTMLInputElement).value)}
                        value={address}
                        onBlur={checkAddress}
                      />
                      {addressStatus.status === 'none' && <FormHelperText mb='27px'></FormHelperText>}
                      {addressStatus.status === 'valid' && (
                        <FormHelperText color='green'>The address is valid</FormHelperText>
                      )}
                      {addressStatus.status === 'invalid' && (
                        <FormErrorMessage>The address is not valid</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Flex align='start' h='100%'>
                      <Button
                        mt='30px'
                        width='100%'
                        variant='brand'
                        size='md'
                        onClick={checkAddress}
                        isLoading={addressStatus.isLoad}
                      >
                        Check address
                      </Button>
                    </Flex>
                  </GridItem>
                </Grid>
                <Text fontSize='2xl' fontWeight='700'>
                  Legal details
                </Text>
                <Flex gap='4'>
                  <FormControl mb='20px' isInvalid={!!errors.legal_details?.VAT}>
                    <InputField
                      mb='0px'
                      id='legal_details.VAT'
                      name='legal_details.VAT'
                      placeholder='Input VAT'
                      label='VAT'
                      onChange={handleChange}
                      value={values.legal_details?.VAT}
                    />
                    {!errors?.legal_details?.VAT ? (
                      <FormHelperText mb='25px'></FormHelperText>
                    ) : (
                      <FormErrorMessage>{errors?.legal_details?.VAT}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={!!errors.legal_details?.legal_name}>
                    <InputField
                      mb='0px'
                      id='legal_details.legal_name'
                      name='legal_details.legal_name'
                      placeholder='Input legal name'
                      label='Legal name'
                      onChange={handleChange}
                      value={values.legal_details?.legal_name}
                    />
                    {!errors?.legal_details?.legal_name ? (
                      <FormHelperText mb='25px'></FormHelperText>
                    ) : (
                      <FormErrorMessage>{errors?.legal_details?.legal_name}</FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
                <Text fontSize='2xl' fontWeight='700'>
                  Logo
                </Text>
                <CustomPhotoUploader
                  photoFromServer={developerInfo?.logo}
                  acceptedFileTypes={acceptedImgFileTypes}
                  setExternalPhotos={logos => (values.logo = logos[0])}
                />
              </Stack>
              <Flex justify='space-between' mt='24px'>
                <Button
                  isLoading={developerActionStatus === LoadingStatus.PENDING || addressStatus.isLoad}
                  variant='darkBrand'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: '128px', md: '148px' }}
                  h='46px'
                  ms='auto'
                  type='submit'
                  disabled={!!Object.values(errors).length}
                >
                  {!!developerUuid ? 'Save changes' : 'Next Step'}
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default DeveloperForm;
