import axios, { AxiosResponse } from 'axios';

export interface GeocodeResultGeo {
  lat: number | null;
  lng: number | null;
}

export interface GeocodeResult {
  country?: string;
  countryCode?: string;
  city?: string;
  house?: string;
  code?: string;
  street?: string;
  state?: string;
  block?: string;
  geo?: GeocodeResultGeo;
}

export const geocodeAddress = async (address: string): Promise<GeocodeResult> => {
  const apiKey = 'AIzaSyD-KaJmltoPvlKRubCIhyc3TAzp95283K4'; //
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${apiKey}&language=en`;

  const response: AxiosResponse = await axios.get(url);
  const data = response.data;

  if (data.status === 'OK') {
    const components = data.results[0].address_components;
    const result = components.reduce((acc: GeocodeResult, component: any) => {
      const type = component.types[0];

      if (type === 'country') {
        acc.country = component.long_name;
        acc.countryCode = component.short_name;
      } else if (type === 'locality') {
        acc.city = component.long_name;
      } else if (type === 'street_number') {
        acc.house = component.long_name;
      } else if (type === 'postal_code') {
        acc.code = component.long_name;
      } else if (type === 'route') {
        acc.street = component.long_name;
      } else if (type === 'administrative_area_level_1') {
        acc.state = component.long_name;
      } else if (type === 'neighborhood' || type === 'sublocality') {
        acc.block = component.long_name;
      }
      return acc;
    }, {} as GeocodeResult);

    result.geo = data.results[0].geometry.location;
    return result;
  } else {
    throw new Error(`Geocode was not successful for the following reason: ${data.status}`);
  }
};

export const printAddress = (address: GeocodeResult) => {
  return (
    address &&
    Object.values(address)
      .filter(value => !!value)
      .join(', ')
  );
};
