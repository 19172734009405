import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { developers } from 'api/admin';
import { API } from 'api/types';
import { LoadingStatus } from 'helpers/constants';

interface DeveloperState {
  items: API.Admin.Developer.Response[];
  status: LoadingStatus;
}

const initialState: DeveloperState = {
  items: [],
  status: LoadingStatus.NONE,
};

export const loadDevelopers = createAsyncThunk('developers/list', async () => {
  const res = await developers.getAll();
  return res.data;
});

export const developersSlice = createSlice({
  name: 'developers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadDevelopers.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(loadDevelopers.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.items = action.payload;
      })
      .addCase(loadDevelopers.rejected, state => {
        state.status = LoadingStatus.REJECTED;
      });
  },
});

export default developersSlice.reducer;
