import { RootState } from './store';
import { PrettyUserPermissions } from './types';

export const selectLoadAssetsStatus = (store: RootState) => store.assets.status;
export const selectAssets = (store: RootState) => store.assets.items;

export const selectLoadFundrisersStatus = (store: RootState) => store.fundraisers.status;
export const selectFundrisers = (store: RootState) => store.fundraisers.fundraisers;

export const selectLoadDevelopersStatus = (store: RootState) => store.developers.status;
export const selectDevelopers = (store: RootState) => store.developers.items;

export const selectLoadBlockchainsStatus = (store: RootState) => store.blockchains.status;
export const selectBlockchains = (store: RootState) => store.blockchains.blockchains;

export const selectloadCryptoAssetsStatus = (store: RootState) => store.cryptoAssets.status;
export const selectCryptoAssets = (store: RootState) => store.cryptoAssets.cryptoAssets;

export const selectUsers = (store: RootState) => store.users;

export const selectLoadGiftcardsStatus = (store: RootState) => store.giftcards.status;
export const selectGiftcards = (store: RootState) => store.giftcards.items;

export const selectLoadOffersStatus = (store: RootState) => store.offers.status;
export const selectOffers = (store: RootState) => store.offers.offers;

export const selectIsAuthUser = (store: RootState) => store.user?.isAuth;
export const selectUser = (store: RootState) => store.user?.info;
export const selectLoadUserStatus = (store: RootState) => store.user?.status;
export const selectPrettyUserPermissions = (store: RootState) => {
  const permissions: PrettyUserPermissions = store.user?.permissions?.reduce((acc, item) => {
    return { ...acc, [item.endpoint]: item.permissions };
  }, {});
  return permissions;
};

export const selectLeaveConfirmationTargetPath = (store: RootState) => store.common.leaveConfirmationModal.targetPath;
export const selectIsLeaveConfirmationAcitve = (store: RootState) => store.common.leaveConfirmationModal.isActive;

export const selectInvestorCategories = (store: RootState) => store.common.investorCategories.items;
export const selectLoadInvestorCategoriesStatus = (store: RootState) => store.common.investorCategories.status;

export const selectUserRoles = (store: RootState) => store.common.userRoles.items;
export const selectLoadUserRolesStatus = (store: RootState) => store.common.userRoles.status;

export const selectCompliance = (store: RootState) => store.common.compliance.items;
export const selectLoadComplianceStatus = (store: RootState) => store.common.compliance.status;

export const selectTenants = (store: RootState) => store.tenants.items;
export const selectLoadTenantsStatus = (store: RootState) => store.tenants.status;

export const selectOrders = (store: RootState) => store.orders;
export const selectLoadOrdersStatus = (store: RootState) => store.orders.status;

export const selectAgentOffers = (store: RootState) => store.agent_offers.items;
export const selectLoadAgentOffersStatus = (store: RootState) => store.agent_offers.status;

export const selectPaymentTypes = (store: RootState) => store.paymentTypes.items;
export const selectLoadPaymentTypesStatus = (store: RootState) => store.paymentTypes.status;

export const selectProjectGoals = (store: RootState) => store.projectGoals;
export const selectLoadProjectGoalsStatus = (store: RootState) => store.projectGoals.status;

export const selectHeaderNavMenu = (store: RootState) => store.ui.headerNavMenuItems;
