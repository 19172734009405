import { createSlice } from '@reduxjs/toolkit';

type OfferingNavMenuItems = {
  [key: string]: {
    label: string;
    value: string;
  };
};

type UiState = {
  headerNavMenuItems: null | OfferingNavMenuItems;
};

const initialState: UiState = {
  headerNavMenuItems: null,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setHeaderNavMenu: (state, { payload }) => {
      state.headerNavMenuItems = payload;
    },
  },
});

export const { setHeaderNavMenu } = uiSlice.actions;

export default uiSlice.reducer;
