// Chakra imports
import { Flex, FormLabel, Input, Text, useColorModeValue } from '@chakra-ui/react';
import { FC } from 'react';
// Custom components

type InputFieldProps = {
  id?: string;
  label?: string | JSX.Element;
  extra?: JSX.Element;
  placeholder?: string;
  type?: string;
  containerWidth?: string;
  [x: string]: any;
};

const InputField: FC<InputFieldProps> = props => {
  const { id, label, extra, placeholder, type, mb, containerWidth, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Flex direction='column' mb={mb ? mb : '30px'} w={containerWidth && containerWidth}>
      <FormLabel
        display='flex'
        ms='10px'
        htmlFor={id}
        fontSize='sm'
        color={textColorPrimary}
        fontWeight={props.fontWeight ?? 'bold'}
        _hover={{ cursor: 'pointer' }}
      >
        {label}
        <Text fontSize='sm' fontWeight='400' ms='2px'>
          {extra}
        </Text>
      </FormLabel>
      <Input
        {...rest}
        type={type}
        id={id}
        fontWeight='500'
        placeholder={placeholder}
        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
        h='44px'
        errorBorderColor='crimson'
      />
    </Flex>
  );
};

export default InputField;
