/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  createStandaloneToast,
  Flex,
  FormControl,
  Grid,
  Icon,
  Select,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';

import { offerings } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';

import DeleteConfirmation from 'components/dialogs/DeleteConfirmation';
import InputField from 'components/fields/InputField';
import { LoadingStatus } from 'helpers/constants';
import useDownloadData from 'hooks/useDownloadData';
import { selectCryptoAssets, selectBlockchains } from 'store/selectors';
import { useAppSelector } from 'store/store';
import { THistory, TLocation } from 'types';
import { getBlockchainNameById } from 'utils/converters';

type AddressFormProps = {
  isEditForm?: boolean;
};

type AdressFormValues = {
  generate: {
    crypto_assets_id: number;
  };
  manual: {
    address: string;
    crypto_assets_id: number;
  };
};

const initialValues: AdressFormValues = {
  generate: {
    crypto_assets_id: null,
  },
  manual: {
    address: '',
    crypto_assets_id: null,
  },
};

export default function AddressForm({ isEditForm = false }: AddressFormProps) {
  const [createAccountStatus, setCreateAccountStatus] = useState<LoadingStatus>(LoadingStatus.NONE);
  const [deletingAccount, setDeletingAccount] = useState<API.Admin.Offerings.CryptoAccounts.Item>(null);
  const [offerAccounts, getOfferAccountsStatus, getOfferAccounts] = useDownloadData(offerings.getCryptoAccounts);

  const blockchains = useAppSelector(selectBlockchains);
  const cryptoAssets = useAppSelector(selectCryptoAssets);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation<TLocation>();
  const params = new URLSearchParams(location.search);
  const toast = createStandaloneToast();
  const offerUuid = params.get('offerUuid');
  const history = useHistory<THistory>();

  useEffect(() => {
    getOfferAccounts(offerUuid);
  }, [offerUuid]);

  const generateNewAccount = (formData: API.Admin.Offerings.CryptoAccounts.GenerateAddress) => {
    setCreateAccountStatus(LoadingStatus.PENDING);
    offerings
      .generateCryptoAccount(offerUuid, formData)
      .then(() => {
        setCreateAccountStatus(LoadingStatus.FULFILLED);
        toast({
          title: `Account successfully generated and added`,
          position: 'top',
          status: 'success',
          isClosable: true,
        });
        getOfferAccounts(offerUuid);
      })
      .catch(e => setCreateAccountStatus(LoadingStatus.REJECTED));
  };

  const addNewAddress = ({ crypto_assets_id, address }: API.Admin.Offerings.CryptoAccounts.ImportAdress) => {
    const uploadData = { crypto_assets_id, address };
    setCreateAccountStatus(LoadingStatus.PENDING);
    offerings
      .importCryptoAccounts(offerUuid, uploadData)
      .then(() => {
        setCreateAccountStatus(LoadingStatus.FULFILLED);
        toast({
          title: `Address successfully generated and added`,
          position: 'top',
          status: 'success',
          isClosable: true,
        });
        getOfferAccounts(offerUuid);
      })
      .catch(e => setCreateAccountStatus(LoadingStatus.REJECTED));
  };

  const deleteAccount = () => {
    if (!isAccountsPending) {
      setCreateAccountStatus(LoadingStatus.PENDING);
      offerings
        .deleteCryptoAccount(offerUuid, deletingAccount.uuid)
        .then(() => {
          setCreateAccountStatus(LoadingStatus.FULFILLED);
          toast({
            title: `Account successfully deleted`,
            position: 'top',
            status: 'success',
            isClosable: true,
          });
          getOfferAccounts(offerUuid);
          closeDeleteModal();
        })
        .catch(e => setCreateAccountStatus(LoadingStatus.REJECTED));
    }
  };

  const submitForm = () => {
    history.push({
      pathname: `/admin/fundraisers/list`,
    });
  };

  const openDeleteModal = (account: API.Admin.Offerings.CryptoAccounts.Item) => {
    setDeletingAccount(account);
    onOpen();
  };

  const closeDeleteModal = () => {
    setDeletingAccount(null);
    onClose();
  };

  const isOfferAccountLoading = getOfferAccountsStatus === LoadingStatus.PENDING;
  const isAccountsPending = createAccountStatus === LoadingStatus.PENDING;

  if (isOfferAccountLoading) {
    return (
      <Flex w='100%' h='500px' justifyContent='center' alignItems='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brand.500' size='xl' />
      </Flex>
    );
  }

  return (
    <Card p='30px'>
      {offerAccounts?.crypto_accounts.length > 0 ? (
        <Grid templateColumns='repeat(5, 1fr)' gap={4} marginBottom='100' overflowX='auto'>
          <Text fontSize='l' fontWeight='700'>
            Blockchain
          </Text>
          <Text fontSize='l' fontWeight='700'>
            Type
          </Text>
          <Text fontSize='l' fontWeight='700'>
            Asset
          </Text>
          <Text fontSize='l' fontWeight='700'>
            Address
          </Text>
          <Text fontSize='l' fontWeight='700'></Text>
          {offerAccounts.crypto_accounts.map((account, index) => (
            <Fragment key={index}>
              <Text>{getBlockchainNameById(account.crypto_asset.blockchains_id, blockchains)}</Text>
              <Text>{account.crypto_asset.type}</Text>
              <Text>{account.crypto_asset.symbol}</Text>
              <Text>{account.address}</Text>
              <Icon
                as={MdDelete}
                cursor='pointer'
                justifySelf='center'
                _hover={{ color: 'red' }}
                onClick={() => openDeleteModal(account)}
              />
            </Fragment>
          ))}
        </Grid>
      ) : (
        <Text marginBottom='50'>No active accounts</Text>
      )}
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {({ values, handleChange, handleSubmit }) => (
          <form>
            <Flex direction='column' w='100%'>
              <Stack direction='column' spacing='20px' marginBottom='50'>
                <Text fontSize='xl' fontWeight='700'>
                  Generate a new address
                </Text>
                <Flex flexDirection={{ base: 'column', md: 'row' }} gap='4'>
                  <Flex flexDirection='column' w='100%'>
                    <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                      Crypto assets
                    </Text>
                    <Select
                      fontSize='sm'
                      id='generate.crypto_assets_id'
                      name='generate.crypto_assets_id'
                      h='44px'
                      maxH='44px'
                      me='20px'
                      onChange={handleChange}
                      defaultValue={values.generate?.crypto_assets_id}
                      placeholder='select crypto asset'
                    >
                      {cryptoAssets.map(asset => (
                        <option key={asset.id} value={asset.id}>
                          {getBlockchainNameById(asset.blockchains_id, blockchains)}, {asset.type}, {asset.descriprion}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  <Button
                    isLoading={isAccountsPending}
                    variant='darkBrand'
                    fontSize='sm'
                    alignSelf='end'
                    borderRadius='16px'
                    flexShrink={0}
                    w={{ base: 'full', md: '100px' }}
                    h='46px'
                    ms='auto'
                    onClick={() => generateNewAccount(values.generate)}
                    disabled={!values.generate?.crypto_assets_id}
                  >
                    Generate
                  </Button>
                </Flex>
              </Stack>
              <Stack direction='column' spacing='20px' marginBottom='50'>
                <Text fontSize='xl' fontWeight='700'>
                  Add a new address manually
                </Text>
                <Flex gap='4' flexDirection={{ base: 'column', md: 'row' }}>
                  <Flex flexDirection='column' w='100%'>
                    <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                      Crypto assets
                    </Text>
                    <Select
                      fontSize='sm'
                      id='manual.crypto_assets_id'
                      name='manual.crypto_assets_id'
                      h='44px'
                      maxH='44px'
                      me='20px'
                      onChange={handleChange}
                      defaultValue={values.manual?.crypto_assets_id}
                      placeholder='select crypto asset'
                    >
                      {cryptoAssets.map(asset => (
                        <option key={asset.id} value={asset.id}>
                          {getBlockchainNameById(asset.blockchains_id, blockchains)}, {asset.type}, {asset.descriprion}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  <FormControl mb='0' w='full'>
                    <InputField
                      mb='0px'
                      id='manual.address'
                      name='manual.address'
                      placeholder='input address'
                      label='Address'
                      onChange={handleChange}
                    />
                  </FormControl>
                  <Button
                    isLoading={isAccountsPending}
                    variant='darkBrand'
                    fontSize='sm'
                    alignSelf='end'
                    borderRadius='16px'
                    flexShrink={0}
                    w={{ base: 'full', md: '100px' }}
                    h='46px'
                    ms='auto'
                    onClick={() => addNewAddress(values.manual)}
                    disabled={!values.manual?.address || !values.manual?.crypto_assets_id}
                  >
                    Add
                  </Button>
                </Flex>
              </Stack>
              <Flex justify='space-between' mt='24px'>
                <Button
                  disabled={isAccountsPending}
                  variant='darkBrand'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: 'full', md: '148px' }}
                  h='46px'
                  ms='auto'
                  onClick={submitForm}
                >
                  {isEditForm ? 'Save changes' : 'Next Step'}
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
      <DeleteConfirmation
        isOpen={isOpen}
        onClose={closeDeleteModal}
        confirmationHandler={deleteAccount}
        text='Are you sure you want to delete address? It’s an irreversible process'
        loadingStatus={createAccountStatus}
      />
    </Card>
  );
}
