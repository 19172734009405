import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { Dispatch, FC, SetStateAction, useState } from 'react';

import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { MdDelete, MdEdit, MdOutlineContentCopy, MdTouchApp } from 'react-icons/md';

import ReactPaginate from 'react-paginate';

import { useHistory } from 'react-router-dom';

import { project_goals } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';
import DeleteConfirmation from 'components/dialogs/DeleteConfirmation';
import OverlayPreloader from 'components/preloader/OverlayPreloader';
import { LoadingStatus } from 'helpers/constants';
import useCustomClipboard from 'hooks/useCustomClipboard';
import usePermissions from 'hooks/usePermissions';
import { selectLoadProjectGoalsStatus, selectProjectGoals } from 'store/selectors';
import { useAppSelector } from 'store/store';
import { ReactPaginateChangePageEvent } from 'types';
import { prettyId } from 'utils/converters';

type ProjectGoalsTableProps = {
  loadItems: () => void;
  setPaginationParams: Dispatch<SetStateAction<API.Pagination.Request>>;
};

const columnHelper = createColumnHelper<API.Admin.ProjectGoals.Item>();

const ProjectGoalsTable: FC<ProjectGoalsTableProps> = ({ loadItems, setPaginationParams }) => {
  const { items, meta } = useAppSelector(selectProjectGoals);
  const loadGoalsStatus = useAppSelector(selectLoadProjectGoalsStatus);
  const isItemsPending = loadGoalsStatus === LoadingStatus.PENDING;
  const { currentRouteUserPermissions } = usePermissions();
  const { canDelete, canUpdate } = currentRouteUserPermissions;
  const toast = useToast();
  const history = useHistory();

  const [activeGoalId, setActiveGoalId] = useState<string | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteGoalStatus, setDeleteGoalStatus] = useState(LoadingStatus.NONE);

  const openDeleteDialog = (goalId: string) => {
    setActiveGoalId(goalId);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setActiveGoalId(null);
    setIsDeleteDialogOpen(false);
  };

  const deleteDialogText = `Do you realy want to delete goal ${activeGoalId}? It's an irreversible process`;
  const deleteGoal = async () => {
    try {
      setDeleteGoalStatus(LoadingStatus.PENDING);
      await project_goals.delete(activeGoalId);
      toast({
        title: `Goal ${activeGoalId} successfully deleted`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      closeDeleteDialog();
      loadItems();
      setDeleteGoalStatus(LoadingStatus.FULFILLED);
    } catch (e) {
      setDeleteGoalStatus(LoadingStatus.REJECTED);
    }
  };

  const changePage = (e: ReactPaginateChangePageEvent) => {
    setPaginationParams(prevRequestParams => ({ ...prevRequestParams, page: e.selected + 1 }));
  };

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const copyToClipboard = useCustomClipboard();

  const editProjectGoal = async (id: string) => {
    history.push(`/admin/offers/project-goals/edit?projectGoalId=${id}`);
  };

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Goal id
        </Text>
      ),
      cell: info => (
        <Flex alignItems='center'>
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {prettyId(info.getValue())}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Goal name
        </Text>
      ),
      cell: info => (
        <Flex flexDirection='column'>
          <Text color={textColor} fontSize='sm' fontWeight='600' minW={'150px'}>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Created
        </Text>
      ),
      cell: info => (
        <Text color={textColor} whiteSpace='nowrap' fontSize='sm' fontWeight='600'>
          {format(new Date(info.getValue()), 'yyyy-MM-dd')}
        </Text>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Actions
        </Text>
      ),
      cell: info => {
        const goal = info.row.original;
        return (
          <Menu>
            <MenuButton
              as={Button}
              leftIcon={<MdTouchApp />}
              fontSize='14px'
              textTransform='uppercase'
              colorScheme='blue'
              bgColor='blue.400'
              h='28px'
              borderRadius='6px'
            >
              Actions
            </MenuButton>
            <MenuList minWidth='0px' fontSize='large' padding='10px'>
              <MenuItem icon={<MdOutlineContentCopy />} onClick={() => copyToClipboard(goal.id)}>
                Copy goal id
              </MenuItem>

              {canUpdate && (
                <MenuItem icon={<MdEdit />} onClick={() => editProjectGoal(goal.id)}>
                  Edit
                </MenuItem>
              )}
              {canDelete && (
                <MenuItem icon={<MdDelete />} onClick={() => openDeleteDialog(goal.id)}>
                  Delete
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    pageCount: meta.pageTotal,
    debugTable: true,
  });

  return (
    <Card mt='20px'>
      <Flex direction='column' w='100%' overflowX={{ sm: 'auto', lg: 'auto' }}>
        <Box overflowX='auto'>
          {isItemsPending && <OverlayPreloader />}

          {!!items.length ? (
            <Table variant='simple' color='gray.500' mt='12px' minWidth='800'>
              <Thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers?.map(header => {
                      return (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          pe='10px'
                          borderColor={borderColor}
                          cursor='pointer'
                          onClick={header.column?.getToggleSortingHandler()}
                        >
                          <Flex
                            justifyContent='space-between'
                            align='center'
                            fontSize={{ sm: '10px', lg: '12px' }}
                            color='gray.400'
                          >
                            {flexRender(header.column?.columnDef?.header, header.getContext())}
                            {{
                              asc: <FaSortDown />,
                              desc: <FaSortUp />,
                            }[header.column?.getIsSorted() as string] ?? null}
                          </Flex>
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map(row => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderColor='transparent'
                          >
                            {flexRender(cell.column?.columnDef?.cell, cell.getContext())}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          ) : (
            <Text fontSize='24px' textAlign='center' textTransform='uppercase' fontWeight='bold'>
              No goals found
            </Text>
          )}
        </Box>
        {meta.pageTotal > 1 && (
          <ReactPaginate
            breakLabel='...'
            nextLabel='next page'
            onPageChange={changePage}
            pageRangeDisplayed={3}
            pageCount={meta.pageTotal}
            previousLabel='prev page'
            renderOnZeroPageCount={null}
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='prev-button'
            previousLinkClassName='page-link'
            nextClassName='next-button'
            nextLinkClassName='page-link'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
          />
        )}
      </Flex>
      <DeleteConfirmation
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        confirmationHandler={deleteGoal}
        text={deleteDialogText}
        loadingStatus={deleteGoalStatus}
      />
    </Card>
  );
};

export default ProjectGoalsTable;
