/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import { Portal, Box, useDisclosure } from '@chakra-ui/react';

import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { LoadingStatus, RouteLayouts } from 'helpers/constants';
import usePermissions from 'hooks/usePermissions';
import routes from 'routes';
import { selectLoadUserStatus } from 'store/selectors';

import { useAppDispatch, useAppSelector } from 'store/store';
import { getUser } from 'store/user';
// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  const dispatch = useAppDispatch();
  const { ...rest } = props;
  const { location } = useHistory();
  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const loadUserStatus = useAppSelector(selectLoadUserStatus);
  const { getReactRoutes, allowedRoutes } = usePermissions();
  const reactRoutes = getReactRoutes(routes, RouteLayouts.ADMIN);

  useEffect(() => {
    const hasToken = Cookies.get('authToken');

    if (hasToken && loadUserStatus === LoadingStatus.NONE) {
      dispatch(getUser());
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = 'Admin panel';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].items) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };

  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={allowedRoutes} display='none' {...rest} />
        <Box
          float='right'
          minHeight='100vh'
          height='100%'
          overflow='auto'
          position='relative'
          maxHeight='100%'
          w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={'Horizon UI Dashboard PRO'}
                brandText={getActiveRoute(allowedRoutes)}
                secondary={getActiveNavbar(allowedRoutes)}
                fixed={false}
                {...rest}
              />
            </Box>
          </Portal>

          <Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='100vh' pt='50px'>
            <Switch>
              {reactRoutes.map(route => {
                return <Route path={route.layout + route.path} component={route.component} key={route.key} />;
              })}
              {/* <Redirect exact from='/' to='/admin/offers/list' /> */}
              <Redirect exact path='*' to={'/404'} />
            </Switch>
          </Box>

          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
