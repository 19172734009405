import { useLocation } from 'react-router-dom';

import { setLeaveConfirmationActive, setLeaveConfirmationPath } from 'store/common';
import { useAppDispatch } from 'store/store';

export const useLeaveConfirmation = (path?: string) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  if (!path) {
    path = location.pathname;
  }

  const enableLeaveConfirmation = () => {
    dispatch(setLeaveConfirmationPath(path));
    dispatch(setLeaveConfirmationActive(true));
  };

  const disableLeaveConfirmation = () => {
    dispatch(setLeaveConfirmationPath(null));
    dispatch(setLeaveConfirmationActive(false));
  };

  return { enableLeaveConfirmation, disableLeaveConfirmation };
};
