import { createAsyncThunk, createSlice, Slice } from '@reduxjs/toolkit';

import { agent_offers } from 'api/admin';
import { API } from 'api/types';
import { LoadingStatus } from 'helpers/constants';

interface AgentOffersState {
  items: API.Admin.AgentOffers.Response[];
  status: LoadingStatus;
}

const initialState: AgentOffersState = {
  items: [],
  status: LoadingStatus.NONE,
};

export const loadAgentOffers = createAsyncThunk('agent_offers/list', async () => {
  const { data } = await agent_offers.getAll();
  return data;
});

export const ordersSlice: Slice<AgentOffersState> = createSlice({
  name: 'agent_offers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadAgentOffers.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(loadAgentOffers.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.items = action.payload;
      })
      .addCase(loadAgentOffers.rejected, state => {
        state.status = LoadingStatus.REJECTED;
        state.items = [];
      });
  },
});

export default ordersSlice.reducer;
