import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  IconButton,
} from '@chakra-ui/react';

import {
  ColumnFiltersState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { format } from 'date-fns';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { MdDelete, MdOutlineContentCopy, MdTouchApp } from 'react-icons/md';

import ReactPaginate from 'react-paginate';

import { order } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';
import { Filter } from 'components/common/ReactTableFilters';
import DeleteConfirmation from 'components/dialogs/DeleteConfirmation';
import OverlayPreloader from 'components/preloader/OverlayPreloader';
import { LoadingStatus, OrderStatus, SortOrder } from 'helpers/constants';
import useCustomClipboard from 'hooks/useCustomClipboard';
import usePermissions from 'hooks/usePermissions';
import usePrevious from 'hooks/usePrevious';
import { selectLoadOrdersStatus, selectOrders, selectPaymentTypes } from 'store/selectors';
import { useAppSelector } from 'store/store';
import { ReactPaginateChangePageEvent } from 'types';
import { prettyId } from 'utils/converters';

interface OrdersTableProps {
  loadItems: () => void;
  setPaginationParams: Dispatch<SetStateAction<API.Pagination.Request>>;
  clearFilters: () => void;
  offeringUuid?: string;
}

const columnHelper = createColumnHelper<API.Admin.Order.Order>();

const OrdersTable: FC<OrdersTableProps> = ({ loadItems, offeringUuid, clearFilters, setPaginationParams }) => {
  const { items, meta } = useAppSelector(selectOrders);
  const loadOrdersStatus = useAppSelector(selectLoadOrdersStatus);
  const paymentTypes = useAppSelector(selectPaymentTypes);
  const isItemsPending = loadOrdersStatus === LoadingStatus.PENDING;
  const isItemsFulfilled = loadOrdersStatus === LoadingStatus.FULFILLED;
  const { currentRouteUserPermissions } = usePermissions();
  const { canDelete } = currentRouteUserPermissions;
  const toast = useToast();

  const [activeOrderUuid, setActiveOrderUuid] = useState<string | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteOrderStatus, setDeleteOrderStatus] = useState(LoadingStatus.NONE);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const previousFilters = usePrevious(columnFilters);

  const getPaymentTypeName = (id: string) => paymentTypes.find(type => type.id === id)?.name || 'N/A';

  const openDeleteDialog = (orderUuid: string) => {
    setActiveOrderUuid(orderUuid);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setActiveOrderUuid(null);
    setIsDeleteDialogOpen(false);
  };

  const deleteDialogText = `Do you realy want to delete order ${activeOrderUuid}? It's an irreversible process`;
  const deleteOrder = async () => {
    try {
      setDeleteOrderStatus(LoadingStatus.PENDING);
      await order.delete(activeOrderUuid);
      toast({
        title: `Order ${activeOrderUuid} successfully deleted`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      closeDeleteDialog();
      loadItems();
      setDeleteOrderStatus(LoadingStatus.FULFILLED);
    } catch (e) {
      setDeleteOrderStatus(LoadingStatus.REJECTED);
    }
  };

  const changePage = (e: ReactPaginateChangePageEvent) => {
    setPaginationParams(prevRequestParams => ({ ...prevRequestParams, page: e.selected + 1 }));
  };

  const changeSorting = () => {
    if (!sorting.length) {
      return setPaginationParams(prevRequestParams => ({
        ...prevRequestParams,
        sort_by: undefined,
        sort_order: undefined,
      }));
    }

    const sort_by = sorting[0].id;
    const sort_order = sorting[0].desc ? SortOrder.DESC : SortOrder.ASC;
    setPaginationParams(prevRequestParams => ({
      ...prevRequestParams,
      sort_by,
      sort_order,
    }));
  };

  const changeFilters = () => {
    const filters = columnFilters.reduce((acc, filter) => {
      return { ...acc, [filter.id]: filter.value };
    }, {});

    const deletedFiltersList = previousFilters?.filter(
      prevFilter => !columnFilters.some(filter => filter.id === prevFilter.id)
    );

    const deletedFilters = deletedFiltersList?.reduce((acc, filter) => {
      return { ...acc, [filter.id]: undefined };
    }, {});

    setPaginationParams(prevRequestParams => ({
      ...prevRequestParams,
      ...filters,
      ...deletedFilters,
    }));
  };

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const copyToClipboard = useCustomClipboard();

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Order id
        </Text>
      ),
      cell: info => (
        <Flex alignItems='center'>
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.getValue()}
          </Text>
        </Flex>
      ),
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('offering.name', {
      id: 'offering.name',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Offering name
        </Text>
      ),
      cell: info => (
        <Flex flexDirection='column'>
          <Text color={textColor} fontSize='sm' fontWeight='600' minW={'150px'}>
            {info.getValue()}
          </Text>
        </Flex>
      ),
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('user.email', {
      id: 'user.email',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          User email
        </Text>
      ),
      cell: info => (
        <Flex flexDirection='column'>
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.getValue() || 'N/A'}
          </Text>
        </Flex>
      ),
      enableColumnFilter: false,
    }),
    columnHelper.accessor('agent.email', {
      id: 'agent.email',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Agent Email
        </Text>
      ),
      cell: info => (
        <Flex flexDirection='column'>
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.getValue() || 'N/A'}
          </Text>
        </Flex>
      ),
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('amount', {
      id: 'amount',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Amount
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {info.getValue()}
        </Text>
      ),
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('amount_paid', {
      id: 'amount_paid',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Paid
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {info.getValue()}
        </Text>
      ),
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Status
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {info.getValue()}
        </Text>
      ),
      enableSorting: false,
      enableColumnFilter: true,
      meta: {
        filterVariant: 'select',
        filterData: Object.values(OrderStatus).map(status => ({ value: status, label: status })),
      },
    }),

    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Created
        </Text>
      ),
      cell: info => (
        <Text color={textColor} whiteSpace='nowrap' fontSize='sm' fontWeight='600'>
          {format(new Date(info.getValue()), 'yyyy-MM-dd')}
        </Text>
      ),
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('payment_type_id', {
      id: 'payment_type_id',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Payment type
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {getPaymentTypeName(info.getValue())}
        </Text>
      ),
      meta: {
        filterVariant: 'select',
        filterData: paymentTypes.map(type => ({ value: type.id, label: type.code })),
      },
      enableSorting: false,
      enableColumnFilter: true,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
          whiteSpace='nowrap'
        >
          Actions
        </Text>
      ),
      cell: info => {
        const order = info.row.original;
        return (
          <Menu>
            <MenuButton
              as={Button}
              leftIcon={<MdTouchApp />}
              fontSize='14px'
              textTransform='uppercase'
              colorScheme='blue'
              bgColor='blue.400'
              h='28px'
              borderRadius='6px'
            >
              Actions
            </MenuButton>
            <MenuList minWidth='0px' fontSize='large' padding='10px'>
              <MenuItem icon={<MdOutlineContentCopy />} onClick={() => copyToClipboard(order.uuid)}>
                Copy order UUID
              </MenuItem>
              <MenuItem icon={<MdOutlineContentCopy />} onClick={() => copyToClipboard(order.offering.uuid)}>
                Copy offering UUID
              </MenuItem>
              {canDelete && (
                <MenuItem icon={<MdDelete />} onClick={() => openDeleteDialog(order.uuid)}>
                  Delete order
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        );
      },
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ];

  const table = useReactTable({
    data: items,
    columns,
    filterFns: {},
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    pageCount: meta.pageTotal,
    manualSorting: true,
    manualFiltering: true,
    enableSortingRemoval: false,
    debugTable: true,
    debugColumns: false,
  });

  useEffect(() => {
    changeSorting();
  }, [sorting]);

  useEffect(() => {
    console.log('here');
    changeFilters();
  }, [columnFilters]);

  return (
    <Card mt='20px'>
      <Flex direction='column' w='100%' overflowX={{ sm: 'auto', lg: 'auto' }}>
        <Box overflowX='auto'>
          {isItemsPending && <OverlayPreloader />}
          {offeringUuid && (
            <Flex
              justifyContent='space-between'
              align='center'
              mb='20px'
              pl='12px'
              py='4px'
              borderRadius='30px'
              border='1px'
              width='fit-content'
            >
              Offer UUID: {prettyId(offeringUuid)}
              <IconButton
                isRound
                onClick={clearFilters}
                type='button'
                icon={<IoCloseCircleOutline />}
                aria-label='Clear filters'
                size={'xs'}
                fontSize='20px'
                mx='8px'
              />
            </Flex>
          )}

          <Table variant='simple' color='gray.500' mt='12px' minWidth='800'>
            <Thead>
              {table.getHeaderGroups().map(headerGroup => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers?.map(header => {
                    return (
                      <Th key={header.id} colSpan={header.colSpan} pe='10px' borderColor={borderColor}>
                        <Flex gap='2'>
                          <Flex
                            justifyContent='space-between'
                            align='center'
                            fontSize={{ sm: '10px', lg: '12px' }}
                            color='gray.400'
                            cursor={header.column?.getCanSort() ? 'pointer' : 'default'}
                            onClick={header.column?.getToggleSortingHandler()}
                          >
                            {flexRender(header.column?.columnDef?.header, header.getContext())}
                            {{
                              asc: <FaSortDown />,
                              desc: <FaSortUp />,
                            }[header.column?.getIsSorted() as string] ?? null}
                          </Flex>
                          {header.column.getCanFilter() ? <Filter column={header.column} /> : null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {!!items.length ? (
                table.getRowModel().rows.map(row => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderColor='transparent'
                          >
                            {flexRender(cell.column?.columnDef?.cell, cell.getContext())}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              ) : (
                <Td colSpan={100}>
                  {isItemsFulfilled && (
                    <Text fontSize='24px' textAlign='center' textTransform='uppercase' fontWeight='bold'>
                      No orders found
                    </Text>
                  )}
                </Td>
              )}
            </Tbody>
          </Table>
        </Box>
        {meta.pageTotal > 1 && (
          <ReactPaginate
            breakLabel='...'
            nextLabel='next page'
            onPageChange={changePage}
            pageRangeDisplayed={3}
            pageCount={meta.pageTotal}
            previousLabel='prev page'
            renderOnZeroPageCount={null}
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='prev-button'
            previousLinkClassName='page-link'
            nextClassName='next-button'
            nextLinkClassName='page-link'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
          />
        )}
      </Flex>
      <DeleteConfirmation
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        confirmationHandler={deleteOrder}
        text={deleteDialogText}
        loadingStatus={deleteOrderStatus}
      />
    </Card>
  );
};

export default OrdersTable;
