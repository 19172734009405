/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { Field, FieldArray, FieldAttributes, Formik, FormikProps } from 'formik';
import 'react-quill/dist/quill.snow.css';

import { debounce, uniqueId } from 'lodash';
import { Dispatch, Fragment, MutableRefObject, SetStateAction, useEffect, useMemo, useRef } from 'react';
import { MdArrowCircleDown, MdArrowCircleUp } from 'react-icons/md';
import ReactQuill from 'react-quill';
import * as Yup from 'yup';

import { acceptedImgFileTypes } from '../config';

import DocsFields, { DocsFieldsDocument } from '../DocsFields';
import FormField from '../FormField';

import { API } from 'api/types';
import CustomPhotoUploader from 'components/common/CustomPhotoUploader';
import InputField from 'components/fields/InputField';
import { LoadingStatus, OfferingDocumentsType, OfferingNavMenuItems } from 'helpers/constants';

import { useAppDispatch } from 'store/store';
import { setHeaderNavMenu } from 'store/ui';
import { convertTimeStampToDayDate, swapArrElements } from 'utils';

type AdditionalInfoFormProps = {
  data: API.Admin.OfferingsAdditionalInfo.Request;
  formRef: MutableRefObject<FormikProps<API.Admin.OfferingsAdditionalInfo.Request>>;
  documents: DocsFieldsDocument[];
  setDocuments: Dispatch<SetStateAction<DocsFieldsDocument[]>>;
};

const additionalInfoValidationSchema = Yup.object({
  name: Yup.string().max(30, 'maximum 30 symbols'),
  main_text: Yup.string().max(300, 'maximum 300 symbols'),
  description: Yup.string().max(250, 'maximum 250 symbols'),
  info: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().max(30, 'maximum 30 symbols'),
      value: Yup.string().max(30, 'maximum 30 symbols'),
      tooltip: Yup.string().max(30, 'maximum 30 symbols'),
    })
  ),
  badges: Yup.array().of(
    Yup.object().shape({
      icon: Yup.string().max(30, 'maximum 30 symbols'),
      text: Yup.string().max(30, 'maximum 30 symbols'),
      type: Yup.string().max(30, 'maximum 30 symbols'),
    })
  ),
  sidebar_1: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().max(30, 'maximum 30 symbols'),
      value: Yup.string().max(30, 'maximum 30 symbols'),
      tooltip: Yup.string().max(30, 'maximum 30 symbols'),
    })
  ),
  features: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().max(30, 'maximum 30 symbols'),
      value: Yup.string().max(30, 'maximum 30 symbols'),
      tooltip: Yup.string().max(30, 'maximum 30 symbols'),
    })
  ),
  tags: Yup.array().of(
    Yup.object().shape({
      text: Yup.string().max(15, 'maximum 15 symbols'),
    })
  ),
  popup: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().max(15, 'maximum 15 symbols'),
      description: Yup.string().max(15, 'maximum 15 symbols'),
      button: Yup.string().max(15, 'maximum 15 symbols'),
    })
  ),
});

const emptyArticleDescription: API.Admin.OfferingsAdditionalInfo.ArticleDataDescription = {
  text: '',
  img: null,
};

const emptyArticleData: API.Admin.OfferingsAdditionalInfo.ArticleData = {
  title: '',
  description: [],
};

const emptyBadge: API.Admin.OfferingsAdditionalInfo.Badge = {
  type: '',
  icon: '',
  text: '',
};

const emptyFeature: API.Admin.OfferingsAdditionalInfo.OfferingFeature = {
  header: '',
  text: '',
};

const emptyInfo: API.Admin.OfferingsAdditionalInfo.Info = {
  title: '',
  value: '',
  tooltip: '',
  icon: '',
};

const emptyBonus: API.Admin.OfferingsAdditionalInfo.Perk = {
  sum: null,
  name: '',
  benefits: [],
};

const getEmptyDocument = (): DocsFieldsDocument => ({
  id: uniqueId(),
  title: '',
  responsefile: {},
  doc_loading_status: LoadingStatus.NONE,
  type: OfferingDocumentsType.REGULAR,
});

const quillModules = {
  toolbar: [['bold'], [{ list: 'ordered' }, { list: 'bullet' }], ['link', 'video']],
};

const quillFormats = ['bold', 'list', 'bullet', 'link'];

export default function AdditionalInfoForm({ data, formRef, documents, setDocuments }: AdditionalInfoFormProps) {
  const { highlights, article_data, badges, features, info, perks, milestones } = data;
  const form = useRef<HTMLFormElement>();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const dispatch = useAppDispatch();

  const addDocument = () => setDocuments(prevDocuments => [...prevDocuments, getEmptyDocument()]);

  const highlightsKeys: string[] = useMemo(() => (highlights ? highlights.map(() => uniqueId()) : []), []);
  const articlesKeys: string[] = useMemo(() => (article_data ? article_data.map(() => uniqueId()) : []), []);
  const badgesKeys: string[] = useMemo(() => (badges ? badges.map(() => uniqueId()) : []), []);
  const featuresKeys: string[] = useMemo(() => (features ? features.map(() => uniqueId()) : []), []);
  const perksKeys: string[] = useMemo(() => (perks ? perks.map(() => uniqueId()) : []), []);
  const milestonesKeys: string[] = useMemo(() => (milestones ? milestones.map(() => uniqueId()) : []), []);
  const infoKeys: string[] = useMemo(() => (info ? info.map(() => uniqueId()) : []), []);

  const articleDescriptionKeys: string[][] = useMemo(() => {
    const descriptionKeys: string[][] = [];
    article_data.forEach(article => {
      const currentDescriptionKeys: string[] = [];
      article.description.forEach(() => currentDescriptionKeys.push(uniqueId()));
      descriptionKeys.push(currentDescriptionKeys);
    });
    return descriptionKeys;
  }, []);

  const perksBenefitsKeys: string[][] = useMemo(() => {
    const benefitsKeys: string[][] = [];
    perks.forEach(perk => {
      const currentBenefitsKeys: string[] = [];
      perk.benefits.forEach(() => currentBenefitsKeys.push(uniqueId()));
      benefitsKeys.push(currentBenefitsKeys);
    });
    return benefitsKeys;
  }, []);

  useEffect(() => {
    const preloadedDocuments = data.documents.map(({ label, url, type }) => ({
      id: uniqueId(),
      title: label,
      responsefile: { url },
      type,
      doc_loading_status: url ? LoadingStatus.FULFILLED : LoadingStatus.NONE,
    }));
    setDocuments(preloadedDocuments);
    dispatch(setHeaderNavMenu(OfferingNavMenuItems));
    return () => {
      dispatch(setHeaderNavMenu(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      innerRef={formRef}
      initialValues={data}
      validationSchema={additionalInfoValidationSchema}
      onSubmit={values => {}}
    >
      {({ values, errors, handleChange, handleSubmit }) => {
        return (
          <form ref={form} onSubmit={handleSubmit}>
            <Flex direction='column' w='100%'>
              <FormControl isInvalid={!!errors.name} mb='0'>
                <InputField
                  mb='0px'
                  name='name'
                  placeholder='OfferName'
                  onBlur={handleChange}
                  label='OfferName'
                  defaultValue={values.name}
                  id={OfferingNavMenuItems.OFFER_NAME.value}
                  scrollMarginTop='180'
                />

                {!errors?.name ? (
                  <FormHelperText mb='26px'></FormHelperText>
                ) : (
                  <FormErrorMessage>{errors?.name}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.description} mb='0'>
                <InputField
                  mb='0px'
                  placeholder='Description'
                  label='Offer description'
                  name='description'
                  onBlur={handleChange}
                  defaultValue={values.description}
                  id={OfferingNavMenuItems.OFFER_DESCRIPTION.value}
                  scrollMarginTop='180'
                />

                {!errors?.description ? (
                  <FormHelperText mb='26px'></FormHelperText>
                ) : (
                  <FormErrorMessage>{errors?.description}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>

            <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px' mt='40px'>
              Extended offer description
            </Text>

            <FieldArray name='article_data'>
              {({ remove, push, move }) => {
                return (
                  <Flex
                    direction='column'
                    justifyContent='center'
                    scrollMarginTop='180'
                    id={OfferingNavMenuItems.EXTENDED_OFFER_DESCRIPTION.value}
                  >
                    {values.article_data?.length > 0 &&
                      values.article_data.map((articleDataItem, articleDataIndex) => (
                        <Flex key={articlesKeys[articleDataIndex]} direction='column' w='100%' mb='10px'>
                          <Stack direction='column' spacing='10px' mb='10px'>
                            <Flex alignItems='center' gap='20px'>
                              <Text color={textColor} fontSize='20px' fontWeight='700'>
                                Extended offer description {articleDataIndex + 1}
                              </Text>
                              <Flex>
                                <Button
                                  p='0'
                                  borderRadius='10px'
                                  variant='ghost'
                                  disabled={articleDataIndex === 0}
                                  colorScheme={'blue'}
                                  onClick={() => {
                                    move(articleDataIndex, articleDataIndex - 1);
                                    swapArrElements(articlesKeys, articleDataIndex, articleDataIndex - 1);
                                    swapArrElements(articleDescriptionKeys, articleDataIndex, articleDataIndex - 1);
                                  }}
                                >
                                  <MdArrowCircleUp size='26px' />{' '}
                                </Button>
                                <Button
                                  p='0'
                                  borderRadius='10px'
                                  disabled={articleDataIndex === values.article_data.length - 1}
                                  variant='ghost'
                                  colorScheme={'blue'}
                                  onClick={() => {
                                    move(articleDataIndex, articleDataIndex + 1);
                                    swapArrElements(articlesKeys, articleDataIndex, articleDataIndex + 1);
                                    swapArrElements(articleDescriptionKeys, articleDataIndex, articleDataIndex + 1);
                                  }}
                                >
                                  <MdArrowCircleDown size='26px' />
                                </Button>
                                <Button
                                  p='0'
                                  borderRadius='10px'
                                  variant='ghost'
                                  colorScheme='red'
                                  fontSize='20px'
                                  onClick={() => {
                                    remove(articleDataIndex);
                                    articlesKeys.splice(articleDataIndex, 1);
                                    articleDescriptionKeys.splice(articleDataIndex, 1);
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Flex>
                            </Flex>
                            <FormField
                              errors={errors}
                              index={articleDataIndex}
                              handleChange={handleChange}
                              name='title'
                              placeholder='Title'
                              label={`Extended description ${articleDataIndex + 1} title`}
                              arrayName='article_data'
                              defaultValue={articleDataItem.title}
                            />
                            <Text color={textColor} fontSize='base' fontWeight='700' mb='40px'>
                              Extended offer description content
                            </Text>
                            <Flex flexDirection={'column'} mt='40px'>
                              <FieldArray name={`article_data[${articleDataIndex}].description`}>
                                {({ remove, push, move }) => {
                                  return (
                                    <Flex flexDirection='column' gap='20px'>
                                      {articleDataItem.description?.length ? (
                                        articleDataItem.description.map((subItem, subIndex) => {
                                          return (
                                            <Fragment key={articleDescriptionKeys[articleDataIndex][subIndex]}>
                                              <Flex flexDirection='column' gap='20px'>
                                                <Flex alignItems='center'>
                                                  <FormLabel
                                                    display='flex'
                                                    flexDirection='column'
                                                    fontSize='sm'
                                                    fontWeight='bold'
                                                    ml='10px'
                                                    mb='0'
                                                  >
                                                    {`Extended description ${articleDataIndex + 1}, content ${
                                                      subIndex + 1
                                                    }`}
                                                  </FormLabel>
                                                  <Button
                                                    p='0'
                                                    borderRadius='10px'
                                                    variant='ghost'
                                                    disabled={subIndex === 0}
                                                    colorScheme={'blue'}
                                                    onClick={() => {
                                                      move(subIndex, subIndex - 1);
                                                      swapArrElements(
                                                        articleDescriptionKeys[articleDataIndex],
                                                        subIndex,
                                                        subIndex - 1
                                                      );
                                                    }}
                                                  >
                                                    <MdArrowCircleUp size='22px' />{' '}
                                                  </Button>
                                                  <Button
                                                    p='0'
                                                    borderRadius='10px'
                                                    disabled={
                                                      subIndex ===
                                                      values.article_data[articleDataIndex].description.length - 1
                                                    }
                                                    variant='ghost'
                                                    colorScheme={'blue'}
                                                    onClick={() => {
                                                      move(subIndex, subIndex + 1);
                                                      swapArrElements(
                                                        articleDescriptionKeys[articleDataIndex],
                                                        subIndex,
                                                        subIndex + 1
                                                      );
                                                    }}
                                                  >
                                                    <MdArrowCircleDown size='22px' />
                                                  </Button>
                                                  <Flex gap='4px'>
                                                    <Button
                                                      p='0'
                                                      borderRadius='10px'
                                                      variant='ghost'
                                                      colorScheme='red'
                                                      onClick={() => {
                                                        remove(subIndex);
                                                        articleDescriptionKeys[articleDataIndex].splice(subIndex, 1);
                                                      }}
                                                    >
                                                      <DeleteIcon />
                                                    </Button>
                                                  </Flex>
                                                </Flex>

                                                <Field
                                                  name={`article_data.${articleDataIndex}.description.${subIndex}.text`}
                                                  arrayName={`article_data[${articleDataIndex}].description`}
                                                >
                                                  {({ field }: FieldAttributes<any>) => {
                                                    const onFieldChange = debounce(field.onChange(field.name), 250);
                                                    return (
                                                      <ReactQuill
                                                        key={articleDescriptionKeys[articleDataIndex][subIndex]}
                                                        modules={quillModules}
                                                        formats={quillFormats}
                                                        theme='snow'
                                                        defaultValue={field.value}
                                                        onChange={onFieldChange}
                                                      />
                                                    );
                                                  }}
                                                </Field>
                                                <Flex direction='column'>
                                                  <CustomPhotoUploader
                                                    key={articleDescriptionKeys[articleDataIndex][subIndex]}
                                                    photoFromServer={subItem?.img}
                                                    acceptedFileTypes={acceptedImgFileTypes}
                                                    setExternalPhotos={photos =>
                                                      (articleDataItem.description[subIndex].img = photos[0] || null)
                                                    }
                                                  />
                                                </Flex>
                                                <Flex
                                                  justifyContent='flex-end'
                                                  gap='20px'
                                                  direction={{ base: 'column', md: 'row' }}
                                                  mb='20px'
                                                >
                                                  <Button
                                                    hidden={articleDataItem.description.length !== subIndex + 1}
                                                    size='xs'
                                                    width={{ base: 'auto', md: 'fit-content' }}
                                                    variant='outline'
                                                    colorScheme='green'
                                                    borderRadius='6px'
                                                    onClick={() => {
                                                      articleDescriptionKeys[articleDataIndex].push(uniqueId());
                                                      push(emptyArticleDescription);
                                                    }}
                                                  >
                                                    Add extended description {articleDataIndex + 1} content
                                                  </Button>
                                                </Flex>
                                              </Flex>
                                            </Fragment>
                                          );
                                        })
                                      ) : (
                                        <Button
                                          size='xs'
                                          width={{ base: 'auto', md: 'fit-content' }}
                                          variant='outline'
                                          alignSelf='end'
                                          colorScheme='blue'
                                          borderRadius='6px'
                                          onClick={() => {
                                            articleDescriptionKeys[articleDataIndex].push(uniqueId());
                                            push(emptyArticleDescription);
                                          }}
                                        >
                                          Add extended description {articleDataIndex + 1} content
                                        </Button>
                                      )}
                                    </Flex>
                                  );
                                }}
                              </FieldArray>
                            </Flex>
                          </Stack>
                        </Flex>
                      ))}
                    <Button
                      size='md'
                      variant='brand'
                      borderRadius='6px'
                      onClick={() => {
                        articleDescriptionKeys.push([]);
                        articlesKeys.push(uniqueId());
                        push(emptyArticleData);
                      }}
                    >
                      Add new extended description
                    </Button>
                  </Flex>
                );
              }}
            </FieldArray>

            <Text color={textColor} fontSize='2xl' fontWeight='700' mt='40px' mb='20px'>
              Badges
            </Text>
            <FieldArray name='badges'>
              {({ push, remove }) => (
                <Flex direction='column' justifyContent='center' gap='20px'>
                  {values.badges?.length > 0 &&
                    values.badges.map((item, index) => (
                      <Stack
                        key={badgesKeys[index]}
                        direction='column'
                        w='100%'
                        spacing='10px'
                        id={OfferingNavMenuItems.BADGES.value}
                        scrollMarginTop='180'
                      >
                        <FormField
                          errors={errors}
                          index={index}
                          handleChange={handleChange}
                          name='text'
                          placeholder='Text'
                          label={`Badge ${index + 1} text`}
                          arrayName='badges'
                          defaultValue={item?.text}
                        />
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap='20px'>
                          <Flex flexDirection='column'>
                            <Text fontSize='sm' px='10px' fontWeight='700' mb='2'>
                              Type
                            </Text>
                            <Select
                              fontSize='sm'
                              id='type'
                              name={`badges.${index}.type`}
                              h='44px'
                              maxH='44px'
                              onBlur={handleChange}
                              defaultValue={item.type}
                              placeholder='select type'
                            >
                              <option value='hot'>Hot</option>
                              <option value='info'>Info</option>
                              <option value='country'>Country</option>
                            </Select>
                          </Flex>
                          <FormField
                            errors={errors}
                            index={index}
                            handleChange={handleChange}
                            name='icon'
                            placeholder={item.type === 'country' ? 'Input country ID' : 'input icon url'}
                            label={item.type === 'country' ? 'Country ID' : 'Icon'}
                            arrayName='badges'
                            defaultValue={item.icon}
                          />
                        </SimpleGrid>
                        <Button
                          colorScheme='red'
                          borderRadius='6px'
                          onClick={() => {
                            badgesKeys.splice(index, 1);
                            remove(index);
                          }}
                        >
                          Delete badge {index + 1}
                        </Button>
                      </Stack>
                    ))}

                  <Button
                    borderRadius='6px'
                    size='md'
                    variant='brand'
                    onClick={() => {
                      badgesKeys.push(uniqueId());
                      push(emptyBadge);
                    }}
                  >
                    Add badge
                  </Button>
                </Flex>
              )}
            </FieldArray>

            <Text color={textColor} fontSize='2xl' fontWeight='700' mt='40px' mb='20px'>
              Highlights
            </Text>
            <FieldArray name='highlights'>
              {({ push, remove }) => (
                <Flex
                  direction='column'
                  justifyContent='center'
                  gap='20px'
                  scrollMarginTop='180'
                  id={OfferingNavMenuItems.HIGHLIGHTS.value}
                >
                  {values.highlights?.length > 0 &&
                    values.highlights.map((_, index) => (
                      <Stack key={highlightsKeys[index]} direction='column' spacing='10px'>
                        <Field
                          as={InputField}
                          label={`highlight ${index + 1}`}
                          name={`highlights.${index}`}
                          placeholder='Input highlight'
                          arrayname='highlights'
                        />
                        <Button
                          colorScheme='red'
                          borderRadius='6px'
                          onClick={() => {
                            remove(index);
                            highlightsKeys.splice(index, 1);
                          }}
                        >
                          Delete highlight {index + 1}
                        </Button>
                      </Stack>
                    ))}
                  <Button
                    borderRadius='6px'
                    variant='brand'
                    onClick={() => {
                      highlightsKeys.push(uniqueId());
                      push('');
                    }}
                  >
                    Add highlight
                  </Button>
                </Flex>
              )}
            </FieldArray>
            <Text color={textColor} fontSize='2xl' fontWeight='700' mt='40px' mb='20px'>
              Features
            </Text>
            <FieldArray name='features'>
              {({ push, remove }) => (
                <Flex
                  direction='column'
                  justifyContent='center'
                  gap='20px'
                  scrollMarginTop='180'
                  id={OfferingNavMenuItems.FEATURES.value}
                >
                  {values.features?.length > 0 &&
                    values.features.map((item, index) => (
                      <Stack key={featuresKeys[index]} direction='column' spacing='10px'>
                        <FormField
                          errors={errors}
                          index={index}
                          handleChange={handleChange}
                          name='header'
                          placeholder={`Input feature header`}
                          label={`Feature ${index + 1} header`}
                          arrayName='features'
                          defaultValue={item.header}
                        />

                        <FormField
                          errors={errors}
                          index={index}
                          handleChange={handleChange}
                          name='text'
                          placeholder='Input feature text'
                          label={`Feature ${index + 1} text`}
                          arrayName='features'
                          defaultValue={item.text}
                        />
                        {/* <FormField
                            errors={errors}
                            index={index}
                            handleChange={handleChange}
                            name='icon'
                            placeholder='Icon'
                            label={`Feature ${index + 1} icon`}
                            arrayName='features'
                            defaultValue={item.icon}
                          /> */}
                        <CustomPhotoUploader
                          key={featuresKeys[index]}
                          photoFromServer={item.icon}
                          acceptedFileTypes={acceptedImgFileTypes}
                          setExternalPhotos={photos => (item.icon = photos[0] || null)}
                        />
                        <Button
                          colorScheme='red'
                          borderRadius='6px'
                          onClick={() => {
                            featuresKeys.splice(index, 1);
                            remove(index);
                          }}
                        >
                          Delete feature {index + 1}
                        </Button>
                      </Stack>
                    ))}
                  <Button
                    borderRadius='6px'
                    variant='brand'
                    onClick={() => {
                      featuresKeys.push(uniqueId());
                      push(emptyFeature);
                    }}
                  >
                    Add feature
                  </Button>
                </Flex>
              )}
            </FieldArray>

            <Text color={textColor} fontSize='2xl' fontWeight='700' mt='40px' mb='20px'>
              Bonuses
            </Text>
            <FieldArray name='perks'>
              {({ push, remove }) => (
                <Flex
                  direction='column'
                  justifyContent='center'
                  gap='10px'
                  scrollMarginTop='180'
                  id={OfferingNavMenuItems.BONUSES.value}
                >
                  {values.perks?.length > 0 &&
                    values.perks.map((perk, perkIndex) => (
                      <Stack key={perksKeys[perkIndex]} direction='column' spacing='10px'>
                        <FormField
                          errors={errors}
                          index={perkIndex}
                          handleChange={handleChange}
                          name='name'
                          placeholder={`Input bonus header`}
                          label={`Bonus ${perkIndex + 1} name`}
                          arrayName='perks'
                          defaultValue={perk.name}
                        />

                        <FormField
                          errors={errors}
                          index={perkIndex}
                          handleChange={handleChange}
                          name='sum'
                          type='number'
                          placeholder='Input bonus sum'
                          label={`Bonus ${perkIndex + 1} sum`}
                          arrayName='perks'
                          defaultValue={perk.sum?.toString()}
                        />
                        <FieldArray name={`perks[${perkIndex}].benefits`}>
                          {({ push, remove }) => {
                            return (
                              <Flex direction='column' justifyContent='center' gap='10px'>
                                {perk.benefits?.length > 0 &&
                                  perk.benefits.map((benefit, benefitIndex) => (
                                    <Stack
                                      key={perksBenefitsKeys[perkIndex][benefitIndex]}
                                      direction='column'
                                      spacing='10px'
                                    >
                                      <InputField
                                        as={InputField}
                                        label={`Benefit ${benefitIndex + 1}`}
                                        name={`perks.${perkIndex}.benefits.${benefitIndex}`}
                                        placeholder='Input benefit'
                                        onBlur={handleChange}
                                        arrayname={`perks[${perkIndex}].benefits`}
                                      />
                                      <Button
                                        colorScheme='red'
                                        borderRadius='6px'
                                        onClick={() => {
                                          remove(benefitIndex);
                                          perksBenefitsKeys[perkIndex].splice(benefitIndex, 1);
                                        }}
                                      >
                                        Delete benefit {benefitIndex + 1}
                                      </Button>
                                    </Stack>
                                  ))}
                                <Button
                                  borderRadius='6px'
                                  variant='brand'
                                  onClick={() => {
                                    perksBenefitsKeys[perkIndex].push(uniqueId());
                                    push('');
                                  }}
                                >
                                  Add benefit
                                </Button>
                              </Flex>
                            );
                          }}
                        </FieldArray>
                        <Button
                          colorScheme='red'
                          borderRadius='6px'
                          onClick={() => {
                            perksKeys.splice(perkIndex, 1);
                            remove(perkIndex);
                          }}
                        >
                          Delete bonus {perkIndex + 1}
                        </Button>
                      </Stack>
                    ))}
                  <Button
                    borderRadius='6px'
                    variant='brand'
                    onClick={() => {
                      perksKeys.push(uniqueId());
                      perksBenefitsKeys.push([]);
                      push(emptyBonus);
                    }}
                  >
                    Add bonus
                  </Button>
                </Flex>
              )}
            </FieldArray>

            <Text color={textColor} fontSize='2xl' fontWeight='700' mt='40px' mb='20px'>
              Timeline
            </Text>
            <FieldArray name='milestones'>
              {({ push, remove }) => (
                <Flex
                  direction='column'
                  justifyContent='center'
                  gap='10px'
                  scrollMarginTop='180'
                  id={OfferingNavMenuItems.TIMELINE.value}
                >
                  {values.milestones?.length > 0 &&
                    values.milestones.map((milestone, milestoneIndex) => (
                      <Stack key={milestonesKeys[milestoneIndex]} direction='column' spacing='10px'>
                        <FormField
                          errors={errors}
                          index={milestoneIndex}
                          handleChange={handleChange}
                          name='name'
                          placeholder={`Input timeline name`}
                          label={`Timeline ${milestoneIndex + 1} name`}
                          arrayName='milestones'
                          defaultValue={milestone.name}
                        />
                        <FormField
                          errors={errors}
                          index={milestoneIndex}
                          handleChange={handleChange}
                          name='description'
                          placeholder={`Input timeline description`}
                          label={`Timeline ${milestoneIndex + 1} description`}
                          arrayName='milestones'
                          defaultValue={milestone.description}
                        />
                        <InputField
                          label={`Timeline ${milestoneIndex + 1} date`}
                          name={`milestones.${milestoneIndex}.date`}
                          onBlur={handleChange}
                          arrayname={`milestones[${milestoneIndex}]`}
                          type='date'
                          defaultValue={milestone.date ? convertTimeStampToDayDate(milestone.date) : ''}
                        />

                        <Button
                          colorScheme='red'
                          borderRadius='6px'
                          onClick={() => {
                            milestonesKeys.splice(milestoneIndex, 1);
                            remove(milestoneIndex);
                          }}
                        >
                          Delete timeline {milestoneIndex + 1}
                        </Button>
                      </Stack>
                    ))}
                  <Button
                    borderRadius='6px'
                    variant='brand'
                    onClick={() => {
                      milestonesKeys.push(uniqueId());
                      push(emptyBonus);
                    }}
                  >
                    Add timeline
                  </Button>
                </Flex>
              )}
            </FieldArray>

            <Text color={textColor} fontSize='2xl' fontWeight='700' mt='40px' mb='20px'>
              Information on card
            </Text>

            <FieldArray name='info'>
              {({ push, remove }) => (
                <Flex
                  direction='column'
                  scrollMarginTop='180'
                  justifyContent='center'
                  gap='20px'
                  id={OfferingNavMenuItems.INFORMATION.value}
                >
                  {values.info?.length > 0 &&
                    values.info.map((item, index) => (
                      <Stack key={infoKeys[index]} direction='column' spacing='10px'>
                        <FormField
                          errors={errors}
                          index={index}
                          handleChange={handleChange}
                          name='title'
                          placeholder='Name'
                          label={`Information ${index + 1} name`}
                          arrayName='info'
                          defaultValue={item.title}
                        />
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap='20px'>
                          <FormField
                            errors={errors}
                            index={index}
                            handleChange={handleChange}
                            name='value'
                            placeholder='Value'
                            label='Value'
                            arrayName='info'
                            defaultValue={item.value}
                          />
                          <FormField
                            errors={errors}
                            index={index}
                            handleChange={handleChange}
                            name='tooltip'
                            placeholder='Tooltip'
                            label='Tooptip'
                            arrayName='info'
                            defaultValue={item.tooltip}
                          />
                        </SimpleGrid>
                        <FormField
                          errors={errors}
                          index={index}
                          handleChange={handleChange}
                          name='icon'
                          placeholder='Icon name'
                          label='Icon name'
                          arrayName='info'
                          defaultValue={item.icon}
                        />
                        <Button
                          size='md'
                          borderRadius='10px'
                          colorScheme='red'
                          onClick={() => {
                            remove(index);
                            infoKeys.splice(index, 1);
                          }}
                        >
                          Delete information {index + 1}
                        </Button>
                      </Stack>
                    ))}

                  <Button
                    borderRadius='5px'
                    variant='brand'
                    onClick={() => {
                      infoKeys.push(uniqueId());
                      push(emptyInfo);
                    }}
                  >
                    Add information
                  </Button>
                </Flex>
              )}
            </FieldArray>

            <Text
              color={textColor}
              fontSize='2xl'
              fontWeight='700'
              mt='40px'
              mb='20px'
              id={OfferingNavMenuItems.DOCUMENTS.value}
              scrollMarginTop='180'
            >
              Documents
            </Text>
            <Flex direction='column' justifyContent='center'>
              <DocsFields sortable financeTypes documents={documents} setDocuments={setDocuments} />

              <Button borderRadius='6px' variant='brand' onClick={addDocument}>
                Add document
              </Button>
            </Flex>
          </form>
        );
      }}
    </Formik>
  );
}
