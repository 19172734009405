import { Icon } from '@chakra-ui/react';

import { BsBuildingFill } from 'react-icons/bs';
import { FaCoins, FaTable } from 'react-icons/fa';
import {
  MdHome,
  MdLock,
  MdMonetizationOn,
  MdOutlineSupervisorAccount,
  MdCardGiftcard,
  MdLocalOffer,
  MdOutlineRealEstateAgent,
} from 'react-icons/md';

import { PermissionEndpoints, PermissionLevels, RouteLayouts } from 'helpers/constants';
import AgentOffersList from 'views/admin/agentOffers/pages/AgentOffersList';
import CreateAgentOffer from 'views/admin/agentOffers/pages/CreateAgentOffer';
import CreateAgentOrder from 'views/admin/agentOffers/pages/CreateAgentOrder';
import AssetsList from 'views/admin/assets/pages/AssetList';
import EditAssetDocuments from 'views/admin/assets/pages/EditAssetDocuments';
import CreationDeveloper from 'views/admin/developers/pages/creationDeveloper';
import DevelopersList from 'views/admin/developers/pages/developerList';
import EditDeveloper from 'views/admin/developers/pages/editDeveloper';
import CreationFundraiser from 'views/admin/fundraisers/pages/creationFundraiser';
import EditFundraiser from 'views/admin/fundraisers/pages/editFundraiser';
import Fundraisers from 'views/admin/fundraisers/pages/fundraisersList';
import CreateGiftcards from 'views/admin/giftCards/pages/CreateGiftcards';
import GiftCardsList from 'views/admin/giftCards/pages/GiftCardsList';
import CreationItem from 'views/admin/offers/pages/creationItem';
import CreationOffer from 'views/admin/offers/pages/creationOffer';
import CreateProjectGoal from 'views/admin/offers/pages/creationProjectGoal';
import EditOfferingsCryptoAccounts from 'views/admin/offers/pages/editCryptoAccounts';
import EditItem from 'views/admin/offers/pages/editItem';
import EditOffer from 'views/admin/offers/pages/editOffer';
import EditProjectGoal from 'views/admin/offers/pages/editProjectGoal';
import ItemUpdates from 'views/admin/offers/pages/ItemUpdates/ItemUpdates';
import OfferItems from 'views/admin/offers/pages/offerItems';
import Offers from 'views/admin/offers/pages/offersList';
import ProjectGoalsList from 'views/admin/offers/pages/projectGoalsList';
import OrdersList from 'views/admin/orders/pages/OrderList';
import CreateTenant from 'views/admin/tenants/pages/ createTenant';
import EditTenant from 'views/admin/tenants/pages/editTenant';
import TenantList from 'views/admin/tenants/pages/tenantsList';
import EditInvestorCategories from 'views/admin/users/pages/editInvestorCategories';
import EditUserRoles from 'views/admin/users/pages/editUserRoles';
import Users from 'views/admin/users/pages/usersList';
import SignInCentered from 'views/auth/signIn/SignInCentered';

const routes: RoutesType[] = [
  {
    name: 'Assets',
    layout: RouteLayouts.ADMIN,
    path: '/assets',
    icon: <Icon as={FaCoins} width='20px' height='20px' color='inherit' />,
    collapse: true,
    permissions: {
      endpoint: PermissionEndpoints.ORDERS,
      levels: [
        PermissionLevels.VIEW,
        PermissionLevels.VIEW_OWN,
        PermissionLevels.CREATE,
        PermissionLevels.UPDATE,
        PermissionLevels.UPDATE_OWN,
      ],
    },
    items: [
      {
        name: 'Assets list',
        layout: RouteLayouts.ADMIN,
        path: '/assets/list',
        component: AssetsList,
        permissions: {
          endpoint: PermissionEndpoints.ORDERS,
          levels: [
            PermissionLevels.VIEW,
            PermissionLevels.VIEW_OWN,
            PermissionLevels.CREATE,
            PermissionLevels.UPDATE,
            PermissionLevels.UPDATE_OWN,
          ],
        },
      },
      {
        name: 'Asset documents',
        layout: RouteLayouts.ADMIN,
        hidden: true,
        path: '/assets/documents',
        component: EditAssetDocuments,
        permissions: {
          endpoint: PermissionEndpoints.ORDERS,
          levels: [PermissionLevels.UPDATE, PermissionLevels.UPDATE_OWN],
        },
      },
    ],
  },
  {
    name: 'Agent offers',
    layout: RouteLayouts.ADMIN,
    path: '/agent-offers',
    icon: <Icon as={MdOutlineRealEstateAgent} width='20px' height='20px' color='inherit' />,
    collapse: true,
    permissions: {
      endpoint: PermissionEndpoints.AGENT_OFFERS,
      levels: [
        PermissionLevels.VIEW,
        PermissionLevels.VIEW_OWN,
        PermissionLevels.UPDATE,
        PermissionLevels.UPDATE_OWN,
        PermissionLevels.CREATE,
        PermissionLevels.DELETE,
        PermissionLevels.DELETE_OWN,
      ],
    },
    items: [
      {
        name: 'Agent offers list',
        layout: RouteLayouts.ADMIN,
        path: '/agent-offers/list',
        component: AgentOffersList,
        permissions: {
          endpoint: PermissionEndpoints.AGENT_OFFERS,
          levels: [
            PermissionLevels.VIEW,
            PermissionLevels.VIEW_OWN,
            PermissionLevels.UPDATE,
            PermissionLevels.UPDATE_OWN,
            PermissionLevels.CREATE,
            PermissionLevels.DELETE,
            PermissionLevels.DELETE_OWN,
          ],
        },
      },
      {
        name: 'Create agent offer',
        layout: RouteLayouts.ADMIN,
        path: '/agent-offers/create/offer',
        hidden: true,
        component: CreateAgentOffer,
        permissions: {
          endpoint: PermissionEndpoints.AGENT_OFFERS,
          levels: [PermissionLevels.CREATE],
        },
      },
      {
        name: 'Create agent order',
        layout: RouteLayouts.ADMIN,
        path: '/agent-offers/create/order',
        hidden: true,
        component: CreateAgentOrder,
        permissions: {
          endpoint: PermissionEndpoints.ORDERS,
          levels: [PermissionLevels.CREATE],
        },
      },
    ],
  },
  {
    name: 'Developers',
    layout: RouteLayouts.ADMIN,
    path: '/developers',
    icon: <Icon as={BsBuildingFill} width='20px' height='20px' color='inherit' />,
    collapse: true,
    permissions: {
      endpoint: PermissionEndpoints.DEVELOPERS,
      levels: [
        PermissionLevels.VIEW,
        PermissionLevels.VIEW_OWN,
        PermissionLevels.CREATE,
        PermissionLevels.UPDATE,
        PermissionLevels.UPDATE_OWN,
      ],
    },
    items: [
      {
        name: 'Create developer',
        layout: RouteLayouts.ADMIN,
        path: '/developers/create',
        hidden: true,
        component: CreationDeveloper,
        permissions: {
          endpoint: PermissionEndpoints.DEVELOPERS,
          levels: [PermissionLevels.CREATE],
        },
      },
      {
        name: 'Developers list',
        layout: RouteLayouts.ADMIN,
        path: '/developers/list',
        component: DevelopersList,
        permissions: {
          endpoint: PermissionEndpoints.DEVELOPERS,
          levels: [
            PermissionLevels.VIEW,
            PermissionLevels.VIEW_OWN,
            PermissionLevels.CREATE,
            PermissionLevels.UPDATE,
            PermissionLevels.UPDATE_OWN,
          ],
        },
      },
      {
        name: 'Developer edit',
        layout: RouteLayouts.ADMIN,
        hidden: true,
        path: '/developers/edit',
        component: EditDeveloper,
        permissions: {
          endpoint: PermissionEndpoints.DEVELOPERS,
          levels: [PermissionLevels.UPDATE, PermissionLevels.UPDATE_OWN],
        },
      },
    ],
  },
  {
    name: 'Fundraisers',
    layout: RouteLayouts.ADMIN,
    path: '/fundraisers',
    icon: <Icon as={MdMonetizationOn} width='20px' height='20px' color='inherit' />,
    collapse: true,
    permissions: {
      endpoint: PermissionEndpoints.FUNDRAISER,
      levels: [
        PermissionLevels.VIEW,
        PermissionLevels.VIEW_OWN,
        PermissionLevels.CREATE,
        PermissionLevels.UPDATE,
        PermissionLevels.UPDATE_OWN,
      ],
    },
    items: [
      {
        name: 'Create fundraiser',
        layout: RouteLayouts.ADMIN,
        path: '/fundraisers/create',
        hidden: true,
        component: CreationFundraiser,
        permissions: {
          endpoint: PermissionEndpoints.FUNDRAISER,
          levels: [PermissionLevels.CREATE],
        },
      },
      {
        name: 'Fundraisers list',
        layout: RouteLayouts.ADMIN,
        path: '/fundraisers/list',
        component: Fundraisers,
        permissions: {
          endpoint: PermissionEndpoints.FUNDRAISER,
          levels: [
            PermissionLevels.VIEW,
            PermissionLevels.VIEW_OWN,
            PermissionLevels.CREATE,
            PermissionLevels.UPDATE,
            PermissionLevels.UPDATE_OWN,
          ],
        },
      },
      {
        name: 'Fundraiser edit',
        layout: RouteLayouts.ADMIN,
        hidden: true,
        path: '/fundraisers/edit',
        component: EditFundraiser,
        permissions: {
          endpoint: PermissionEndpoints.FUNDRAISER,
          levels: [PermissionLevels.UPDATE, PermissionLevels.UPDATE_OWN],
        },
      },
    ],
  },

  {
    name: 'Gift Cards',
    layout: RouteLayouts.ADMIN,
    path: '/giftcards',
    icon: <Icon as={MdCardGiftcard} width='20px' height='20px' color='inherit' />,
    collapse: true,
    permissions: {
      endpoint: PermissionEndpoints.GIFTCARDS,
      levels: [PermissionLevels.VIEW, PermissionLevels.VIEW_OWN, PermissionLevels.CREATE],
    },
    items: [
      {
        name: 'Gift Cards list',
        layout: RouteLayouts.ADMIN,
        path: '/giftcards/list',
        component: GiftCardsList,
        permissions: {
          endpoint: PermissionEndpoints.GIFTCARDS,
          levels: [PermissionLevels.VIEW, PermissionLevels.VIEW_OWN, PermissionLevels.CREATE],
        },
      },
      {
        name: 'Generate giftcards',
        layout: RouteLayouts.ADMIN,
        path: '/giftcards/create',
        component: CreateGiftcards,
        permissions: {
          endpoint: PermissionEndpoints.GIFTCARDS,
          levels: [PermissionLevels.CREATE],
        },
      },
    ],
  },
  {
    name: 'Offers',
    layout: RouteLayouts.ADMIN,
    path: '/offers',
    icon: <Icon as={MdLocalOffer} width='20px' height='20px' color='inherit' />,
    collapse: true,
    permissions: {
      endpoint: PermissionEndpoints.OFFERINGS,
      levels: [
        PermissionLevels.VIEW,
        PermissionLevels.VIEW_OWN,
        PermissionLevels.CREATE,
        PermissionLevels.UPDATE,
        PermissionLevels.UPDATE_OWN,
      ],
    },
    items: [
      {
        name: 'Create project goal',
        layout: RouteLayouts.ADMIN,
        path: '/offers/project-goals/create',
        component: CreateProjectGoal,
        hidden: true,
        permissions: {
          endpoint: PermissionEndpoints.OFFERINGS,
          levels: [PermissionLevels.CREATE],
        },
      },
      {
        name: 'Edit project goal',
        layout: RouteLayouts.ADMIN,
        hidden: true,
        path: '/offers/project-goals/edit',
        component: EditProjectGoal,
        permissions: {
          endpoint: PermissionEndpoints.OFFERINGS,
          levels: [PermissionLevels.UPDATE, PermissionLevels.UPDATE_OWN],
        },
      },
      {
        name: 'Create offer',
        layout: RouteLayouts.ADMIN,
        path: '/offers/create',
        component: CreationOffer,
        hidden: true,
        permissions: {
          endpoint: PermissionEndpoints.OFFERINGS,
          levels: [PermissionLevels.CREATE],
        },
      },
      {
        name: 'Offers list',
        layout: RouteLayouts.ADMIN,
        path: '/offers/list',
        component: Offers,
        permissions: {
          endpoint: PermissionEndpoints.OFFERINGS,
          levels: [
            PermissionLevels.VIEW,
            PermissionLevels.VIEW_OWN,
            PermissionLevels.CREATE,
            PermissionLevels.UPDATE,
            PermissionLevels.UPDATE_OWN,
          ],
        },
      },
      {
        name: 'Project goals list',
        layout: RouteLayouts.ADMIN,
        path: '/offers/project-goals/list',
        component: ProjectGoalsList,
        permissions: {
          endpoint: PermissionEndpoints.OFFERINGS,
          levels: [PermissionLevels.CREATE],
        },
      },

      {
        name: 'Offers edit',
        layout: RouteLayouts.ADMIN,
        hidden: true,
        path: '/offers/edit',
        component: EditOffer,
        permissions: {
          endpoint: PermissionEndpoints.OFFERINGS,
          levels: [PermissionLevels.UPDATE, PermissionLevels.UPDATE_OWN],
        },
      },
      {
        name: 'Item creation',
        layout: RouteLayouts.ADMIN,
        hidden: true,
        path: '/offers/item/create',
        component: CreationItem,
        permissions: {
          endpoint: PermissionEndpoints.OFFERINGS,
          levels: [PermissionLevels.CREATE],
        },
      },
      {
        name: 'Details edit',
        layout: RouteLayouts.ADMIN,
        hidden: true,
        path: '/offers/item/edit',
        component: EditItem,
        permissions: {
          endpoint: PermissionEndpoints.OFFERINGS,
          levels: [PermissionLevels.UPDATE, PermissionLevels.UPDATE_OWN],
        },
      },
      {
        name: 'Item updates',
        layout: RouteLayouts.ADMIN,
        hidden: true,
        path: '/offers/item/updates',
        component: ItemUpdates,
        permissions: {
          endpoint: PermissionEndpoints.OFFERINGS,
          levels: [PermissionLevels.UPDATE, PermissionLevels.UPDATE_OWN],
        },
      },
      {
        name: 'Offer items',
        layout: RouteLayouts.ADMIN,
        hidden: true,
        path: '/offers/items',
        component: OfferItems,
        permissions: {
          endpoint: PermissionEndpoints.OFFERINGS,
          levels: [PermissionLevels.VIEW],
        },
      },
      {
        name: 'Edit offers cryptoaccounts',
        layout: RouteLayouts.ADMIN,
        hidden: true,
        path: '/offers/crypto-accounts/edit',
        component: EditOfferingsCryptoAccounts,
        permissions: {
          endpoint: PermissionEndpoints.OFFERINGS,
          levels: [PermissionLevels.UPDATE, PermissionLevels.UPDATE_OWN],
        },
      },
    ],
  },
  {
    name: 'Orders',
    layout: RouteLayouts.ADMIN,
    path: '/orders',
    icon: <Icon as={FaTable} width='20px' height='20px' color='inherit' />,
    collapse: true,
    permissions: {
      endpoint: PermissionEndpoints.ORDERS,
      levels: [
        PermissionLevels.VIEW,
        PermissionLevels.VIEW_OWN,
        PermissionLevels.UPDATE,
        PermissionLevels.UPDATE_OWN,
        PermissionLevels.CREATE,
        PermissionLevels.DELETE,
        PermissionLevels.DELETE_OWN,
      ],
    },
    items: [
      {
        name: 'Orders list',
        layout: RouteLayouts.ADMIN,
        path: '/orders/list',
        component: OrdersList,
        permissions: {
          endpoint: PermissionEndpoints.ORDERS,
          levels: [
            PermissionLevels.VIEW,
            PermissionLevels.VIEW_OWN,
            PermissionLevels.UPDATE,
            PermissionLevels.UPDATE_OWN,
            PermissionLevels.CREATE,
            PermissionLevels.DELETE,
            PermissionLevels.DELETE_OWN,
          ],
        },
      },
    ],
  },
  {
    name: 'Tenants',
    layout: RouteLayouts.ADMIN,
    path: '/tenants',
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    collapse: true,
    permissions: {
      endpoint: PermissionEndpoints.TENANT,
      levels: [
        PermissionLevels.VIEW,
        PermissionLevels.VIEW_OWN,
        PermissionLevels.CREATE,
        PermissionLevels.UPDATE,
        PermissionLevels.UPDATE_OWN,
      ],
    },
    items: [
      {
        name: 'Create tenant',
        layout: RouteLayouts.ADMIN,
        path: '/tenants/create',
        component: CreateTenant,
        hidden: true,
        permissions: {
          endpoint: PermissionEndpoints.TENANT,
          levels: [PermissionLevels.CREATE],
        },
      },
      {
        name: 'Tenants list',
        layout: RouteLayouts.ADMIN,
        path: '/tenants/list',
        component: TenantList,
        permissions: {
          endpoint: PermissionEndpoints.TENANT,
          levels: [
            PermissionLevels.VIEW,
            PermissionLevels.VIEW_OWN,
            PermissionLevels.CREATE,
            PermissionLevels.UPDATE,
            PermissionLevels.UPDATE_OWN,
          ],
        },
      },
      {
        name: 'Edit tenant',
        layout: RouteLayouts.ADMIN,
        hidden: true,
        path: '/tenants/edit',
        component: EditTenant,
        permissions: {
          endpoint: PermissionEndpoints.TENANT,
          levels: [PermissionLevels.UPDATE, PermissionLevels.UPDATE_OWN],
        },
      },
    ],
  },
  {
    name: 'Users',
    layout: RouteLayouts.ADMIN,
    path: '/users',
    icon: <Icon as={MdOutlineSupervisorAccount} width='20px' height='20px' color='inherit' />,
    collapse: true,
    permissions: {
      endpoint: PermissionEndpoints.USER,
      levels: [PermissionLevels.VIEW, PermissionLevels.VIEW_OWN, PermissionLevels.UPDATE, PermissionLevels.UPDATE_OWN],
    },
    items: [
      {
        name: 'Users list',
        layout: RouteLayouts.ADMIN,
        path: '/users/list',
        component: Users,
        permissions: {
          endpoint: PermissionEndpoints.USER,
          levels: [
            PermissionLevels.VIEW,
            PermissionLevels.VIEW_OWN,
            PermissionLevels.UPDATE,
            PermissionLevels.UPDATE_OWN,
          ],
        },
      },
      {
        name: 'Edit investor categories',
        layout: RouteLayouts.ADMIN,
        hidden: true,
        path: '/users/investor-categories/edit',
        component: EditInvestorCategories,
        permissions: {
          endpoint: PermissionEndpoints.USER,
          levels: [PermissionLevels.UPDATE, PermissionLevels.UPDATE_OWN],
        },
      },
      {
        name: 'Edit user roles',
        layout: RouteLayouts.ADMIN,
        hidden: true,
        path: '/users/user-roles/edit',
        component: EditUserRoles,
        permissions: {
          endpoint: PermissionEndpoints.USER,
          levels: [PermissionLevels.UPDATE, PermissionLevels.UPDATE_OWN],
        },
      },
    ],
  },

  // --- Authentication ---
  {
    name: 'Authentication',
    path: '/auth',
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    collapse: true,
    hidden: true,
    items: [
      {
        name: 'Sign In',
        layout: RouteLayouts.AUTH,
        path: '/sign-in/default',
        component: SignInCentered,
      },
    ],
  },
];

export default routes;
