/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Flex, Spinner, Text } from '@chakra-ui/react';

import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { assets } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';

import LeaveConfirmation from 'components/common/LeaveConfirmation';
import { AssetDocumentsStatus, LoadingStatus, OfferingDocumentsType } from 'helpers/constants';
import useDownloadData from 'hooks/useDownloadData';
import { useLeaveConfirmation } from 'hooks/useLeaveConfirmation';
import { THistory, TLocation } from 'types';
import DocsFields, { DocsFieldsDocument } from 'views/admin/offers/components/DocsFields';

export default function AssetDocuments() {
  const [loadDocumensStatus, setLoadDocumensStatus] = useState<LoadingStatus>(LoadingStatus.NONE);

  const [assetDocumets, getAssetDocumetsStatus, getAssetDocumets] = useDownloadData(assets.documents.getAll);

  const [tableDocs, setTableDocs] = useState<DocsFieldsDocument[]>([]);

  const location = useLocation<TLocation>();
  const params = new URLSearchParams(location.search);

  const asset_id = params.get('assetId');
  const history = useHistory<THistory>();
  const { disableLeaveConfirmation } = useLeaveConfirmation();

  const getEmptyDocument = (): DocsFieldsDocument => ({
    external_id: uniqueId(),
    title: '',
    responsefile: {},
    doc_loading_status: LoadingStatus.NONE,
    type: OfferingDocumentsType.PERSONAL,
  });

  const convertAssetDocsToOfferDocs = (docs: API.Admin.Assets.Document.Response[]): DocsFieldsDocument[] =>
    docs.map(doc => ({
      id: doc.id,
      title: doc.name,
      type: OfferingDocumentsType.PERSONAL,
      responsefile: { url: doc.url },
      doc_loading_status: LoadingStatus.FULFILLED,
      personal_doc_status: doc.status,
    }));

  const convertOfferDocsToAssetDocs = (docs: DocsFieldsDocument[]) =>
    docs.map(doc => ({
      id: doc.id,
      name: doc.title,
      assets_id: +asset_id,
      url: doc.responsefile?.url,
      status: doc.personal_doc_status || AssetDocumentsStatus.NEW,
    }));

  const addDocument = () => setTableDocs(prevDocuments => [...prevDocuments, getEmptyDocument()]);

  const closeForm = () => {
    if (!tableDocs.length && !assetDocumets.length) {
      disableLeaveConfirmation();
    }
    setTimeout(() =>
      history.push({
        pathname: `/admin/assets/list`,
      })
    );
  };

  const submitForm = async () => {
    const promises = [];
    const documents = convertOfferDocsToAssetDocs(tableDocs);
    const documentsForUpdate = documents.filter(doc => doc.id);
    const documentsForCreate = documents.filter(doc => !doc.id);
    const documentsForDelete = assetDocumets.filter(
      assetDoc => !tableDocs.find(tableDoc => tableDoc.id === assetDoc.id)
    );

    if (documentsForUpdate.length) {
      promises.push(assets.documents.update(+asset_id, { items: documentsForUpdate }));
    }

    if (documentsForCreate.length) {
      promises.push(assets.documents.create(+asset_id, { items: documentsForCreate }));
    }

    if (documentsForDelete.length) {
      documentsForDelete.forEach(doc => {
        promises.push(assets.documents.delete(String(doc.id)));
      });
    }

    try {
      setLoadDocumensStatus(LoadingStatus.PENDING);
      await Promise.all(promises);
      disableLeaveConfirmation();
      history.push({
        pathname: `/admin/assets/list`,
      });
      setLoadDocumensStatus(LoadingStatus.FULFILLED);
    } catch (error) {
      setLoadDocumensStatus(LoadingStatus.REJECTED);
      throw error;
    }
  };

  const isDocumentsLoading = getAssetDocumetsStatus === LoadingStatus.PENDING;
  const isDocumentsPending = loadDocumensStatus === LoadingStatus.PENDING;

  useEffect(() => {
    getAssetDocumets(asset_id);
  }, [asset_id]);

  useEffect(() => {
    if (getAssetDocumetsStatus === LoadingStatus.FULFILLED) {
      setTableDocs(convertAssetDocsToOfferDocs(assetDocumets));
    }
  }, [getAssetDocumetsStatus]);

  if (isDocumentsLoading) {
    return (
      <Flex w='100%' h='500px' justifyContent='center' alignItems='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brand.500' size='xl' />
      </Flex>
    );
  }

  return (
    <Card p='30px'>
      <LeaveConfirmation />
      {tableDocs.length > 0 ? (
        <Flex direction='column' justifyContent='center'>
          <DocsFields assetDocStatuses documents={tableDocs} setDocuments={setTableDocs} />
        </Flex>
      ) : (
        <Text marginBottom='50'>No active docs</Text>
      )}
      <Button borderRadius='6px' variant='brand' onClick={addDocument}>
        Add document
      </Button>

      <Flex direction='column' w='100%'>
        <Flex gap='4' mt='24px' justifyContent='end'>
          <Button
            isLoading={isDocumentsPending}
            variant='outline'
            fontSize='sm'
            borderRadius='16px'
            onClick={closeForm}
          >
            Close
          </Button>
          <Button
            isLoading={isDocumentsPending}
            variant='darkBrand'
            fontSize='sm'
            borderRadius='16px'
            onClick={submitForm}
          >
            Save changes
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
