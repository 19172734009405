import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { offerings } from 'api/admin';
import { LoadingStatus } from 'helpers/constants';

interface ChangeOfferStatusProps {
  uuid: string;
  status: string;
}
interface CounterState {
  offers: any[];
  status: LoadingStatus;
}

const initialState: CounterState = {
  offers: [],
  status: LoadingStatus.NONE,
};

export const getOffers = createAsyncThunk('offers/list', async () => {
  const res = await offerings.getAll();
  return res.data;
});

export const deleteOffer = createAsyncThunk('offers/delete', async (uuid: string) => {
  await offerings.delete(uuid);
  const res = await offerings.getAll();
  return res.data;
});

export const changeOfferStatus = createAsyncThunk(
  'offers/changeStatus',
  async ({ uuid, status }: ChangeOfferStatusProps) => {
    await offerings.changeStatus(uuid, status);
    const res = await offerings.getAll();
    return res.data;
  }
);

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOffers.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(getOffers.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.offers = action.payload;
      })
      .addCase(getOffers.rejected, state => {
        state.status = LoadingStatus.REJECTED;
        state.offers = [];
      })
      .addCase(changeOfferStatus.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(changeOfferStatus.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.offers = action.payload;
      })
      .addCase(changeOfferStatus.rejected, state => {
        state.status = LoadingStatus.REJECTED;
        state.offers = [];
      })
      .addCase(deleteOffer.pending, state => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(deleteOffer.fulfilled, (state, action) => {
        state.status = LoadingStatus.FULFILLED;
        state.offers = action.payload;
      })
      .addCase(deleteOffer.rejected, state => {
        state.status = LoadingStatus.REJECTED;
        state.offers = [];
      });
  },
});

// export const {} = offersSlice.actions;

export default offersSlice.reducer;
