import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC, useRef } from 'react';

import { OfferingStatus, offeringStatusesInfo } from 'helpers/constants';

type ChangeOfferAddressModalProps = {
  isChangeStatusModalOpen: boolean;
  closeChangeStatusModal: () => void;
  changeStatus: () => void;
  targetStatus: OfferingStatus;
};

const ChangeOfferAddressModal: FC<ChangeOfferAddressModalProps> = props => {
  const initialRef = useRef(null);
  const { isChangeStatusModalOpen, closeChangeStatusModal, changeStatus, targetStatus } = props;

  return (
    <Modal initialFocusRef={initialRef} isOpen={isChangeStatusModalOpen} onClose={closeChangeStatusModal} isCentered>
      <ModalOverlay bg='none' backdropFilter='auto' backdropBlur='10px' />
      <ModalContent>
        <ModalHeader>Change status confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want change status to {offeringStatusesInfo[targetStatus]?.name}? It's an irreversible
          process
        </ModalBody>
        <ModalFooter>
          <Button ref={initialRef} variant='outline' mr={3} onClick={closeChangeStatusModal}>
            Close
          </Button>
          <Button colorScheme={targetStatus !== OfferingStatus.CANCELED ? 'green' : 'red'} onClick={changeStatus}>
            Change status
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangeOfferAddressModal;
