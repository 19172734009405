/* eslint-disable */

import {
  Avatar,
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Badge,
  Checkbox,
  useToast,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getSortedRowModel,
  getCoreRowModel,
  useReactTable,
  SortingState,
} from '@tanstack/react-table';
// Custom components
import Card from 'components/card/Card';
import { CSVLink } from 'react-csv';

import { MdOutlineBadge, MdOutlineLoyalty, MdTouchApp } from 'react-icons/md';
import { useHistory } from 'react-router';
import { format } from 'date-fns';
import {
  defaultTableRowCounts,
  KYCStatus,
  kycStatusesInfo,
  LoadingStatus,
  ReferralStatus,
  refStatusesInfo,
  SortOrder,
} from 'helpers/constants';
import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import BasePreloader from 'components/preloader/BasePreloader';
import { useAppSelector } from 'store/store';
import { UsersStore } from 'store/users';
import { selectInvestorCategories, selectUserRoles } from 'store/selectors';
import { FaFlagUsa, FaUserLock } from 'react-icons/fa';
import { API } from 'api/types';
import ChangeKYCStatusModal from './modals/ChangeKYCStatusModal';
import ChangeReferralStatusModal from './modals/ChangeReferralStatusModal';
import ReactPaginate from 'react-paginate';
import { ReactPaginateChangePageEvent } from 'types';
import { ArrowDownIcon, ArrowUpIcon, CloseIcon, DownloadIcon, ExternalLinkIcon, SearchIcon } from '@chakra-ui/icons';
import { debounce, omit } from 'lodash';
import usePermissions from 'hooks/usePermissions';
import OverlayPreloader from 'components/preloader/OverlayPreloader';
import { user } from 'api/admin';

const columnHelper = createColumnHelper<API.Admin.User.Item>();

type UsersTableProps = {
  tableData: UsersStore;
  requestParams: API.Pagination.Request;
  setRequestParams: React.Dispatch<React.SetStateAction<API.Pagination.Request>>;
  changeRefferalStatus: (selectedUser: API.Admin.User.Item, targetRefferalStatus: number) => Promise<void>;
  changeKYCStatus: (selectedUser: API.Admin.User.Item, kyc_status: KYCStatus) => Promise<void>;
};

const UsersTable: FC<UsersTableProps> = props => {
  const { tableData, setRequestParams, changeRefferalStatus, changeKYCStatus, requestParams } = props;
  const { meta, items, status } = tableData;
  const history = useHistory();
  const searchRef = useRef(null);
  const { currentRouteUserPermissions } = usePermissions();
  const { canUpdate } = currentRouteUserPermissions;
  const isActionsAvailable = canUpdate;

  const downloadReferralRef = useRef(null);

  const toast = useToast();

  const [selectedUser, setSelectedUser] = useState<API.Admin.User.Item>(null);
  const [selectedUserReferrals, setSelectedUserReferrals] = useState<API.Admin.User.Referrals.Item[] | null>(null);
  const [actionStatus, setActionStatus] = useState<LoadingStatus>(LoadingStatus.NONE);
  const [isKYCPopupOpen, setIsKYCPopupOpen] = useState(false);
  const [isRefPopupOpen, setIsRefPopupOpen] = useState(false);
  const [targetKYCStatus, setTargetKYCStatus] = useState<KYCStatus>(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [targetRefferalStatus, setTargetRefferalStatus] = useState(null);

  const investorCategories = useAppSelector(selectInvestorCategories);
  const userRoles = useAppSelector(selectUserRoles);
  const isUsersPending = status === LoadingStatus.PENDING;
  const isActionsPending = actionStatus === LoadingStatus.PENDING;
  const downloadData = items.map(user => ({
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    phone: user.phone,
    email_verified: user.email_verified,
    phone_verified: user.phone_verified,
    kyc_status: user.kyc_status,
    ref_tier_id: ReferralStatus[user.ref_tier_id],
    created_at: format(new Date(user.created_at), 'yyyy-MM-dd'),
    investor_categories_id: user.investor_categories_id.map(categoryId =>
      investorCategories.find(category => category.id === categoryId)?.category_name.toUpperCase()
    ),
    user_roles: user.user_roles_id.map(roleId => userRoles.find(role => role.id === roleId)?.name),
  }));

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const openChangeKYCStatusModal = (user: API.Admin.User.Item) => {
    setSelectedUser(user);
    setIsKYCPopupOpen(true);
  };

  const openChangeRefStatusModal = (user: API.Admin.User.Item) => {
    setSelectedUser(user);
    setIsRefPopupOpen(true);
  };

  const closeChangeKYCStatusModal = () => {
    setSelectedUser(null);
    setTargetKYCStatus(null);
    setIsKYCPopupOpen(false);
  };

  const closeChangeRefStatusModal = () => {
    setSelectedUser(null);
    setTargetRefferalStatus(null);
    setIsRefPopupOpen(false);
  };

  const loadUserReferrals = async (userData: API.Admin.User.Item) => {
    setSelectedUser(userData);
    try {
      setActionStatus(LoadingStatus.PENDING);
      const { data } = await user.referrals.getBuUserId(userData.id);
      setSelectedUserReferrals(data.map(referral => omit(referral, ['avatar'])));
      setActionStatus(LoadingStatus.FULFILLED);
      downloadReferralRef.current.link.click();
    } catch (e) {
      setActionStatus(LoadingStatus.REJECTED);
      throw e;
    } finally {
      setSelectedUserReferrals(null);
      setSelectedUser(null);
    }
  };

  const changeKYCTargetStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setTargetKYCStatus(value as KYCStatus);
  };

  const changeRefTargetStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setTargetRefferalStatus(Number(value));
  };

  const changeKYCStatusHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setActionStatus(LoadingStatus.PENDING);
    try {
      await changeKYCStatus(selectedUser, targetKYCStatus);
      setActionStatus(LoadingStatus.FULFILLED);
      closeChangeKYCStatusModal();
      toast({
        title: `Status has been successfully changed`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
    } catch {
      setActionStatus(LoadingStatus.REJECTED);
    }
  };

  const changeRefStatusHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setActionStatus(LoadingStatus.PENDING);
    try {
      await changeRefferalStatus(selectedUser, targetRefferalStatus);
      setActionStatus(LoadingStatus.FULFILLED);
      closeChangeKYCStatusModal();
      toast({
        title: `Referral status has been successfully changed`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
    } catch {
      setActionStatus(LoadingStatus.REJECTED);
    }
  };

  const editInvestorCategories = (userId: number) => {
    history.push({
      pathname: `/admin/users/investor-categories/edit`,
      search: `?userId=${userId}`,
    });
  };

  const editUserRoles = (userId: number) => {
    history.push({
      pathname: `/admin/users/user-roles/edit`,
      search: `?userId=${userId}`,
    });
  };

  const updateSearchParams = useCallback(function () {
    setRequestParams(prevRequestParams => ({ ...prevRequestParams, search: searchRef.current.value }));
  }, []);

  const changeSearch = debounce(updateSearchParams, 500);
  const clearSearchParams = () => {
    searchRef.current.value = '';
    updateSearchParams();
  };

  const changePage = (e: ReactPaginateChangePageEvent) => {
    setRequestParams(prevRequestParams => ({ ...prevRequestParams, page: e.selected + 1 }));
  };

  const changeSorting = () => {
    const sort_by = sorting[0].id;
    const sort_order = sorting[0].desc ? SortOrder.DESC : SortOrder.ASC;
    setRequestParams(prevRequestParams => ({
      ...prevRequestParams,
      sort_by,
      sort_order,
    }));
  };

  const changeUsersPerPage = (e: ChangeEvent<HTMLSelectElement>) => {
    setRequestParams(prevRequestParams => ({ ...prevRequestParams, per_page: Number(e.target.value) }));
  };

  const columns = [
    columnHelper.accessor('name', {
      id: 'first_name',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          User name
        </Text>
      ),
      cell: info => (
        <Flex alignItems='center'>
          <Avatar size='lg' name='Offer image' mr='14px' src={info.row?.original?.avatar?.url} />
          {info.row.original?.first_name && (
            <Text color={textColor} fontSize='sm' fontWeight='600'>
              {info.row.original?.first_name} {info.row.original?.last_name}
            </Text>
          )}
        </Flex>
      ),
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Contacts
        </Text>
      ),
      cell: info => (
        <Flex flexDirection='column'>
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.row.original.email}
          </Text>
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.row.original.phone}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('email_verified', {
      id: 'email_verified',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Verified
        </Text>
      ),
      cell: info => (
        <Flex flexDirection='column'>
          <Checkbox
            color={textColor}
            fontSize='sm'
            fontWeight='600'
            disabled
            defaultChecked={info.row.original.email_verified}
          >
            email
          </Checkbox>
          <Checkbox
            color={textColor}
            fontSize='sm'
            fontWeight='600'
            disabled
            defaultChecked={info.row.original.phone_verified}
          >
            phone
          </Checkbox>
        </Flex>
      ),
    }),
    columnHelper.accessor('kyc_status', {
      id: 'kyc_status',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          KYC
        </Text>
      ),
      cell: info => (
        <Badge
          colorScheme={kycStatusesInfo[info.getValue()]?.theme}
          color={kycStatusesInfo[info.getValue()]?.fontColor}
          fontSize={kycStatusesInfo[info.getValue()]?.fontSize}
          fontWeight={kycStatusesInfo[info.getValue()]?.fontWeight}
          minW={'120px'}
          textAlign='center'
        >
          {info.getValue().toLowerCase()}
        </Badge>
      ),
    }),
    columnHelper.accessor('ref_tier_id', {
      id: 'ref_tier_id',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Referral program
        </Text>
      ),
      cell: info =>
        ReferralStatus[info.getValue()] ? (
          <Badge
            fontWeight={refStatusesInfo[ReferralStatus[info.getValue()]]?.fontWeight}
            fontSize={refStatusesInfo[ReferralStatus[info.getValue()]]?.fontSize}
            backgroundImage={refStatusesInfo[ReferralStatus[info.getValue()]]?.backgroundGradient}
            minW={'120px'}
            textAlign='center'
          >
            {ReferralStatus[info.getValue()]}
          </Badge>
        ) : null,
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Created
        </Text>
      ),
      cell: info => (
        <Text color={textColor} whiteSpace='nowrap' fontSize='sm' fontWeight='600'>
          {format(new Date(info.getValue()), 'yyyy-MM-dd')}
        </Text>
      ),
    }),
    columnHelper.display({
      id: 'investor_categories_id',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Investor categories
        </Text>
      ),
      cell: info => (
        <Flex
          flexDirection='column'
          color={textColor}
          fontSize='sm'
          fontWeight='600'
          maxWidth='300px'
          wordBreak='keep-all'
        >
          {info.row.original.investor_categories_id.length > 0
            ? info.row.original.investor_categories_id.map((categoryId, index) => (
                <Text whiteSpace='nowrap' key={index}>
                  {investorCategories.find(category => category.id === categoryId)?.category_name.toUpperCase()}
                </Text>
              ))
            : 'n/a'}
        </Flex>
      ),
    }),
    columnHelper.display({
      id: 'user_roles_id',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Roles
        </Text>
      ),
      cell: info => (
        <Flex flexDirection='column' color={textColor} fontSize='sm' fontWeight='600'>
          {[...new Set(info.row.original.user_roles_id)].map((roleId, index) => (
            <Text whiteSpace='nowrap' key={index}>
              {userRoles.find(role => role.id === roleId)?.name}
            </Text>
          ))}
        </Flex>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Actions
        </Text>
      ),
      cell: info => {
        const user = info.row.original;
        const isCurrentActionPending = isActionsPending && selectedUser?.id === user.id;
        return (
          isActionsAvailable && (
            <Menu>
              <MenuButton
                as={Button}
                leftIcon={isCurrentActionPending ? <Spinner size='xs' mr='0.5' /> : <MdTouchApp />}
                fontSize='14px'
                textTransform='uppercase'
                colorScheme='blue'
                bgColor='blue.400'
                h='28px'
                borderRadius='6px'
                disabled={isCurrentActionPending}
              >
                Actions
              </MenuButton>
              <MenuList minWidth='0px' fontSize='large' padding='10px'>
                {canUpdate && (
                  <>
                    <MenuItem icon={<MdOutlineBadge />} onClick={() => openChangeKYCStatusModal(user)}>
                      Change KYC status
                    </MenuItem>
                    <MenuItem icon={<MdOutlineLoyalty />} onClick={() => openChangeRefStatusModal(user)}>
                      Change referral program status
                    </MenuItem>
                    <MenuItem icon={<FaFlagUsa />} onClick={() => editInvestorCategories(user.id)}>
                      Change investor categories
                    </MenuItem>
                    <MenuItem icon={<FaUserLock />} onClick={() => editUserRoles(user.id)}>
                      Change user roles
                    </MenuItem>
                    <MenuItem icon={<DownloadIcon />} onClick={() => loadUserReferrals(user)}>
                      Download user referrals
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          )
        );
      },
    }),
  ];

  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    pageCount: meta.pageTotal,
    manualSorting: true,
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useEffect(() => {
    sorting.length && changeSorting();
  }, [sorting]);

  return (
    <>
      <Card mt='20px'>
        <Flex direction='column' w='100%' overflowX={{ sm: 'auto', lg: 'auto' }}>
          <Box overflowX='auto' mb='40px' position='relative'>
            {isUsersPending && <OverlayPreloader />}

            <Flex px='5' alignItems='center' justifyContent='space-between'>
              <InputGroup w='xs' p='5'>
                <Input type='tel' placeholder='Search by name/email' ref={searchRef} onChange={changeSearch} />
                <InputRightElement m='5'>
                  {!!searchRef.current?.value ? (
                    <CloseIcon onClick={clearSearchParams} cursor='pointer' color='gray.300' />
                  ) : (
                    <SearchIcon color='gray.300' />
                  )}
                </InputRightElement>
              </InputGroup>
              <Flex alignItems='center'>
                <Button colorScheme='blue' variant='outline' rounded='md' fontWeight='normal'>
                  <CSVLink data={downloadData} filename={`users-${format(new Date(), 'yyyy-MM-dd')}.csv`}>
                    Export <ExternalLinkIcon />
                  </CSVLink>
                </Button>
              </Flex>
            </Flex>
            <Table variant='simple' color='gray.500' mt='12px' minWidth='800'>
              <Thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers?.map(header => {
                      return (
                        <Th key={header.id} colSpan={header.colSpan} pe='10px' borderColor={borderColor}>
                          <Flex
                            justifyContent='space-between'
                            align='center'
                            fontSize={{ sm: '10px', lg: '12px' }}
                            color={header.column.getCanSort() ? 'pointer' : 'gray.400'}
                            onClick={header.column?.getToggleSortingHandler()}
                            cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: <ArrowUpIcon />,
                              desc: <ArrowDownIcon />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </Flex>
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map(row => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderColor='transparent'
                          >
                            {flexRender(cell.column?.columnDef?.cell, cell.getContext())}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          {meta.pageTotal > 1 && (
            <Flex justifyContent='center' alignItems='center' gap='10px'>
              <ReactPaginate
                breakLabel='...'
                nextLabel='next page'
                onPageChange={changePage}
                pageRangeDisplayed={3}
                pageCount={meta.pageTotal}
                previousLabel='prev page'
                renderOnZeroPageCount={null}
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='prev-button'
                previousLinkClassName='page-link'
                nextClassName='next-button'
                nextLinkClassName='page-link'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
              />

              <Select color='blue.400' w='80px' onChange={changeUsersPerPage} defaultValue={requestParams.per_page}>
                {defaultTableRowCounts.map((count, index) => (
                  <option key={index} value={count}>
                    {count}
                  </option>
                ))}
              </Select>
            </Flex>
          )}
        </Flex>

        <ChangeKYCStatusModal
          isOpen={isKYCPopupOpen}
          loadingStatus={actionStatus}
          changeTargetStatus={changeKYCTargetStatus}
          changeStatusHandler={changeKYCStatusHandler}
          onClose={closeChangeKYCStatusModal}
          currentStatus={selectedUser?.kyc_status}
        />
        <ChangeReferralStatusModal
          isOpen={isRefPopupOpen}
          loadingStatus={actionStatus}
          changeTargetStatus={changeRefTargetStatus}
          changeStatusHandler={changeRefStatusHandler}
          currentStatus={selectedUser?.ref_tier_id}
          onClose={closeChangeRefStatusModal}
        />
      </Card>
      {selectedUserReferrals && (
        <CSVLink
          data={selectedUserReferrals}
          ref={downloadReferralRef}
          filename={`${selectedUser.email}-referrals-${format(new Date(), 'yyyy-MM-dd')}.csv`}
        />
      )}
    </>
  );
};

export default UsersTable;
