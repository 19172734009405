import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Badge,
  useToast,
} from '@chakra-ui/react';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { format } from 'date-fns';
import { useState } from 'react';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { MdDelete, MdOutlineContentCopy, MdTouchApp } from 'react-icons/md';

import { giftcards } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';
import DeleteConfirmation from 'components/dialogs/DeleteConfirmation';

import BasePreloader from 'components/preloader/BasePreloader';

import { giftcardsBadgeInfo, LoadingStatus } from 'helpers/constants';
import useCustomClipboard from 'hooks/useCustomClipboard';
import usePermissions from 'hooks/usePermissions';
import { loadGiftcards } from 'store/giftcards';
import { selectGiftcards, selectLoadGiftcardsStatus } from 'store/selectors';
import { useAppDispatch, useAppSelector } from 'store/store';

type GiftCard = API.Admin.Giftcards.GiftCardItem;

interface GiftCardColumnHelper extends GiftCard {
  actions?: () => void;
}

const columnHelper = createColumnHelper<GiftCardColumnHelper>();

const GiftCardTable = () => {
  const dispatch = useAppDispatch();

  const giftcardsList = useAppSelector(selectGiftcards);
  const loadGiftcardsStatus = useAppSelector(selectLoadGiftcardsStatus);
  const { currentRouteUserPermissions } = usePermissions();

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const toast = useToast();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [activeGiftcard, setActiveGiftcard] = useState<number>(null);
  const [deleteGiftcardStatus, setDeleteGiftcardStatus] = useState<LoadingStatus>(LoadingStatus.NONE);

  const openDeleteDialog = (giftcard: number) => {
    setActiveGiftcard(giftcard);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setActiveGiftcard(null);
    setIsDeleteDialogOpen(false);
  };

  const deleteDialogText = `Do you realy want to delete ${activeGiftcard}? It's an irreversible process`;
  const deleteGiftcard = async () => {
    try {
      setDeleteGiftcardStatus(LoadingStatus.PENDING);
      await giftcards.delete(activeGiftcard);
      toast({
        title: `Giftcard ${activeGiftcard} successfully deleted`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      closeDeleteDialog();
      dispatch(loadGiftcards());
      setDeleteGiftcardStatus(LoadingStatus.FULFILLED);
    } catch (e) {
      setDeleteGiftcardStatus(LoadingStatus.REJECTED);
    }
  };

  const copyGiftcardToClipboard = useCustomClipboard();

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Gift Card id
        </Text>
      ),
      cell: info => (
        <Flex alignItems='center'>
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('series', {
      id: 'series',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Series
        </Text>
      ),
      cell: info => (
        <Flex flexDirection='column'>
          <Text color={textColor} fontSize='sm' fontWeight='600'>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('amount', {
      id: 'amount',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Amount
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize='sm' fontWeight='600'>
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('active', {
      id: 'active',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Active
        </Text>
      ),
      cell: info => {
        const activeStatus = info.getValue() ? 'active' : 'inactive';

        return (
          <Badge
            colorScheme={giftcardsBadgeInfo[activeStatus]?.theme}
            color={giftcardsBadgeInfo[activeStatus]?.fontColor}
            fontSize={giftcardsBadgeInfo[activeStatus]?.fontSize}
            fontWeight={giftcardsBadgeInfo[activeStatus]?.fontWeight}
            minW={'120px'}
            textAlign='center'
          >
            {activeStatus}
          </Badge>
        );
      },
    }),
    columnHelper.accessor('used', {
      id: 'used',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Used
        </Text>
      ),
      cell: info => {
        const isCardUsed = info.getValue();
        const usedText = isCardUsed ? format(new Date(info.row.original.used_at), 'yyyy-MM-dd') : 'non-used';

        return (
          <Text
            color={textColor}
            whiteSpace='nowrap'
            fontSize='sm'
            fontWeight='600'
            textColor={isCardUsed ? 'navy.700' : 'green.500'}
            textTransform='uppercase'
          >
            {usedText}
          </Text>
        );
      },
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Created
        </Text>
      ),
      cell: info => (
        <Text color={textColor} whiteSpace='nowrap' fontSize='sm' fontWeight='600'>
          {format(new Date(info.getValue()), 'yyyy-MM-dd')}
        </Text>
      ),
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => (
        <Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
          Actions
        </Text>
      ),
      cell: info => {
        const giftcard = info.row.original;
        return (
          <Menu>
            <MenuButton
              as={Button}
              leftIcon={<MdTouchApp />}
              fontSize='14px'
              textTransform='uppercase'
              colorScheme='blue'
              bgColor='blue.400'
              h='28px'
              borderRadius='6px'
            >
              Actions
            </MenuButton>
            <MenuList minWidth='0px' fontSize='large' padding='10px'>
              <MenuItem icon={<MdOutlineContentCopy />} onClick={() => copyGiftcardToClipboard(giftcard.number)}>
                Copy number
              </MenuItem>
              {currentRouteUserPermissions.canDelete && (
                <MenuItem icon={<MdDelete />} onClick={() => openDeleteDialog(giftcard.id)}>
                  Delete giftcard
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        );
      },
    }),
  ];

  const data = giftcardsList;
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  if (loadGiftcardsStatus === LoadingStatus.PENDING) {
    return <BasePreloader />;
  }

  return (
    <Card mt='20px'>
      <Flex direction='column' w='100%' overflowX={{ sm: 'auto', lg: 'auto' }}>
        <Box overflowX='auto'>
          <Table variant='simple' color='gray.500' mt='12px' minWidth='800'>
            <Thead>
              {table.getHeaderGroups().map(headerGroup => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers?.map(header => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe='10px'
                        borderColor={borderColor}
                        cursor='pointer'
                        onClick={header.column?.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'
                        >
                          {flexRender(header.column?.columnDef?.header, header.getContext())}
                          {{
                            asc: <FaSortDown />,
                            desc: <FaSortUp />,
                          }[header.column?.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          {flexRender(cell.column?.columnDef?.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Flex>
      <DeleteConfirmation
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        confirmationHandler={deleteGiftcard}
        text={deleteDialogText}
        loadingStatus={deleteGiftcardStatus}
      />
    </Card>
  );
};

export default GiftCardTable;
