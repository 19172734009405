import { useEffect } from 'react';

import { useLeaveConfirmation } from 'hooks/useLeaveConfirmation';

const LeaveConfirmation = (): null => {
  const { enableLeaveConfirmation, disableLeaveConfirmation } = useLeaveConfirmation();

  useEffect(() => {
    enableLeaveConfirmation();
    return () => {
      disableLeaveConfirmation();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default LeaveConfirmation;
