/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  createStandaloneToast,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Grid,
  GridItem,
  Select,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';

import { Field, FieldAttributes, Formik } from 'formik';
import { debounce } from 'lodash';
import { SyntheticEvent, useEffect, useState } from 'react';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import ReactQuill from 'react-quill';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { fundraisers } from 'api/admin';
import { tenant } from 'api/admin';
import { API } from 'api/types';
import Card from 'components/card/Card';
import CustomPhotoUploader from 'components/common/CustomPhotoUploader';
import InputField from 'components/fields/InputField';
import { LoadingStatus } from 'helpers/constants';
import useDownloadData from 'hooks/useDownloadData';
import { useLeaveConfirmation } from 'hooks/useLeaveConfirmation';
import { THistory, TLocation } from 'types';
import { geocodeAddress, GeocodeResult } from 'utils/geocoder';
import { acceptedImgFileTypes } from 'views/admin/offers/components/config';

type FundraiserFormProps = {
  nextStep?: () => void;
  isEditForm?: boolean;
};

const initialValues: API.Admin.Fundraisers.Request = {
  tenant_uuid: '',
  name: '',
  description: '',
  legal_details: { VAT: '', legal_name: '', EIN: '' },
};

const quillModules = {
  toolbar: [['bold'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
};

const quillFormats = ['bold', 'list', 'bullet', 'link'];

export default function FundraiserForm({ nextStep, isEditForm = false }: FundraiserFormProps) {
  const [createFundraiserStatus, setCreateFundraiserStatus] = useState<LoadingStatus>(LoadingStatus.NONE);
  const [address, setAddress] = useState('');
  const [geocoderData, setGeocoderData] = useState<GeocodeResult | null>(null);
  const [addressStatus, setAddressStatus] = useState({ isLoad: false, status: 'none' });
  // const { getSortedPhotos, deletePhoto, serverUploadPhotoConfig, preloadPhotos, setFiles, files } = usePhotoUpload();
  const { disableLeaveConfirmation } = useLeaveConfirmation();

  const [tenantsList, loadTenantsStatus, loadTenants] = useDownloadData<API.Admin.Tenants.Response[]>(tenant.getAll);
  const [fundraiserInfo, getFundraiserStatus, getFundraiser] = useDownloadData<API.Admin.Fundraisers.Response>(
    fundraisers.getById
  );

  const history = useHistory<THistory>();
  const location = useLocation<TLocation>();
  const params = new URLSearchParams(location.search);
  const fundraiserUuid = params.get('fundraiserUuid');

  const toast = createStandaloneToast();

  const checkAddress = (e: SyntheticEvent) => {
    setAddressStatus({ status: 'none', isLoad: true });

    geocodeAddress(address)
      .then(result => {
        if (result.country) {
          setGeocoderData(result);
          setAddressStatus({ status: 'valid', isLoad: false });
        } else {
          setAddressStatus({ status: 'invalid', isLoad: false });
        }
      })
      .catch(error => {
        console.error(error);
        setAddressStatus({ status: 'invalid', isLoad: false });
      });
  };

  const submitData = async (formData: API.Admin.Fundraisers.Request) => {
    let fundraiserData = { ...formData };

    if (addressStatus.status === 'valid') {
      fundraiserData.location = {
        country: geocoderData.country,
        country_code: geocoderData.countryCode,
        state: geocoderData.state,
        city: geocoderData.city || '',
        zip: geocoderData.code || '',
        building: geocoderData.house || '',
      };
    }
    const submitFunction = isEditForm
      ? () => fundraisers.update(fundraiserUuid, fundraiserData)
      : () => fundraisers.create(fundraiserData);
    const toastMessage = `Fundraiser has been successfully ${isEditForm ? 'updated' : 'created'}`;

    try {
      setCreateFundraiserStatus(LoadingStatus.PENDING);
      await submitFunction();
      disableLeaveConfirmation();

      setCreateFundraiserStatus(LoadingStatus.FULFILLED);
      toast({
        title: toastMessage,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      history.push({
        pathname: `/admin/fundraisers/list`,
      });
    } catch (e) {
      setCreateFundraiserStatus(LoadingStatus.REJECTED);
      throw e;
    }
  };

  useEffect(() => {
    loadTenants();
    if (isEditForm) {
      getFundraiser(fundraiserUuid);
    }
  }, []);

  useEffect(() => {
    if (isEditForm && getFundraiserStatus === LoadingStatus.FULFILLED) {
      if (fundraiserInfo?.location) {
        const { location } = fundraiserInfo;
        setGeocoderData({ ...fundraiserInfo.location });
        setAddress(`${location.country ?? ''} ${location.state ?? ''} ${location.city ?? ''}`);
      }
    }
  }, [getFundraiserStatus]);

  const isLoaded =
    loadTenantsStatus === LoadingStatus.FULFILLED && (!isEditForm || getFundraiserStatus === LoadingStatus.FULFILLED);

  if (!isLoaded) {
    return (
      <Flex w='100%' h='500px' justifyContent='center' alignItems='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brand.500' size='xl' />
      </Flex>
    );
  }

  return (
    <Card p='30px'>
      <Formik
        validateOnChange
        validateOnBlur
        initialValues={isEditForm ? fundraiserInfo : initialValues}
        validationSchema={Yup.object({
          name: Yup.string().max(30, 'maximum 30 symbols'),
          description: Yup.string().max(2000, 'maximum 2000 symbols'),
          legal_details: Yup.object().shape({
            legal_name: Yup.string().max(20, 'maximum 20 symbols'),
            VAT: Yup.string().max(10, 'maximum 10 symbols'),
            EIN: Yup.string().max(10, 'maximum 10 symbols'),
          }),
        })}
        onSubmit={submitData}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Flex direction='column' w='100%'>
              <Stack direction='column' spacing='20px'>
                <FormControl mb='20px' minW='150px'>
                  <Text fontSize='sm' mb='8px' ml='10px' fontWeight='700'>
                    Tenant name
                  </Text>
                  <Select
                    fontSize='sm'
                    id='tenant_uuid'
                    name='tenant_uuid'
                    h='44px'
                    maxH='44px'
                    me='20px'
                    onChange={handleChange}
                    defaultValue={values.tenant_uuid}
                    placeholder='Select tenant'
                  >
                    {tenantsList.map(({ uuid, name }: { uuid: string; name: string }) => (
                      <option key={uuid} value={uuid}>
                        {name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl isInvalid={!!errors.name} mb='0'>
                  <InputField
                    mb='0px'
                    id='name'
                    name='name'
                    placeholder='Input fundraiser name'
                    label='Fundraiser Name'
                    onChange={handleChange}
                    value={values.name}
                  />
                  {!errors?.name ? (
                    <FormHelperText mb='25px'></FormHelperText>
                  ) : (
                    <FormErrorMessage>{errors?.name}</FormErrorMessage>
                  )}
                </FormControl>
                <Field name='description'>
                  {({ field }: FieldAttributes<any>) => {
                    const onFieldChange = debounce(field.onChange(field.name), 250);
                    return (
                      <ReactQuill
                        modules={quillModules}
                        formats={quillFormats}
                        theme='snow'
                        defaultValue={field.value}
                        onChange={onFieldChange}
                      />
                    );
                  }}
                </Field>
                <Grid templateRows='1fr' templateColumns='repeat(3, 1fr)' gap={4}>
                  <GridItem colSpan={2}>
                    <FormControl isInvalid={addressStatus.status === 'invalid'} mb='0'>
                      <InputField
                        mb='0'
                        id='location'
                        name='location'
                        placeholder='Address'
                        label='Fundraiser address'
                        onChange={(e: SyntheticEvent) => setAddress((e.target as HTMLInputElement).value)}
                        value={address}
                        onBlur={checkAddress}
                      />
                      {addressStatus.status === 'none' && <FormHelperText mb='27px'></FormHelperText>}
                      {addressStatus.status === 'valid' && (
                        <FormHelperText color='green'>The address is valid</FormHelperText>
                      )}
                      {addressStatus.status === 'invalid' && (
                        <FormErrorMessage>The address is not valid</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Flex align='start' h='100%'>
                      <Button
                        mt='30px'
                        width='100%'
                        variant='brand'
                        size='md'
                        onClick={checkAddress}
                        isLoading={addressStatus.isLoad}
                      >
                        Check address
                      </Button>
                    </Flex>
                  </GridItem>
                </Grid>
                <Text fontSize='2xl' fontWeight='700'>
                  Legal details
                </Text>
                <Flex gap='4'>
                  <FormControl mb='20px' isInvalid={!!errors.legal_details?.VAT}>
                    <InputField
                      mb='0px'
                      id='legal_details.VAT'
                      name='legal_details.VAT'
                      placeholder='Input VAT'
                      label='VAT'
                      onChange={handleChange}
                      value={values.legal_details?.VAT}
                    />
                    {!errors?.legal_details?.VAT ? (
                      <FormHelperText mb='25px'></FormHelperText>
                    ) : (
                      <FormErrorMessage>{errors?.legal_details?.VAT}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={!!errors.legal_details?.legal_name}>
                    <InputField
                      mb='0px'
                      id='legal_details.legal_name'
                      name='legal_details.legal_name'
                      placeholder='Input legal name'
                      label='Legal name'
                      onChange={handleChange}
                      value={values.legal_details?.legal_name}
                    />
                    {!errors?.legal_details?.legal_name ? (
                      <FormHelperText mb='25px'></FormHelperText>
                    ) : (
                      <FormErrorMessage>{errors?.legal_details?.legal_name}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={!!errors.legal_details?.EIN}>
                    <InputField
                      mb='0px'
                      id='legal_details.EIN'
                      name='legal_details.EIN'
                      placeholder='Input EIN'
                      label='EIN'
                      onChange={handleChange}
                      value={values.legal_details?.EIN}
                    />
                    {!errors?.legal_details?.EIN ? (
                      <FormHelperText mb='25px'></FormHelperText>
                    ) : (
                      <FormErrorMessage>{errors?.legal_details?.EIN}</FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
                <Text fontSize='2xl' fontWeight='700'>
                  Logo
                </Text>
                <CustomPhotoUploader
                  photoFromServer={fundraiserInfo?.logo}
                  acceptedFileTypes={acceptedImgFileTypes}
                  allowMultiple={false}
                  setExternalPhotos={logos => (values.logo = logos[0])}
                />
              </Stack>
              <Flex justify='space-between' mt='24px'>
                <Button
                  isLoading={createFundraiserStatus === LoadingStatus.PENDING || addressStatus.isLoad}
                  variant='darkBrand'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: '128px', md: '148px' }}
                  h='46px'
                  ms='auto'
                  type='submit'
                  disabled={!!Object.values(errors).length}
                >
                  {isEditForm ? 'Save changes' : 'Create fundraiser'}
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </Card>
  );
}
